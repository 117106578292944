import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditFile from "./EditFile";

const GetFiles = () => {
  const [notes, setNotes] = useState([]);

  // Get Notes PDF Data
  const getAllNotes = async () => {
    try {
      const response = await axios.get(`/api/v1/common/admin-get-all-notes`);
      if (response?.data?.success === true) {
        setNotes(response?.data?.data);
        // console.log(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllNotes();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Notes PDF
  const deleteNote = async (e, cloudinaryId) => {
    if (
      window.confirm("Are you sure you want to delete this PDF Notes File?")
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/common/admin-delete-pdf-notes",
          {
            data: { cloudinaryId },
          }
        );
        console.log(data, "data");
        if (data?.success === true) {
          message.success(data.message);
          getAllNotes();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
            aria-label="delete"
            onClick={(e) => deleteNote(e, value?.row?.cloudinaryId)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditFile
          refreshData={getAllNotes}
            nodeId={value?.row?._id}
            subject_name={value?.row?.subject_name}
            subject_type={value?.row?.subject_type}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            instituteId={value?.row?.instituteId}
          />
        
        </Box>
      ),
    },
    // {
    //   field: "_id",
    //   headerName: "Node ID",
    //   width: 210,
    //   editable: true,
    // },

    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },

    {
      field: "subject_name",
      headerName: "Subject Name",
      width: 180,
      editable: true,
    },

    {
      field: "subject_type",
      headerName: "Subject Type",
      width: 180,
      editable: true,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      editable: true,
    },

    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      editable: true,
    },

   
  ];
  return (
    <>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              // Style for header cells
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={notes}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </>
  );
};

export default GetFiles;
