import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import { message, Button } from "antd";
import jsPDF from "jspdf";
import { Box } from '@mui/material';

const QuestionPaperPDF = ({ id }) => {
  const [questions, setQuestions] = useState([]);
  const [examName, setExamName] = useState("");

  // Fetch exam data by ID
  const fetchExamById = async () => {
    try {
      const response = await axios.get(`/api/v1/subjective/get-exam-subjective-by-id/${id}`);
      setQuestions(response.data.data.subjectiveQuestions);
      setExamName(response.data.data.name);
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to fetch question paper");
    }
  };

  // Function to generate Question Paper PDF
  const generateQuestionPaperPDF = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });

    let yPos = 10;

    // Calculate center position for title
    const pageWidth = doc.internal.pageSize.width;
    const textWidth = doc.getStringUnitWidth(examName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const centerX = (pageWidth - textWidth) / 2;

    // Add exam title centered
    doc.setFontSize(16);
    doc.text(examName, centerX, yPos);
    yPos += 15; // Increase yPos for title spacing

    // Add questions and reference answers
    questions.forEach((question, index) => {
      // Add question number and name
      const questionText = `${index + 1}. ${question.name}`;
      const questionNumber = `Question Number: ${question.questionNumber}`;

      const content = doc.splitTextToSize(
        `${questionText}\n\n${questionNumber}`,
        doc.internal.pageSize.width - 20
      );

      if (yPos + doc.getTextDimensions(content).h > doc.internal.pageSize.height - 10) {
        doc.addPage();
        yPos = 10;
      }

      doc.setFontSize(12);
      doc.text(content, 10, yPos);
      yPos += doc.getTextDimensions(content).h + 10; 
    });

    // Save Question Paper PDF
    doc.save("question_paper.pdf");
  };

  // Function to generate Answer Key PDF
  const generateAnswerKeyPDF = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });

    let yPos = 10;

    // Add exam title centered
    doc.setFontSize(16);
    doc.text(`Answer Key - ${examName}`, 10, yPos);
    yPos += 15; // Increase yPos for title spacing

    // Add questions and reference answers with correct indication
    questions.forEach((question, index) => {
      // Add question number and name
      const questionText = `${index + 1}. ${question.name}`;
      const questionNumber= `Question Number: ${question.questionNumber}`;
      const referenceAnswer = `Reference Answer: ${question.referenceAnswer}`;

      const content = doc.splitTextToSize(
        `${questionText}\n\n${questionNumber}\n\n${referenceAnswer}`,
        doc.internal.pageSize.width - 20
      );

      if (yPos + doc.getTextDimensions(content).h > doc.internal.pageSize.height - 10) {
        doc.addPage();
        yPos = 10;
      }

      doc.setFontSize(12);
      doc.text(content, 10, yPos);
      yPos += doc.getTextDimensions(content).h + 10; 
    });

    // Save Answer Key PDF
    doc.save("answer_key.pdf");
  };

  useEffect(() => {
    if (id) {
      fetchExamById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box className="question-paper">
      <Button type="primary" onClick={generateQuestionPaperPDF}>
        Question Paper
      </Button>
      <Button type="primary" onClick={generateAnswerKeyPDF} style={{ marginLeft: "10px" }}>
        Answer Key
      </Button>
    </Box>
  );
};

export default QuestionPaperPDF;
