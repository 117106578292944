import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button, Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../../axiosInstance";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const EditFile = ({
  refreshData,
  nodeId,
  subject_name,
  subject_type,
  startDate,
  endDate,
  instituteId,
}) => {
 
  const [subjectName, setSubjectName] = useState(subject_name);
  const [subjectType, setSubjectType] = useState(subject_type);
  const [start_Date, setStart_Date] = useState(startDate);
  const [end_Date, setEnd_Date] = useState(endDate);

  // const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(startDate)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  const handleStartDateChange = (date) => {
    setStart_Date(date);
  };
  const handleEndDateChange = (date) => {
    setEnd_Date(date);
  };
  const notesStartDate = moment(start_Date).format("MM/DD/YYYY");
  const notesEndDate = moment(end_Date).format("MM/DD/YYYY");

  // Edit File
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to Edit this PDF Note ?")) {
      const addRole = {
        id: nodeId,
        subject_name: subjectName,
        subject_type: subjectType,
        startDate: notesStartDate,
        endDate: notesEndDate,
        instituteId: instituteId,
      };

      try {
        const { data } = await axios.put(
          "/api/v1/common/admin-edit-pdf-notes",
          addRole
        );
        if (data.success === true) {
          message.success(data.message);
          refreshData()
          setIsModalOpen(false);
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit PDF Nodes"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="subjectName"
            label="Subject Name"
            name="subjectName"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Subject Name"
            value={subjectName}
            onChange={(e) => setSubjectName(e.target.value)}
          />

          <Box sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {" "}
                Notes Type{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={subjectType}
                label="Notes Type"
                onChange={(e) => setSubjectType(e.target.value)}
              >
                <MenuItem value="Lecture Notes">Lecture Notes</MenuItem>
                <MenuItem value="Study Guides">Study Guides</MenuItem>
                <MenuItem value="Assignments">Assignments</MenuItem>
                <MenuItem value="Research Papers">Research Papers</MenuItem>
                <MenuItem value="Syllabi">Syllabi</MenuItem>
                <MenuItem value="Project Reports">Project Reports</MenuItem>
                <MenuItem value="Reference Materials">
                  Reference Materials
                </MenuItem>
                <MenuItem value="Exam Papers">Exam Papers</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box style={{ display: "flex" }} className="Date">
            <Box
              sx={{
                mb: 3,
              }}
              style={{ marginRight: "2%" }}
            >
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Start Date"
                selected={start_Date}
                value={start_Date}
                onChange={handleStartDateChange}
                minDate={new Date()}
                maxDate={end_Date}
                required
              />
            </Box>
            <Box>
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="End Date"
                selected={end_Date}
                onChange={handleEndDateChange}
                value={end_Date}
                // eslint-disable-next-line no-mixed-operators
                minDate={(new Date() && start_Date) || new Date()}
                maxDate={end_Date}
                required
              />
            </Box>
          </Box>

          <Button variant="contained" type="submit">
            Edit Note
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditFile;
