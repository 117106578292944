import React, { useState, useEffect } from "react";
import { Container, Grid, Card, CardContent, Typography, Box } from "@mui/material";
import axios from "../axiosInstance";
import { message } from "antd";

const VenderAbout = () => {
  const [about, setAbout] = useState([]);

  // Get all reports by user
  const getBannerImage = async () => {
    try {
      const response = await axios.get(`/api/v1/vendor/get-institute-info`);
      setAbout(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBannerImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {about && about?.length > 0 ? (
        <Box sx={{ background: "#f7fafd", p: 3, mt: 3 }}>
          <Container>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                style={{
                  textTransform: "capitalize",
                  fontWeight: "600",
                  fontSize: "24px",
                  marginBottom: "20px",
                  textAlign: "center",
                  letterSpacing: "2px",
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                {about.length > 0 && <b>Why Choose {about[0].instituteName}?</b>}
              </Typography>
              {about.length > 0 && (
                <Typography className="vendor_about_description" sx={{letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>{about[0].description}</Typography>
              )}
            </Box>
            <Grid container spacing={2} mt={3}>
              {about[0].courses.map((course, index) => (
                <Grid item xs={12}  sm={6} md={4} key={index} mb={2}>
                  <Card
                    sx={{
                      transition: "transform 0.5s",
                      "&:hover": {
                        transform: "translateY(-12px)",
                      },
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Typography variant="h6" sx={{letterSpacing: "1px", fontFamily: 'Poppins, sans-serif'}}>{course}</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      ) : null}
    </div>
  );
};

export default VenderAbout;
