import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, contactForm } from "../redux/actions/userAction";
import Footer from "../Components/Footer";
import { message } from "antd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const Contact = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { error, loading, success, isAuthenticated } = useSelector(
    (state) => state.contact
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", messages);
    formData.append("instituteId", user?.instituteId);
    formData.append("vendorId", user?._id);
    dispatch(contactForm(formData));
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      message.success(
        "Thanks for contacting us. We'll get back to you as soon as possible."
      );
      setName("");
      setEmail("");
      setMessages("");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [dispatch, error, success, isAuthenticated]);

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <>
      <Navbar />
      <PageTitle title="Contact Us | Test Series" />
      <Container sx={{ pt: 3, minHeight: "100vh", mt:{ xs: 8, sm:8, md: 8, lg: 10 },mb:8, }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 5,
            mt:5,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              {/* <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              > */}
                <Card>
                  <CardContent>
                    <Typography variant="h4" align="center" mb={2}>
                      Contact Us
                    </Typography>
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Message"
                        value={messages}
                        onChange={(e) => setMessages(e.target.value)}
                        margin="normal"
                        multiline
                        rows={4}
                      />
                      <Button
                        disabled={loading}
                        variant="contained"
                        type="submit"
                        fullWidth
                        sx={{ mt: 2 }}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              {/* </motion.div> */}
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: 50 }}
                animate={inView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent sx={{ textAlign: "center" }}>
                        <LocationOnIcon fontSize="large" color="primary" />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                          Address
                        </Typography>
                        <Typography variant="body2">
                          1234 Street Name, City, Country
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent sx={{ textAlign: "center" }}>
                        <PhoneIcon fontSize="large" color="primary" />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                          Phone
                        </Typography>
                        <Typography variant="body2">
                          +123-456-7890
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent sx={{ textAlign: "center" }}>
                        <MailIcon fontSize="large" color="primary" />
                        <Typography variant="h6" sx={{ mt: 1 }}>
                          Email
                        </Typography>
                        <Typography variant="body2">
                          info@example.com
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </motion.div>
            </Grid> */}
          </Grid>
        </Box>

        {/* <Box sx={{ mt: 5, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            Connect with Us
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <IconButton href="#" color="primary">
              <FacebookIcon fontSize="large" />
            </IconButton>
            <IconButton href="#" color="primary">
              <InstagramIcon fontSize="large" />
            </IconButton>
            <IconButton href="#" color="primary">
              <TwitterIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box> */}
      </Container>

      <Footer />
    </>
  );
};

export default Contact;
