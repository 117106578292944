import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  CardActions,
  CardContent,
  Badge,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "../axiosInstance";
import Footer from "../Components/Footer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClassIcon from "@mui/icons-material/Class";
import CategoryIcon from "@mui/icons-material/Category";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ScoreIcon from "@mui/icons-material/Score";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SchoolIcon from "@mui/icons-material/School";
import DescriptionIcon from "@mui/icons-material/Description";
import moment from "moment";
import { message } from "antd";
import SubjectiveExam from "./SubjectiveExam";

const Exam = () => {
  const navigate = useNavigate();
  const [exam, setExam] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const currentDate = moment().startOf("day");

  // Get All Exam Data
  const getAllExams = async () => {
    try {
      const response = await axios.post(`/api/v1/exams/get-all-exams`);
      setExam(response?.data?.data);
      setSearch(response?.data?.data);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
  }, []);

  // Get all reports by user
  const getReportData = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/get-all-reports-by-user`
      );
      setReportsData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  // Search Exam Name and Category
  const searchAllExams = async (e) => {
    let key = e?.target?.value;
    if (key) {
      try {
        const response = await axios.get(
          `/api/v1/exams/search-all-exams/${key}`
        );
        if (response?.data?.data) {
          setSearch(response?.data?.data);
          setExam(response?.data?.data);
        }
      } catch (error) {
        message.error(error.response?.data?.message);
      }
    } else {
      getAllExams();
    }
  };

  return (
    <>
      <Navbar />
      <PageTitle title="Exam | Test Series" />
      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="text"
          label="Search Exam Name and Category"
          name="text"
          placeholder="Search Exam Name and Category"
          onChange={searchAllExams}
        />
      </Container>

      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
        <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              {exam && exam.length > 0 ? (
                exam.map((exam, index) =>
                  exam.questions.length > 0 ? (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Badge
                        badgeContent="Upcoming"
                        color="primary"
                        invisible={moment(exam.startDate).isSameOrBefore(
                          currentDate
                        )}
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Card
                          style={{
                            animation: "fadeIn 5s ease-in-out",
                            minHeight: "38vh",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              style={{
                                letterSpacing: ".1rem",
                                fontSize: "18px",
                                marginBottom: "12px",
                                fontWeight: "bold",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <SchoolIcon
                                style={{
                                  marginRight: "8px",
                                  verticalAlign: "middle",
                                }}
                              />
                              {exam.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                mt: 2,
                                display: "flex",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <CategoryIcon
                                fontSize="small"
                                style={{ marginRight: "8px" }}
                              />
                              <strong>Category</strong> : {exam.category}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                mt: 2,
                                display: "flex",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <DescriptionIcon
                                fontSize="small"
                                style={{ marginRight: "8px" }}
                              />
                              <strong> Exam Set</strong> : {exam.examSet}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                mt: 2,
                                display: "flex",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <QuestionAnswerIcon
                                fontSize="small"
                                style={{ marginRight: "8px" }}
                              />
                              <strong> Total Questions</strong> :{" "}
                              {exam.questions.length}
                            </Typography>

                            {moment(exam.startDate).isSameOrBefore(
                              currentDate
                            ) ? (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  fontSize: "15px",
                                  letterSpacing: ".1rem",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                <DateRangeIcon
                                  fontSize="small"
                                  style={{ marginRight: "8px" }}
                                />
                                <strong> End Date </strong> :{" "}
                                {moment(exam.endDate).format("MMMM Do YYYY")}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  mt: 2,
                                  display: "flex",
                                  fontSize: "15px",
                                  letterSpacing: ".1rem",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                <DateRangeIcon
                                  fontSize="small"
                                  style={{ marginRight: "8px" }}
                                />
                                <strong> Start Date </strong> :{" "}
                                {moment(exam.startDate).format("MMMM Do YYYY")}
                              </Typography>
                            )}
                          </CardContent>
                          <CardActions sx={{ mb: 2 }}>
                            {moment(exam.startDate).isSameOrBefore(
                              currentDate
                            ) ? (
                              reportsData.some(
                                (report) =>
                                  report.examId === exam._id &&
                                  report.examSet === "Live Exam"
                              ) ? (
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  disabled
                                  sx={{
                                    letterSpacing: "1px",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  Exam Already Submitted
                                </Button>
                              ) : (
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={() =>
                                    navigate(`/write-exam/${exam._id}`)
                                  }
                                  sx={{
                                    letterSpacing: "1px",
                                    fontFamily: "Poppins, sans-serif",
                                  }}
                                >
                                  Start Exam
                                </Button>
                              )
                            ) : (
                              <Button
                                variant="outlined"
                                fullWidth
                                disabled
                                sx={{
                                  letterSpacing: "1px",
                                  fontFamily: "Poppins, sans-serif",
                                }}
                              >
                                Upcoming Exam
                              </Button>
                            )}
                          </CardActions>
                        </Card>
                      </Badge>
                    </Grid>
                  ) : null
                )
              ) : (
                <Box
                  style={{
                    color: "#878787",
                    margin: "30px 80px",
                    fontSize: 18,
                  }}
                >
                  No Available Exams{" "}
                </Box>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Exam;
