import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import InstituteInfoList from "./InstituteInfoList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function JobNotification() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [files, setFiles] = useState([]);
  const [instituteId, setInstituteId] = useState(user?.instituteId);
  const [vendorId, setVendorId] = useState(user?._id);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [instituteName, setInstituteName] = useState("");
  const [instituteDescription, setInstituteDescription] = useState("");
  const [coursesName, setCoursesName] = useState([]);
  const [inputType, setInputType] = useState("coursesName");
  const [filePreviews, setFilePreviews] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);

    // Create an array of object URLs
    const fileURLs = Array.from(selectedFiles).map((file) => {
      const objectUrl = URL.createObjectURL(file);
      return objectUrl;
    });

    // Update file previews with the object URLs
    setFilePreviews(fileURLs);
  };
  // Cleanup object URLs when component unmounts or file previews change
  useEffect(() => {
    return () => {
      filePreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [filePreviews]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    for (const file of files) {
      formData.append("banners", file);
    }
    formData.append("instituteId", instituteId);
    formData.append("vendorId", vendorId);

    try {
      const response = await axios.post(
        "/api/v1/vendor/admin-upload-banner-institute",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(response.data.message);
      getAllBannerImage(); // Refresh banner images after upload
    } catch (error) {
      message.error("Error uploading files");
    } finally {
      setLoading(false);
    }
  };

  const getAllBannerImage = async () => {
    try {
      const response = await axios.get(
        "/api/v1/vendor/admin-get-all-banner-institute"
      );
      if (response?.data?.success === true) {
        setBanner(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllBannerImage(); // Initial load of banner images
  }, []);

  const deleteBannerImage = async (id, cloudinaryId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this institute banner image?"
      )
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/vendor/admin-delete-banner-institute",
          {
            data: { id, cloudinaryId },
          }
        );
        if (data?.success === true) {
          message.success(data?.message);
          getAllBannerImage(); // Refresh banner images after deletion
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() =>
            deleteBannerImage(params?.row?._id, params?.row?.cloudinaryId)
          }
        >
          <DeleteIcon sx={{ color: "red" }} />
        </IconButton>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      // editable: true,
    },
    {
      field: "cloudinaryId",
      headerName: "Cloudinary Id",
      width: 200,
      editable: true,
    },
    {
      field: "url",
      headerName: "Banner Image",
      width: 240,
      renderCell: (params) => (
        <img
          src={params.row.url}
          alt="Banner"
          style={{ width: "100px", height: "auto" }}
        />
      ),
    },
  ];

  const handleChangeInputs = (onChangeValue, i) => {
    if (inputType === "coursesName") {
      const updatedCourses = [...coursesName];
      updatedCourses[i] = onChangeValue.target.value;
      setCoursesName(updatedCourses);
    }
  };

  const handleDeleteInputs = (i) => {
    if (inputType === "coursesName") {
      const updatedCourses = [...coursesName];
      updatedCourses.splice(i, 1);
      setCoursesName(updatedCourses);
    }
  };

  const handleAddInput = () => {
    if (inputType === "coursesName") {
      setCoursesName([...coursesName, ""]);
    }
  };

  const instituteSubmit = async (e) => {
    e.preventDefault();
    const addInstituteInfo = {
      instituteName,
      description: instituteDescription,
      courses: coursesName,
      vendorId: user?._id,
      instituteId: user?.instituteId,
    };

    try {
      const { data } = await axios.post(
        "/api/v1/vendor/admin-about-institute",
        addInstituteInfo
      );
      if (data.success === true) {
        message.success(data.message);
        setCoursesName([]); // Clear coursesName
        setInstituteName("");
        setInstituteDescription("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Institute Information| Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Institute Information
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Home" {...TabPanel(0)} />
            <Tab label="About" {...TabPanel(1)} />
            <Tab label="About List" {...TabPanel(2)} />
          </Tabs>
        </Box>

        {/* Institute Banner Image */}
        <CustomTabPanel value={value} index={0}>
          <Box>
            <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
              Upload Banner Images
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Institute ID"
                value={instituteId}
                onChange={(e) => setInstituteId(e.target.value)}
                fullWidth
                margin="normal"
                required
                sx={{ mb: 2 }}
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Files
                </Button>
              </label>
              <Box mt={2}>
                {files.length > 0 && (
                  <Typography variant="body2">{`${files.length} file(s) selected`}</Typography>
                )}
              </Box>
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{ mt: 2, mb: 3 }}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Box>
            </form>
            {/* Image Previews */}
            <Box mt={3}>
              {filePreviews.map((preview, index) => (
                <img
                  key={index}
                  src={preview}
                  alt={`preview-${index}`}
                  style={{ width: "100px", marginRight: "10px" }}
                />
              ))}
            </Box>
            <hr />
            <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
              Institute Banner Image Details
            </Typography>
            <Paper sx={{ bgcolor: "white" }}>
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row?._id}
                  sx={{
                    "& .MuiTablePagination-displayedRows": {
                      color: "black",
                    },
                    color: "black",
                    [`& .${gridClasses?.row}`]: {
                      bgcolor: "white",
                    },
                     // Style for header cells
                     "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "rgb(25, 118, 210)",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      color: "white",
                      fontWeight: "bold",
                      // fontSize: "13px",
                    },
                    // Style for checkbox in header
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                      color: "#FFF",
                    },
                    "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                      color: "white",
                    },
                    "& .MuiDataGrid-sortIcon": {
                      color: "white",
                    },
                  }}
                  rows={banner}
                  columns={columns}
                  pageSize={3}
                  rowsPerPageOptions={[3]}
                  // checkboxSelection
                />
              </Box>
            </Paper>
          </Box>
        </CustomTabPanel>

        {/* Create Institute Details */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Institute Details
          </Typography>
          <Container
            onSubmit={instituteSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteName"
              label="Institute Name"
              name="instituteName"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Institute Name"
              value={instituteName}
              onChange={(e) => setInstituteName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteDescription"
              label="Institute Description"
              name="instituteDescription"
              InputLabelProps={{
                shrink: true,
              }}
              required
              margin="normal"
              multiline
              placeholder="Short Description In Institute"
              value={instituteDescription}
              onChange={(e) => setInstituteDescription(e.target.value)}
            />

            {inputType === "coursesName" && (
              <>
                {coursesName?.map((course, i) => (
                  <Box key={i} sx={{ mb: 2 }}>
                    <Box sx={{ mb: 2 }} style={{ fontWeight: "500" }}>{`${
                      i + 1
                    }. Courses Name`}</Box>
                    <TextField
                      fullWidth
                      id={`coursesName-${i}`}
                      label={`Courses Name ${i + 1}`}
                      name={`coursesName-${i}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      placeholder={`Courses Name ${i + 1}`}
                      value={course}
                      onChange={(e) => handleChangeInputs(e, i)}
                    />
                    <Button sx={{ mb: 2 }} onClick={() => handleDeleteInputs(i)}>
                      Remove
                    </Button>
                  </Box>
                ))}
                <Button
                  sx={{ mb: 2 }}
                  color="success"
                  variant="outlined"
                  onClick={handleAddInput}
                >
                  Add Courses Name
                </Button>
              </>
            )}

            <br />

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Institute Info
            </Button>
          </Container>
        </CustomTabPanel>

        {/* About Institute Details List */}
        <CustomTabPanel value={value} index={2}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Institute Details List
          </Typography>

          <InstituteInfoList />
        </CustomTabPanel>
      </Box>
    </>
  );
}
