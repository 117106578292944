import React from "react";
import { Typography, Paper, Box } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import axios from "../../axiosInstance";
import { useState } from "react";
import EditCategoryPermissions from "./EditCategoryPermissions";
import IconButton  from "@mui/material/IconButton";
import { message } from "antd";
import { useEffect } from "react";
import  DeleteIcon  from '@mui/icons-material/Delete';

const AssignCategoryPermissionsList = () => {
  const [assignCategory, setAssignCategory] = useState([]);

  // Get Assign Category Permission
  const getCategoryPermissions = async () => {
    try {
      const response = await axios.get(
        "/api/v1/common/get-assign-category-permission"
      );
      if (response?.data?.success === true) {
        setAssignCategory(response?.data?.assignCategory);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getCategoryPermissions();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Assign Category Permission
  const deleteCategoryPermissions = async (e, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this Assign Category Permission?"
      )
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/common/admin-delete-category-permission",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getCategoryPermissions();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
            aria-label="delete"
            onClick={(e) => deleteCategoryPermissions(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditCategoryPermissions
           refreshData={getCategoryPermissions}
            permissionName={value?.row?.permissions}
            institute={value?.row?.instituteId}
            userId={value?.row?.user_id?._id}
            permissionID={value?.row?._id}
          />
        </Box>
      ),
    },
    {
        field: "instituteId",
        headerName: "Institute Id",
        width: 220,
        editable: true,
      },
      {
        field: "user_id",
        headerName: "Vendor Name",
        width: 250,
        // editable: true,
        renderCell: (row) => row?.row?.user_id?.name,
      },
  
      {
        field: "email",
        headerName: "Vendor Email",
        width: 290,
        // editable: true,
        renderCell: (row) => row?.row?.user_id?.email,
      },
  
      {
        field: "role_name",
        headerName: "Role",
        width: 280,
        // editable: true,
        renderCell: (row) => row?.row?.user_id?.role_name,
      },


    {
      field: "permissions",
      headerName: "Permissions",
      width: 220,
      renderCell: (row) => {
        // Render permissions as comma-separated string
        return row.row.permissions
          .map((permission) => permission.permission_name)
          .join(", ");
      },
    },

    
  ];
  return (
    <div>
      <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
        Assign Category Permissions List
      </Typography>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 400, width: "auto" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
               // Style for header cells
               "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={assignCategory}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </div>
  );
};

export default AssignCategoryPermissionsList;
