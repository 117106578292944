import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../Components/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, userRegister } from "../redux/actions/userAction";
import { message } from "antd";
import axios from "../axiosInstance";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [password, setPassword] = useState("");

  // Check Server Status
  const checkServerStatus = async () => {
    try {
      const response = await axios.get("/api/status");
      if (response?.data?.data === true) {
        // message.success("Server is running");
      } else {
        message.error(
          "Oops! Something went wrong. Please try again later or contact support."
        );
      }
    } catch (error) {
      message.error(
        "Error: Oops! Something went wrong. Please try again later or contact support."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      name,
      email,
      phone,
      instituteId,
      password,
    };
    checkServerStatus();
    dispatch(userRegister(userData));
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, isAuthenticated]);

  return (
    <>
      <PageTitle title="Register | Test Series" />
      <Box
        sx={{
          height: "81vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgColor: "primary.white",
          marginTop: "50px"
        }}
      >
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style needs-validation"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
              <LockOpenIcon />
            </Avatar>
            <h2>Create a new account</h2>
            <TextField
              sx={{
                mb: 3,
                mt: 2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Full Name"
              placeholder="Full name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              type="tel"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteId"
              label="Institute Id"
              name="instituteId"
              placeholder="Institute Id"
              value={instituteId}
              onChange={(e) => setInstituteId(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              className="was-validated"
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              disabled={loading}
              variant="contained"
              type="submit"
            >
              {loading ? "loading..." : "Register"}
            </Button>

            <Box sx={{ pt: 2 }} className="switchMember">
              {" "}
              Already a member ?{""} <Link to="/login"> Sign In </Link>{" "}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Register;
