import React, { useEffect, useState } from "react";
import DemoNavbar from "./DemoNavbar";
import { Paper } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "@mui/material/Container";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import DemoImage from "../../assets/demo image.png";
import Grid from "@mui/material/Grid";
import Footer from "./DemoFooter";
import BookADemo from "./BookADemo";
import PageTitle from "../PageTitle/PageTitle";
import DemoFeature from "./DemoFeature";
import axios from "../../axiosInstance";
import { message } from "antd";

const BannerContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "600px",
});

const BannerImage = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#000",
});

const DemoHome = () => {
  const [image_Url, setImage_Url] = useState([]);

  const settings = {
    dots: false,
    infinite: image_Url.length > 1, // infinite only if there are multiple images
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div>
      <DemoNavbar />
      <PageTitle title="Test Series" />
      <Container
        sx={{ mt: { xs: 3, md: 10 }, padding: "20px", minHeight: "100vh" }}
      >
        <Grid container spacing={5} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                mb: 2,
                mt: 2,
                lineHeight: 1.5,
                wordSpacing: "1.5px",
                letterSpacing: " 2.5px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              The most cost-effective way to manage and grow your Institution.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 2,
                wordSpacing: "2px",
                letterSpacing: " 2px",
                color: "#757575",
              }}
            >
              GatikAI Technologies has developed a test series web app to help
              users practice and enhance their skills through a variety of mock
              tests. The app offers an intuitive interface, extensive question
              sets, and instant results, providing an effective and engaging
              learning experience.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              <Link
                to="book-a-demo"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  padding: "8px",
                  fontSize: "14px",
                  wordSpacing: "2px",
                  letterSpacing: "1px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Book A Demo
                <ArrowForwardIosIcon sx={{ ml: 1, fontSize: "18px", }} />{" "}
              </Link>
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <img
                src={DemoImage}
                alt="Institution Management"
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
            </Paper>
          </Grid>
        </Grid>
        <DemoFeature />
        <Box sx={{ mt: -13 }}>
          {" "}
          <BookADemo />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default DemoHome;
