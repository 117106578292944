import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Chip,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { message } from "antd";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageTitle from "../../Components/PageTitle/PageTitle";
import axios from "../../axiosInstance";
import AllVendorList from "./AllVendorList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CreateVendor = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorPhone, setVendorPhone] = useState("");
  const [vendorInstituteId, setVendorInstituteId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [permissions, setPermissions] = useState([]);
  const [addPermission, setAddPermission] = useState([]);
  const [routerPermission, setRouterPermission] = useState([]);
  const [addPermissionId, setAddPermissionId] = useState("");
  const [role, setRole] = useState([]);
  const [addRolePermission, setAddRolePermission] = useState("");
  const [role_name, setRole_name] = useState("");
  const [instituteAccounts, setInstituteAccounts] = useState("Primary Account");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [vendorPlans, setVendorPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const planStartDate = moment(startDate).format("MM/DD/YYYY");
  const planEndDate = moment(endDate).format("MM/DD/YYYY");

  // Get Permission Data
  const getAllPermission = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-permission`);
      if (response?.data?.success === true) {
        setPermissions(response?.data?.permission);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPermission();
    return () => clearInterval(intervalId);
  }, []);

  const AddPermissionHandler = (e) => {
    const selectedValue = e.target.value;
    setAddPermissionId(selectedValue);
  };

  const AddRoleHandler = (e) => {
    const selectedValue = e.target.value;
    setAddRolePermission(selectedValue?.value);
    setRole_name(selectedValue?.role_name);
  };

  // Get Role Data
  const getAllRole = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-role`);
      if (response?.data?.success === true) {
        setRole(response?.data?.role);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // Define a function to get the label for each MenuItem value in Add Permissions
  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };

  // Get All Vendor Plan
  const getAllVendorPlan = async () => {
    try {
      const response = await axios.get(`/api/v1/plan/admin-get-plan`);
      if (response?.data?.success === true) {
        setVendorPlans(response?.data?.plan);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to fetch plans");
    }
  };
  useEffect(() => {
    getAllVendorPlan();
  }, []);

  const handleChangeVendorPlan = (event) => {
    setSelectedPlanId(event.target.value);
  };

  // Create a new Vendor and Assign A New Vendor Plans
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const addVendor = {
      name: vendorName,
      email: vendorEmail,
      phone: vendorPhone,
      instituteId: vendorInstituteId,
      permissions: [
        {
          id: addPermissionId,
          value: addPermission,
        },
      ],
      role: addRolePermission,
      permission_id: addPermissionId,
      assignCategoryPermissions: user?._id,
      assignVendorId: user?._id,
      instituteAccounts,
      role_name,
    };

    try {
      const { data: createVendorData } = await axios.post(
        "/api/v1/common/admin-create-vendor",
        addVendor
      );

      if (createVendorData.success === true) {
        message.success(createVendorData.message);

        const vendorId = createVendorData.vendorId; 

        // Proceed with assigning the plan
        const AssignPlanData = {
          startDate: planStartDate,
          endDate: planEndDate,
          planId: selectedPlanId,
          userId: vendorId,
        };

        try {
          const { data: assignPlanData } = await axios.post(
            "/api/v1/plan/admin-assign-plan-to-user",
            AssignPlanData
          );

          if (assignPlanData.success === true) {
            // message.success(assignPlanData.message);
          } else {
            message.error(assignPlanData?.message);
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }

        // Clear form fields after both calls are successful
        setVendorName("");
        setVendorEmail("");
        setVendorPhone("");
        setVendorInstituteId("");
        setAddPermissionId(null);
        setAddPermission([]);
        setPermissions(null);
        setAddRolePermission(null);
        setStartDate(null);
        setEndDate(null);
        setSelectedPlanId(null);
        setRole_name(null);
      } else {
        message.error(createVendorData?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <PageTitle title="Admin Vendors | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Vendors
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Vendor List" {...TabPanel(0)} />
            <Tab label="Add Vendor" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Vendor List */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            {/* Vendor List */}
            <AllVendorList />
          </Typography>
        </CustomTabPanel>

        {/* Add Roles */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Create a new Vendor Account
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorName"
              label="Vendor Full Name *"
              name="vendorName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Full Name"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorEmail"
              label="Vendor Email *"
              name="vendorEmail"
              InputLabelProps={{
                shrink: true,
              }}
              // type="email"
              placeholder="Vendor Email"
              value={vendorEmail}
              onChange={(e) => setVendorEmail(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorPhone"
              label="Vendor Phone Number *"
              name="vendorPhone"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Phone Number"
              type="tel"
              value={vendorPhone}
              onChange={(e) => setVendorPhone(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="instituteId"
              label="Vendor Institute Id *"
              name="instituteId"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Institute Id for example GatikAi-01"
              value={vendorInstituteId}
              onChange={(e) => setVendorInstituteId(e.target.value)}
            />

            {/* Roles */}
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Add Role</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Add Role"
                  onChange={AddRoleHandler}
                  required
                >
                  {role && role.length > 0 ? (
                    role.map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {/* {role.value} */}
                        {role?.role_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Roles
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* Permission */}
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Permission Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Permission Name"
                  required
                  onChange={AddPermissionHandler}
                >
                  {permissions && permissions.length > 0 ? (
                    permissions.map((routerPermission, index) => (
                      <MenuItem key={index} value={routerPermission._id}>
                        {routerPermission.permission_name}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Permission
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Add Permission{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addPermission}
                  required
                  label="Add Permission"
                  multiple
                  onChange={(e) => setAddPermission(e.target.value)}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip
                          sizes="medium"
                          key={value}
                          label={getMenuItemLabel(value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value="" disabled>
                    Add Permission
                  </MenuItem>
                  <MenuItem value={0}>Create</MenuItem>
                  <MenuItem value={1}>Read</MenuItem>
                  <MenuItem value={2}>Update</MenuItem>
                  <MenuItem value={3}>Delete</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel shrink id="plan-label">
                Plan Name *
              </InputLabel>
              <Select
                label="plan-label"
                id="plan-select"
                value={selectedPlanId}
                onChange={handleChangeVendorPlan}
                displayEmpty
                sx={{
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
              >
                <MenuItem value="" disabled>
                  Select a Plan
                </MenuItem>
                {vendorPlans.map((plan) => (
                  <MenuItem key={plan._id} value={plan._id}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Assign Plan Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Assign Plan End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="submit"
              disabled={loading} 
            >
              {loading ? <CircularProgress size={24} /> : "Create Vendor"}
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default CreateVendor;
