import React, { useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Input,
} from "@mui/material";
import { Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import { message } from "antd";
import axios from "../../axiosInstance";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GetVideo from "./GetVideo";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Video = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState(""); 
  const [fileType, setFileType] = useState("video")
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 500) {
        message.error(
          "File size exceeds the limit. Please upload a file with a maximum size of 500MB."
        );
        return;
      }
      if (!selectedFile.type.startsWith("video/")) {
        message.error("Invalid file type. Please upload a Video.");
        return;
      }
      setVideo(selectedFile);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const videoStartDate = moment(startDate).format("MM/DD/YYYY");
  const videoEndDate = moment(endDate).format("MM/DD/YYYY");


  // Add Video and YouTube Url
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (video) {
      formData.append('video', video);
    } else {
      formData.append('youtubeUrl', youtubeUrl); // Add YouTube URL to form data
    }
    formData.append('startDate', videoStartDate);
    formData.append('endDate', videoEndDate);
    formData.append('instituteId', user?.instituteId);
    formData.append('vendorId', user?._id);
    formData.append('fileType', fileType);
    try {
      const { data } = await axios.post(
        '/api/v1/admin/admin-upload-notes-video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(data, "data");
      if (data.success === true) {
        message.success(data?.message);
        setTitle('');
        setDescription('');
        setVideo(null);
        setYoutubeUrl(''); // Clear YouTube URL state
        setStartDate(null);
        setEndDate(null);
      } else {
        message.error(data?.message);
        console.log(data, "else message");
      }
    } catch (error) {
      console.log(error.response.data.message, "catch error");
      message.error(error?.response?.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle title="Courses | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Courses
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Add Video" {...TabPanel(0)} />
            <Tab label="Video" {...TabPanel(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Upload a Courses Video
          </Typography>
          <Container
            component="form"
            className="video_form_style border-style"
            onSubmit={handleSubmit}
            maxWidth="md"
           >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
               }}
              fullWidth
              id="title"
              label="Title"
              name="title"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="description"
              label="Short Description"
              name="description"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Short Description"
              margin="normal"
              multiline
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Upload Course Video
              </Typography>
              <label htmlFor="upload-file">
                <Input
                  accept="video/*"
                  id="upload-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload
                </Button>
              </label>
              {video && (
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  {video.name}
                </Typography>
              )}
            </Box>

            {/* Add TextField for YouTube video URL */}
            <TextField
              sx={{
                mb: 3,
                mt: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="youtubeUrl"
              label="YouTube Video URL"
              name="youtubeUrl"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Enter YouTube Video URL"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date" sx={{ mt: 5 }}>
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  minDate={startDate || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Add Video"}
            </Button>
          </Container>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Videos List
          </Typography>
          <GetVideo />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Video;
