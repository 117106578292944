import React, { useState, useEffect } from "react";
import AllUsers from "./AllUsers";
import { PieChart } from "@mui/x-charts/PieChart";
import { Container, Typography, Box, Grid } from "@mui/material/";
import { message } from "antd";
import axios from "../axiosInstance";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { BarChart } from "@mui/x-charts/BarChart";
import Paper from "@mui/material/Paper";
import PageTitle from '../Components/PageTitle/PageTitle';
import VendorProfileViewPlan from './VendorProfileViewPlan';

const Dashboard = () => {
  const [exams, setExams] = useState([]);
  const [question, setQuestion] = useState([]);
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [contact, setContact] = useState([]);
  const [subjectiveExams, setSubjectiveExams] = useState([]);
  const [subjectiveQuestions, setSubjectiveQuestions] = useState([]);
  const [subjectiveReports, setSubjectiveReports] = useState([]);

  // get-all-exams
  const displayExams = async () => {
    try {
      const data = await axios.post("/api/v1/exams/admin-get-all-exams");
      setExams(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };
// Get All Subjective Exam
  const displaySubjectiveExams = async () => {
    try {
      const data = await axios.post("/api/v1/subjective/admin-get-all-exams-subjective");
      setSubjectiveExams(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };

  // Get All Subjective Question
  const displayGetAllSubjectiveQuestion = async () => {
    try {
      const response = await axios.get(`/api/v1/question/subjective/admin-get-all-subjectiveQuestions`);
      setSubjectiveQuestions(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };


  // get-all-questions
  const displayQuestions = async () => {
    try {
      const data = await axios.post("/api/v1/question/admin-get-dashboard-questions");
      setQuestion(data?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };

  // Get all reports
  const displayReports = async () => {
    try {
      const response = await axios.post(`/api/v1/reports/admin-get-all-reports`);
      setReports(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };
    // Get all reports
    const displaySubjectiveReports = async () => {
      try {
        const response = await axios.post(`/api/v1/reports/subjective/admin-get-all-Reports-subjectiveReport`);
        setSubjectiveReports(response?.data?.data);
      } catch (error) {
        message.error(error?.response?.data?.message)
      }
    }; 

  // get-all-users
  const displayUsers = async () => {
    try {
      const { data } = await axios.get("/api/v1/auth/admin-get-all-user");
      setUsers(data?.user);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };

   // get-all-vendor
   const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };

  // get-all-contact
  const displayContact = async () => {
    try {
      const { data } = await axios.get("/api/v1/contact/admin-get-contact");
      setContact(data?.contactInfo);
    } catch (error) {
      message.error(error?.response?.data?.message)
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    displayExams();
    displayQuestions();
    displayReports();
    displayUsers();
    displayVendor()
    displayContact();
    displaySubjectiveExams();
    displayGetAllSubjectiveQuestion();
    displaySubjectiveReports();
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    <PageTitle title="Admin Dashboard | Test Series" />
      <>
        <Box sx={{ flexGrow: 1, mr:5 }}>
          <Stack spacing={2} >
            <Grid container spacing={2} >
              <Grid item xs={12} sm={4} >
                <Paper elevation={12}>
                  <Card
                    variant="outlined"
                    style={{ background: "rgb(2, 178, 175)", color: "#fff" }}
                  >
                    <CardContent>
                      <h3 style={{ fontWeight: "bold" }}>Exams </h3>
                      <h6 style={{ fontSize: "30px" }}>{exams?.length + subjectiveExams?.length}</h6>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper elevation={12}>
                  <Card
                    variant="outlined"
                    style={{ background: "rgb(46, 150, 255)", color: "#fff" }}
                  >
                    <CardContent>
                      <h3 style={{ fontWeight: "bold" }}>Questions </h3>
                      <h6 style={{ fontSize: "30px" }}>{question?.length+subjectiveQuestions?.length}</h6>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Paper elevation={12}>
                  <Card
                    variant="outlined"
                    style={{ background: "rgb(184, 0, 216)", color: "#fff" }}
                  >
                    <CardContent>
                      <h3 style={{ fontWeight: "bold" }}>Reports </h3>
                      <h6 style={{ fontSize: "30px" }}>{reports?.length+subjectiveReports?.length}</h6>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Paper elevation={12}>
                  <Card
                    variant="outlined"
                    style={{ background: "rgb(96, 0, 155)", color: "#fff" }}
                  >
                    <CardContent>
                      <h3 style={{ fontWeight: "bold" }}>Users </h3>
                      <h6 style={{ fontSize: "30px" }}>{users?.length}</h6>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Paper elevation={12}>
                  <Card
                    variant="outlined"
                    style={{ background: "rgb(39, 49, 200)", color: "#fff" }}
                  >
                    <CardContent>
                      <h3 style={{ fontWeight: "bold" }}>Accounts </h3>
                      <h6 style={{ fontSize: "30px" }}>{vendors?.length}</h6>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Paper elevation={12}>
                  <Card
                    variant="outlined"
                    style={{ background: "rgb(3, 0, 141)", color: "#fff" }}
                  >
                    <CardContent>
                      <h3 style={{ fontWeight: "bold" }}>Contacts </h3>
                      <h6 style={{ fontSize: "30px" }}>{contact?.length}</h6>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3, m:3 }}>
          Data Chart
        </Typography>
      </>
      <Box>
        <Grid
          container
          spacing={{ xs: 3, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={5.5}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: exams?.length, label: "Exams A" },
                    { id: 1, value: question?.length, label: " Questions B" },
                    { id: 2, value: reports?.length, label: "Reports C" },
                    { id: 3, value: users?.length, label: "Users D" }, 
                    { id: 4, value: vendors?.length, label: "Accounts E" }, 
                    { id: 5, value: contact?.length, label: "Contacts F" },
                  ],
                },
              ]}
              height={200}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5.3}>
            <BarChart
              series={[
                { data: [exams?.length] },
                { data: [question?.length] },
                { data: [reports?.length] },
                { data: [users?.length] },
                { data: [vendors?.length] },
                { data: [contact?.length] },
              ]}
              height={290}
              xAxis={[{ scaleType: "band", data: ["Data Chart"] }]}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* <Container>
        <AllVendorList />{" "}
      </Container> */}

      {/* <Container  sx={{ mt: 3 }}> */}
      <Box sx={{m:3}}>

        <AllUsers />{" "}
      </Box>
      {/* </Container> */}
    </>
  );
};

export default Dashboard;
