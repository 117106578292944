import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button, Box } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../../axiosInstance";
import moment from "moment";

const EditVideo = ({
  refreshData,
  videoId,
  title,
  description,
  startDate,
  endDate,
  instituteId,
}) => {
  
  const [video_Title, setVideo_Title] = useState(title);
  const [video_Description, setVideo_Description] = useState(description);
  const [start_Date, setStart_Date] = useState(startDate);
  const [end_Date, setEnd_Date] = useState(endDate);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // window.location.reload();
  };

  const handleStartDateChange = (date) => {
    setStart_Date(date);
    console.log(date, "date");
  };
  const handleEndDateChange = (date) => {
    setEnd_Date(date);
  };
  const videoStartDate = moment(start_Date).format("MM/DD/YYYY");
  const videoEndDate = moment(end_Date).format("MM/DD/YYYY");

  // Edit Role
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      window.confirm("Are you sure you want to Edit this video information ?")
    ) {
      const addRole = {
        id: videoId,
        title: video_Title,
        description: video_Description,
        startDate: videoStartDate,
        endDate: videoEndDate,
        instituteId: instituteId,
      };

      try {
        const { data } = await axios.put(
          "/api/v1/admin/admin-update-video",
          addRole
        );
        if (data.success === true) {
          message.success(data.message);
          refreshData()
          setIsModalOpen(false);
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit PDF Nodes"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="video_Title"
            label="Title "
            name="video_Title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Title"
            value={video_Title}
            onChange={(e) => setVideo_Title(e.target.value)}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="video_Title"
            label="Description"
            name="video_Title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Description"
            multiline
            value={video_Description}
            onChange={(e) => setVideo_Description(e.target.value)}
          />
          <Box style={{ display: "flex" }} className="Date">
            <Box
              sx={{
                mb: 3,
              }}
              style={{ marginRight: "2%" }}
            >
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="Start Date"
                selected={start_Date}
                value={start_Date}
                onChange={handleStartDateChange}
                minDate={new Date()}
                maxDate={end_Date}
                required
              />
            </Box>
            <Box>
              <DatePicker
                showIcon
                toggleCalendarOnIconClick
                isClearable
                placeholderText="End Date"
                selected={end_Date}
                onChange={handleEndDateChange}
                value={end_Date}
                // eslint-disable-next-line no-mixed-operators
                minDate={(new Date() && start_Date) || new Date()}
                maxDate={end_Date}
                required
              />
            </Box>
          </Box>

          <Button variant="contained" type="submit">
            Edit Note
          </Button>
        </Container>
      </Modal>
    </div>
  );
};

export default EditVideo;
