// import React from 'react'

// const UserDetails = () => {
//   return (
//     <div>
//       <h1>All Students List</h1>
//     </div>
//   )
// }

// export default UserDetails

import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { message } from "antd";
import moment from "moment";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { useParams } from 'react-router-dom';

const UserDetails = () => {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`/api/v1/auth/admin-get-all-institute-user`);
        const userData = response.data.user;
  
        // Find the selected user
        const selectedUser = userData.find((user) => user._id === id);
  
        // Check if the selected user has role 1
        if (selectedUser?.role === 1) {
          // If role 1, show all users
          setUsers(userData);
        } else if (
          selectedUser &&
          selectedUser.instituteAccounts === "Primary Account"
        ) { 
          // Filter users with role 0 and "Primary Account"
          const filteredUsers = userData.filter(
            (user) =>
            //   user.instituteId === selectedUser.instituteId &&
            //   user.instituteAccounts === "Primary Account" &&
              user.role === 0
          );
          setUsers(filteredUsers);
        } else {
          console.log("No matching users found.");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
  
    fetchUsers();
  }, [id]);
  


  //delete User
  const deleteUser = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const { data } = await axios.delete("/api/v1/auth/admin-delete-user", {
          data: { id },
        });
        if (data?.success === true) {
          message.success("User Deleted successfully");
        //   fetchUsers();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // Function to toggle user's active status
  const toggleUserActiveStatus = async (id, isActive) => {
    const action = isActive ? "activate" : "deactivate";
    if (window.confirm(`Are you sure you want to ${action} this user?`)) {
      try {
        const { data } = await axios.post(
          "/api/v1/auth/admin-active-delete-user",
          {
            id,
            isActive: isActive,
          }
        );
        if (data?.success === true) {
          message.success(data?.message);
        //   fetchUsers();
        }
        setResponse(data);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteUser(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <IconButton
            aria-label="view"
            //   onClick={(e) => viewUser(e, value?.row?._id)}
          >
            <VisibilityIcon sx={{ color: "#1976d2" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 180,
      renderCell: (params) => {
        const isActive = params?.row?.isActive === true;
        return (
          <select
            value={isActive.toString()}
            onChange={(e) =>
              toggleUserActiveStatus(params.row._id, e.target.value === "true")
            }
            style={{
              padding: "6px 12px",
              fontSize: "14px",
              borderRadius: "8px",
              border: `1px solid ${isActive ? "green" : "red"}`,
              backgroundColor: isActive ? "#e0ffe0" : "#ffe0e0",
              color: isActive ? "green" : "red",
              cursor: "pointer",
              outline: "none",
              transition:
                "border-color 0.2s, background-color 0.2s, color 0.2s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) =>
              (e.target.style.borderColor = isActive ? "green" : "red")
            }
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      },
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },
    {
        field: "role_name",
        headerName: "Role",
        width: 180,
        editable: true,
      },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: true,
    },

    {
      field: "phone",
      headerName: "Phone",
      width: 180,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY h:mm A"),
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Personal Details | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          All Students List
        </Typography>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              // rows={users}
              rows={users?.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
              // checkboxSelection
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default UserDetails;
