import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button, TextField } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { message } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../axiosInstance";
import PageTitle from "../Components/PageTitle/PageTitle";

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [response, setResponse] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVendors, setFilteredVendors] = useState([]);

  // get all user
  const displayUsers = async () => {
    try {
      const { data } = await axios.get(`/api/v1/auth/admin-get-all-user`);
      setUsers(data?.user);
      setFilteredVendors(data?.user);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayUsers();
  }, []);

  //delete User
  const deleteUser = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const { data } = await axios.delete("/api/v1/auth/admin-delete-user", {
          data: { id },
        });
        if (data?.success === true) {
          message.success("User Deleted successfully");
          displayUsers();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // Function to toggle user's active status
  const toggleUserActiveStatus = async (id, isActive) => {
    const action = isActive ? "activate" : "deactivate";
    if (window.confirm(`Are you sure you want to ${action} this user?`)) {
      try {
        const { data } = await axios.post(
          "/api/v1/auth/admin-active-delete-user",
          {
            id,
            isActive: isActive,
          }
        );
        if (data?.success === true) {
          message.success(data?.message);
          displayUsers();
        }
        setResponse(data);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const exportPDF = () => {
    // Show confirmation dialog
    const confirmDownload = window.confirm("Do you want to download the All Users List as a PDF?");
  
    if (confirmDownload) {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Institute Id",
        "Name",
        "Email",
        "Phone",
        "Create Date",
      ];
  
      // Check for empty vendor data
      if (!users || users.length === 0) {
        message.error("No user data available to export.");
        return;
      }
  
      // Map users data to rows
      const rows = users.map((item, index) => {
        // Ensure all fields are properly accessed and default to "N/A" if missing
        const instituteId = item.instituteId || "N/A";
        const name = item.name || "N/A";
        const email = item.email || "N/A";
        const phone = item.phone || "N/A";
        const createdAt = item.createdAt
          ? moment(item.createdAt).format("MM/DD/YYYY")
          : "N/A";
  
        return [index + 1, instituteId, name, email, phone, createdAt];
      });
  
      // Add title
      doc.text("All Users List", 14, 10);
  
      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });
  
      // Save the PDF
      doc.save("All_Users_List.pdf");
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter vendors based on search query
    const filtered = users.filter(
      (user) =>
      user.name.toLowerCase().includes(query) ||
      user.email.toLowerCase().includes(query)
    );
    setFilteredVendors(filtered);
  };

  

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteUser(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 180,
      renderCell: (params) => {
        const isActive = params?.row?.isActive === true;
        return (
          <select
            value={isActive.toString()}
            onChange={(e) =>
              toggleUserActiveStatus(params.row._id, e.target.value === "true")
            }
            style={{
              padding: "6px 12px",
              fontSize: "14px",
              borderRadius: "8px",
              border: `1px solid ${isActive ? "green" : "red"}`,
              backgroundColor: isActive ? "#e0ffe0" : "#ffe0e0", // Light green for Active, Light red for Inactive
              color: isActive ? "green" : "red", // Green text for Active, Red text for Inactive
              cursor: "pointer",
              outline: "none",
              transition: "border-color 0.2s, background-color 0.2s, color 0.2s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
            onBlur={(e) => (e.target.style.borderColor = isActive ? "green" : "red")}
          >
            <option value="" disabled>Select Status</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        );
      },
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: true,
    },

    {
      field: "phone",
      headerName: "Phone",
      width: 180,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY h:mm A"),
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Dashboard | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          All Users List
        </Typography>

        <TextField
          variant="outlined"
          fullWidth
          label="Search by User Name or User Email"
          placeholder="Search by User Name or User Email"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        />


        <Button
          sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
          variant="contained"
          color="primary"
          onClick={exportPDF}
          startIcon={<DownloadIcon />}
        >
          Export to PDF
        </Button>

        <Paper
          sx={{
            bgcolor: "white",
            borderRadius: 3,
            overflow: "hidden",
          }}
        >
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              rows={filteredVendors.map((row, index) => ({ ...row, rowNumber: index + 1 }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default AllUsers;
