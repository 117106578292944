import React from 'react'
import DemoNavbar from "./DemoNavbar";
import { Box } from '@mui/material';

const DemoSupport = () => {
  return (
    <Box sx={{ marginTop: "100px", padding: "20px", minHeight: "100vh" }}>
        <DemoNavbar />
      <h1> Support Team Connect </h1>
    </Box>
  )
}

export default DemoSupport
