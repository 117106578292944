import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { message } from "antd";
import axios from "../../axiosInstance";

const Exams = () => {
  const [exams, setExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get All Exam Data
  const getAllExams = async () => {
    try {
      const response = await axios.post(`/api/v1/exams/admin-get-all-exams`);
      if (response?.data?.success === true) {
        setExams(response?.data?.data);
        setFilteredExams(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
  }, []);

  // Delete Exam
  const deleteExam = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this Exam?")) {
      try {
        const { data } = await axios.delete(
          "/api/v1/exams/admin-delete-exams",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getAllExams();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // Filter exams based on search query and selected category
  useEffect(() => {
    let filtered = exams;

    if (searchQuery) {
      filtered = filtered.filter((exam) =>
        exam.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter((exam) => exam.category === selectedCategory);
    }

    setFilteredExams(filtered);
  }, [searchQuery, selectedCategory, exams]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 90,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteExam(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <Link to={`/admin/edit/exam/${value?.row?._id}`}>
            <IconButton aria-label="edit">
              <EditIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Link>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Exam Name",
      width: 140,
      editable: true,
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 110,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 110,
      editable: true,
    },
    {
      field: "examSet",
      headerName: "Exam Type",
      width: 110,
      editable: true,
    },
    {
      field: "questions",
      headerName: "Total Questions",
      width: 100,
      editable: true,
      renderCell: (row) => row?.row?.questions?.length,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 100,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 120,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 120,
      editable: true,
    },
    {
      field: "negativeMarking",
      headerName: "Negative Marks",
      width: 110,
      editable: true,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 120,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 120,
      editable: true,
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Exams | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Exams
        </Typography>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          <Box
            sx={{
              pb: 2,
              display: "flex",
              width: "auto",
              justifyContent: "left",
            }}
          >
            <Button variant="contained" color="success" startIcon={<AddIcon />}>
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to="/admin/add/exam"
              >
                Create Exam
              </Link>{" "}
            </Button>
          </Box>
          {/* Search Input */}
          <Box sx={{ pb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              // label="Search Exams"
              label="Search by Exam Name"
              placeholder="Search by Exam Name"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Array.from(new Set(exams.map((exam) => exam.category))).map(
                  (category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                   // Style for header cells
                   "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                // rows={filteredExams}
                rows={filteredExams.map((row, index) => ({ ...row, rowNumber: index + 1 }))}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default Exams;
