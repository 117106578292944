import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Input,
} from "@mui/material";
import { Box } from "@mui/material";
import PageTitle from "../../Components/PageTitle/PageTitle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { message } from "antd";
import axios from "../../axiosInstance";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GetFiles from "./GetFiles";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const File = () => {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [subject_name, setSubject_name] = useState("");
  const [subject_type, setSubject_type] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);
      if (fileSizeInMB > 50) {
        message.error(
          "File size exceeds the limit. Please upload a file with a maximum size of 50MB."
        );
        return;
      }
      if (selectedFile.type !== "application/pdf") {
        message.error("Invalid file type. Please upload a PDF file.");
        return;
      }
      setFile(selectedFile);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const notesStartDate = moment(startDate).format("MM/DD/YYYY");
  const notesEndDate = moment(endDate).format("MM/DD/YYYY");

  // Add the notes
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("subject_name", subject_name);
    formData.append("subject_type", subject_type);
    formData.append("file", file);
    formData.append("startDate", notesStartDate);
    formData.append("endDate", notesEndDate);
    formData.append("instituteId", user?.instituteId);
    formData.append("vendorId", user?._id);

    try {
      const { data } = await axios.post(
        "/api/v1/common/admin-upload-notes",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.success === true) {
        message.success(data.message);
        setSubject_name("");
        setSubject_type("");
        setFile(null);
        setStartDate(null);
        setEndDate(null);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle title="Admin Media Library | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Upload PDF Notes
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Add Notes" {...TabPanel(0)} />
            <Tab label="Notes" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Add PDF(Notes) */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Upload a pdf notes
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="subject_name"
              label="Subject Name"
              name="subject_name"
              required
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Subject Name"
              value={subject_name}
              onChange={(e) => setSubject_name(e.target.value)}
            />

            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Notes Type{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subject_type}
                  label="Notes Type"
                  onChange={(e) => setSubject_type(e.target.value)}
                  required
                >
                  <MenuItem value="Lecture Notes">Lecture Notes</MenuItem>
                  <MenuItem value="Study Guides">Study Guides</MenuItem>
                  <MenuItem value="Assignments">Assignments</MenuItem>
                  <MenuItem value="Research Papers">Research Papers</MenuItem>
                  <MenuItem value="Syllabi">Syllabus</MenuItem>
                  <MenuItem value="Project Reports">Project Reports</MenuItem>
                  <MenuItem value="Reference Materials">
                    Reference Materials
                  </MenuItem>
                  <MenuItem value="Exam Papers">Exam Papers</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Upload PDF File
              </Typography>
              <label htmlFor="upload-file">
                <Input
                  accept="application/pdf"
                  id="upload-file"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  required
                />
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload
                </Button>
              </label>
              {file && (
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                  {file.name}
                </Typography>
              )}
            </Box>

            <Box style={{ display: "flex" }} className="Date" sx={{ mt: 5 }}>
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Add Pdf"}
            </Button>
          </Container>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Notes
          </Typography>
          <GetFiles />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default File;
