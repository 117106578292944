import React, { useState, useEffect } from "react";
import {
  Avatar,
  Container,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  updateProfile,
  updatePassword,
  reloadUser,
} from "../redux/actions/userAction";
import { UPDATE_PROFILE_RESET } from "../redux/constants/userConstants";
import { UPDATE_PASSWORD_RESET } from "../redux/constants/userConstants";
import { message } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import Password from "@mui/icons-material/Password";
import moment from "moment";
import PageTitle from "../Components/PageTitle/PageTitle";
import Navbar from "../Components/Navbar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Footer from "../Components/Footer";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { error, isUpdated, loading } = useSelector((state) => state.profile);
  const [value, setValue] = useState(0);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);
  const created = moment(user?.createdAt).format("MM/DD/YYYY h:mm A");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      name,
      email,
      phone,
    };
    dispatch(updateProfile(userData));
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
    }
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      message.success("Profile Updated Successfully");
      dispatch(reloadUser());
      dispatch({ type: UPDATE_PROFILE_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Profile Update Password
  const handleSubmitPassword = async (e, value) => {
    e.preventDefault();
    const formData = {
      oldPassword,
      newPassword,
      confirmPassword,
    };
    dispatch(updatePassword(formData));
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      message.success("Profile Updated Successfully");
      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, isUpdated]);

  return (
    <div>
      <Navbar />
      <PageTitle title={`${user?.name}'s Profile | Test Series`} />

      <Container sx={{mt:{ xs: 12, sm:12, md: 12, lg: 14 },  minHeight: "100vh"}}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Profile Info" {...TabPanel(0)} />
            <Tab label="Update" {...TabPanel(1)} />
            <Tab label="Password" {...TabPanel(2)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Profile Information
          </Typography>
          <Container sx={{ pt: 3 }}>
            <Box sx={{ bgcolor: "white", p: 3 }} className="userProfile">
              <p>Full name : {name}</p>
              <p>E-mail : {email}</p>
              <p>Phone : {phone}</p>
              <p>Institute Id: {user?.instituteId}</p>
              <p>Joined On : {created}</p>
              {/* <p>Role: {user?.role}</p> */}
            </Box>
          </Container>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Update Profile
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
                <EditIcon />
              </Avatar>
              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="name"
                label="Full Name"
                name="name"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Joined On"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Box>
            <Button disabled={loading} variant="contained" type="submit">
              {loading ? "loading..." : "Update Profile"}
            </Button>
          </Container>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Change Password
          </Typography>
          <Container
            onSubmit={handleSubmitPassword}
            component="form"
            className="form_style border-style"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
                <Password />
              </Avatar>

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="oldPassword"
                name="password"
                label="Old Password"
                type="password"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="NewPassword"
                name="password"
                label="New Password"
                type="password"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="ConfirmPassword"
                name="password"
                label="Confirm Password"
                type="password"
                // required
                // inputProps={{
                //   pattern: "(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}",
                //   title: `- at least 8 characters\n - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                //   - Can contain special characters`
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Box>
            <Button variant="contained" type="submit">
              Update Password
            </Button>
          </Container>
        </CustomTabPanel>
      </Container>
      <Footer />
    </div>
  );
}


// import React, { useState, useEffect } from "react";
// import {
//   Avatar,
//   Container,
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   clearErrors,
//   updateProfile,
//   updatePassword,
//   reloadUser,
// } from "../redux/actions/userAction";
// import { UPDATE_PROFILE_RESET } from "../redux/constants/userConstants";
// import { UPDATE_PASSWORD_RESET } from "../redux/constants/userConstants";
// import { message } from "antd";
// import EditIcon from "@mui/icons-material/Edit";
// import Password from "@mui/icons-material/Password";
// import moment from "moment";
// import PageTitle from "../Components/PageTitle/PageTitle";
// import Navbar from "../Components/Navbar";
// import Footer from "../Components/Footer";

// export default function Profile() {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.user);
//   const { error, isUpdated, loading } = useSelector((state) => state.profile);
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [selectedMenu, setSelectedMenu] = useState(0);
//   const [name, setName] = useState(user?.name);
//   const [email, setEmail] = useState(user?.email);
//   const [phone, setPhone] = useState(user?.phone);
//   const created = moment(user?.createdAt).format("MM/DD/YYYY h:mm A");
//   const [oldPassword, setOldPassword] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

//   const handleMenuClick = (index) => {
//     setSelectedMenu(index);
//     setDrawerOpen(false);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const userData = {
//       name,
//       email,
//       phone,
//     };
//     dispatch(updateProfile(userData));
//   };

//   useEffect(() => {
//     if (user) {
//       setName(user.name);
//       setEmail(user.email);
//       setPhone(user.phone);
//     }
//     if (error) {
//       message.error(error);
//       dispatch(clearErrors());
//     }
//     if (isUpdated) {
//       message.success("Profile Updated Successfully");
//       dispatch(reloadUser());
//       dispatch({ type: UPDATE_PROFILE_RESET });
//     }
//   }, [dispatch, error, isUpdated, user]);

//   // Profile Update Password
//   const handleSubmitPassword = async (e) => {
//     e.preventDefault();
//     const formData = {
//       oldPassword,
//       newPassword,
//       confirmPassword,
//     };
//     dispatch(updatePassword(formData));
//     setOldPassword("");
//     setNewPassword("");
//     setConfirmPassword("");
//   };

//   useEffect(() => {
//     if (error) {
//       message.error(error);
//       dispatch(clearErrors());
//     }
//     if (isUpdated) {
//       message.success("Profile Updated Successfully");
//       dispatch({
//         type: UPDATE_PASSWORD_RESET,
//       });
//     }
//   }, [dispatch, error, isUpdated]);

//   const renderContent = () => {
//     switch (selectedMenu) {
//       case 0:
//         return (
//           <Container sx={{ pt: 3 }}>
//             <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
//               Profile Info
//             </Typography>
//             <Box sx={{ bgcolor: "white", p: 3 }} className="userProfile">
//               <p>Full name: {name}</p>
//               <p>E-mail: {email}</p>
//               <p>Phone: {phone}</p>
//               <p>Institute Id: {user?.instituteId}</p>
//               <p>Joined On: {created}</p>
//             </Box>
//           </Container>
//         );
//       case 1:
//         return (
//           <Container
//             onSubmit={handleSubmit}
//             component="form"
//             className="form_style border-style"
//           >
//             <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
//               Update Profile
//             </Typography>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
//                 <EditIcon />
//               </Avatar>
//               <TextField
//                 sx={{
//                   mb: 3,
//                   "& .MuiInputBase-root": {
//                     color: "text.secondary",
//                   },
//                   fieldset: { borderColor: "rgb(231, 235, 240)" },
//                 }}
//                 fullWidth
//                 id="name"
//                 label="Full Name"
//                 name="name"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 placeholder="Full Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               />

//               <TextField
//                 sx={{
//                   mb: 3,
//                   "& .MuiInputBase-root": {
//                     color: "text.secondary",
//                   },
//                   fieldset: { borderColor: "rgb(231, 235, 240)" },
//                 }}
//                 fullWidth
//                 id="email"
//                 label="E-mail"
//                 name="email"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 placeholder="E-mail"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <TextField
//                 sx={{
//                   mb: 3,
//                   "& .MuiInputBase-root": {
//                     color: "text.secondary",
//                   },
//                   fieldset: { borderColor: "rgb(231, 235, 240)" },
//                 }}
//                 fullWidth
//                 id="phone"
//                 label="Phone"
//                 name="phone"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 placeholder="Joined On"
//                 value={phone}
//                 onChange={(e) => setPhone(e.target.value)}
//               />
//             </Box>
//             <Button disabled={loading} variant="contained" type="submit">
//               {loading ? "loading..." : "Update Profile"}
//             </Button>
//           </Container>
//         );
//       case 2:
//         return (
//           <Container
//             onSubmit={handleSubmitPassword}
//             component="form"
//             className="form_style border-style"
//           >
//             <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
//               Change Password
//             </Typography>
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
//                 <Password />
//               </Avatar>

//               <TextField
//                 sx={{
//                   mb: 3,
//                   "& .MuiInputBase-root": {
//                     color: "text.secondary",
//                   },
//                   fieldset: { borderColor: "rgb(231, 235, 240)" },
//                 }}
//                 fullWidth
//                 id="oldPassword"
//                 name="password"
//                 label="Old Password"
//                 type="password"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 placeholder="Old Password"
//                 value={oldPassword}
//                 onChange={(e) => setOldPassword(e.target.value)}
//               />

//               <TextField
//                 sx={{
//                   mb: 3,
//                   "& .MuiInputBase-root": {
//                     color: "text.secondary",
//                   },
//                   fieldset: { borderColor: "rgb(231, 235, 240)" },
//                 }}
//                 fullWidth
//                 id="NewPassword"
//                 name="password"
//                 label="New Password"
//                 type="password"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 placeholder="New Password"
//                 value={newPassword}
//                 onChange={(e) => setNewPassword(e.target.value)}
//               />

//               <TextField
//                 sx={{
//                   mb: 3,
//                   "& .MuiInputBase-root": {
//                     color: "text.secondary",
//                   },
//                   fieldset: { borderColor: "rgb(231, 235, 240)" },
//                 }}
//                 fullWidth
//                 id="ConfirmPassword"
//                 name="password"
//                 label="Confirm Password"
//                 type="password"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 placeholder="Confirm Password"
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//               />
//             </Box>
//             <Button variant="contained" type="submit">
//               Update Password
//             </Button>
//           </Container>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <Navbar />
//       <PageTitle title={`${user?.name}'s Profile`} />
//       <Box sx={{ display: "flex" }}>
//         <Drawer
//           variant="permanent"
//           open={drawerOpen}
//           sx={{
//             "& .MuiDrawer-paper": {
//               boxSizing: "border-box",
//               // width: 240,
//               marginTop: "100px"
//             },
//           }}
//         >
//           <List>
//             <ListItem button onClick={() => handleMenuClick(0)}>
//               <ListItemText primary="Profile Info" />
//             </ListItem>
//             <ListItem button onClick={() => handleMenuClick(1)}>
//               <ListItemText primary="Update Profile" />
//             </ListItem>
//             <ListItem button onClick={() => handleMenuClick(2)}>
//               <ListItemText primary="Change Password" />
//             </ListItem>
//           </List>
//         </Drawer>
//         <Container sx={{ flexGrow: 1, p: 3,  marginTop: "100px", minHeight: "100vh" }}>
//           {renderContent()}
//         </Container>
//       </Box>
//       <Footer />
//     </div>
//   );
// }
