import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";
import AdbIcon from "@mui/icons-material/Adb";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const currentYear = new Date().getFullYear();
  // Show button when page is scrolled up to a certain distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  // Scroll the page to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Box
        className="footer-main-box"
        sx={{
          backgroundColor: "#252C39",
          width: "100%",
          height: "100%",
          padding: "20px 0 ",
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                className="footer-main-inside-box"
                sx={{ m: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}
              >
                <Box
                  className="footer-text-and-logo-section"
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { xs: "flex-start", md: "center" },
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Box
                    className="footer-text-1"
                    sx={{
                      display: "flex",
                      fontSize: "15px",
                      mt: 3,
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Link
                      style={{
                        fontWeight: "500",
                        color: "white",
                        cursor: "pointer",
                        flexWrap: "nowrap",
                        margin: "0 10px",
                        textDecoration: "none",
                      }}
                      sx={{ m: { xs: 8, sm: 8, md: 8, lg: 8 } }}
                      to="/terms-service"
                    >
                      Terms and Conditions
                    </Link>
                    <Link
                      style={{
                        fontWeight: "500",
                        color: "white",
                        cursor: "pointer",
                        flexWrap: "nowrap",
                        margin: "0 10px",
                        textDecoration: "none",
                      }}
                      sx={{
                        mt: { xs: 10, sm: 10, md: 8, lg: 8 },
                        p: { xs: 8, sm: 8, md: 8, lg: 8 },
                      }}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      style={{
                        fontWeight: "500",
                        color: "white",
                        cursor: "pointer",
                        flexWrap: "nowrap",
                        margin: "0 10px",
                        textDecoration: "none",
                      }}
                      sx={{
                        mt: { xs: 10, sm: 10, md: 8, lg: 8 },
                        p: { xs: 8, sm: 8, md: 8, lg: 8 },
                      }}
                      to="/about"
                      className="up-down-button"
                    >
                      About us
                    </Link>
                    <Link
                      style={{
                        fontWeight: "500",
                        color: "white",
                        cursor: "pointer",
                        flexWrap: "nowrap",
                        margin: "0 10px",
                        textDecoration: "none",
                      }}
                      sx={{ m: { xs: 8, sm: 8, md: 8, lg: 8 } }}
                      to="/contact"
                    >
                      Contact us
                    </Link>
                  </Box>

                  <Box className="footer-logo-img">
                    {/* <Box className="logo" sx={{ margin: 4 }}>
                      <Link to="/" className="nav-logo">
                        <AdbIcon
                          sx={{ display: { xs: "none", md: "flex",  color: "#FFF", }, mr: 1 }}
                        />
                        <Typography
                          noWrap
                          component={Link}
                          to="/"
                          sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontWeight: 600,
                            color: "#FFF",
                            textDecoration: "none",
                          }}
                        >
                          TEST SERIES
                        </Typography>
                      </Link>
                    </Box> */}
                  </Box>
                </Box>

                <Divider sx={{ backgroundColor: "#fff", height: "2px" }} />

                <Box
                  className="footer-under-section"
                  sx={{
                    mt: 3,
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "1.75",
                  }}
                >
                  <p>
                    {/* Gatik Ai Technology is registered in Cardiff under Company
                    No. 09568675 | VAT Reg No. 217562215 */}
                    GatikAI Technologies is one of the growing IT Solutions
                    company based in India. We provides expert solutions in the
                    area of App & Web development.
                  </p>
                  <p>
                    &copy; {currentYear} Copyright by GatikAI Technologies . All
                    rights reserved.
                  </p>
                  <p>
                    Registered Office: Arg group, north avenue, 306A, third
                    floor, road no 9, Sikar Rd, Jaipur, Rajasthan 302013
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <Box className="up-down-button">
          {isVisible && (
            <button onClick={scrollToTop} className="">
              <KeyboardArrowUpIcon sx={{ fontSize: 30 }} />
            </button>
          )}
        </Box> */}
      </Box>
    </div>
  );
};

export default Footer;
