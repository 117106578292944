import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import AddSubjectiveQuestion from "./AddSubjectiveQuestion";
import { Link, useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddSubjectiveExamQuestion() {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [passingMarks, setPassingMarks] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [negativeMarking, setNegativeMarking] = useState("");
  const [examSet, setExamSet] = useState("");
  const [type, setType] = useState("Subjective");
  const [questionCount, setQuestionCount] = useState(0);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const examStartDate = moment(startDate).format("MM/DD/YYYY");
  const examEndDate = moment(endDate).format("MM/DD/YYYY");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const addExam = {
      name,
      category,
      duration,
      totalMarks,
      passingMarks,
      examSet,
      type,
      startDate: examStartDate,
      endDate: examEndDate,
      negativeMarking,
      instituteId: user?.instituteId,
      vendorId: user?._id,
      userId:user?._id,
    };

    try {
      const { data } = await axios.post(
        "/api/v1/subjective/admin-create-exam-subjective",
        addExam
      );

      if (data.success === true) {
        message.success(data.message);
        localStorage.setItem(
          "subjectiveId",
          JSON.stringify(data.newSubjectiveExam._id)
        );
        // Clear form fields
        setName("");
        setCategory("");
        setDuration("");
        setTotalMarks("");
        setPassingMarks("");
        setExamSet("");
        setStartDate(null);
        setEndDate(null);
        setNegativeMarking("");
        setType("");
        // Automatically switch to the correct tab after adding the exam
        setValue(1);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  // Use The Navigate in Question List
  const localStorageId = JSON.parse(localStorage.getItem("subjectiveId"));
  // check the question length
  const getAllQuestionLength = async () => {
    try {
      // Check if localStorageId exists
      if (!localStorageId) {
        message.warning("No questions added yet. Please add questions first.");
        return { success: false, count: 0 };
      }
      const response = await axios.get(`/api/v1/subjective/get-exam-subjective-by-id/${localStorageId}`);
      const count = response?.data?.data?.subjectiveQuestions?.length || 0;
      setQuestionCount(count);
      return { success: true, count };
    } catch (error) {
      message.error(error?.response?.data?.message);
      return { success: false, count: 0 };
    }
  };

  useEffect(() => {
    if (localStorageId) {
      getAllQuestionLength();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageId]);

  const handleShowQuestionList = async () => {
    const { success, count } = await getAllQuestionLength();
    if (!success) {
      return; // Exit if there was an error
    }
    if (count === 0) {
      message.warning("No questions added yet. Please add questions first.");
    } else {
      navigate(`/admin/edit/subjective/exam/${localStorageId}`);
    }
  };
 

  return (
    <>
      <PageTitle title="Admin Subjective Exam and Question | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Add Subjective Exams And Questions
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Add Exam" {...TabPanel(0)} />
            <Tab label="Add Question" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Add Exams */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Create a new Subjective Exam
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Exam Name *"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Exam Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="category"
              label="Category *"
              name="category"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Duration *"
              name="duration"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="totalMarks"
              label="Total Marks *"
              name="totalMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Total Marks"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="passingMarks"
              label="Passing Marks *"
              name="passingMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Passing Marks"
              value={passingMarks}
              onChange={(e) => setPassingMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="negativeMarks"
              label="Negative Marks"
              name="negativeMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Negative Marks"
              value={negativeMarking}
              onChange={(e) => setNegativeMarking(e.target.value)}
            />

            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Exam Set{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={examSet}
                  label="Exam Set"
                  required
                  onChange={(e) => setExamSet(e.target.value)}
                >
                  <MenuItem value="Practice Exam">Practice Exam </MenuItem>
                  <MenuItem value="Live Exam">Live Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Exam
            </Button>
          </Container>
        </CustomTabPanel>

        {/* Add Questions */}
        <CustomTabPanel value={value} index={1}>
          <Typography variant="h6" sx={{ color: "black", pb: 2 }}>
            Add Question
          </Typography>
          <AddSubjectiveQuestion
            vendor_Id={user?._id}
            institute_Id={user?.instituteId}
          />
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            onClick={handleShowQuestionList}
            style={{ textDecoration: "none", color: "#FFF" }}

          >
            Show Question List
          </Button>
        </CustomTabPanel>

        {/* Add Subjective Question */}
        {/* <CustomTabPanel value={value} index={2}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Add Subjective Question
          </Typography>

          <AddSubjectiveQuestion
            vendor_Id={user?._id}
            institute_Id={user?.instituteId}
          />
        </CustomTabPanel> */}
      </Box>
    </>
  );
}
