import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import StarIcon from "@mui/icons-material/Star";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import DemoNavbar from "./DemoNavbar";
import { useNavigate } from "react-router-dom";
import PageTitle from "../PageTitle/PageTitle";
import DemoFooter from "./DemoFooter";

const plans = [
  {
    Allfeatures: "All features",
    icon: <StarIcon sx={{ color: "orange", fontSize: "35px", mr: 1 }} />,
    title: "Standard",
    description:
      "Streamline Essentials; Manage core functionalities, collaboration with parents, and manage student data.",
    features: [
      "2 MCQ Exams -> 1 Practice Test , 1 Live Test ",
      "2 Subjective Exam -> 1 Practice Test , 1 Live Test",
      "3 PDF Notes Upload",
      "Video Lecture upload (2 Videos via YT)",
      "Student Enquiry Notifications",
      "No Reports",
      "1 Primary Account",
      "Student Signup - Unlimited",
      "Test Series Branding",
    ],
  },
  {
    Allfeatures: "All features (Pro+)",
    icon: (
      <BusinessCenterIcon sx={{ color: "orange", fontSize: "35px", mr: 1 }} />
    ),
    title: "Pro",
    description:
      "Empower for Growth; Unlock essential tools for academics and financial management, and more.",
    features: [
      "10 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
      "10 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
      "15 PDF Notes Upload",
      "Video Lecture upload (10 Videos via YT)",
      "Proprietary Video Lectures Upload 4 files (1 gb Total)",
      "Student Enquiry Notifications",
      "view Reports",
      "1 Primary Account 2 Teacher Accounts",
      "Student Signup - Unlimited",
      "Test Series Branding",
    ],
  },
  {
    Allfeatures: "All features (Elite+)",
    icon: <WhatshotIcon sx={{ color: "orange", fontSize: "35px", mr: 1 }} />,
    title: "Elite",
    description:
      "Drive Efficiency; Unleash the power of integrations, from devices & institutions, to HRMS, and beyond.",
    features: [
      "50 MCQ Exams -> 5 Practice Tests , 5 Live Tests ",
      "50 Subjective Exams -> 5 Practice Tests , 5 Live Tests",
      "20 PDF Notes Upload",
      "Video Lecture upload (25 Videos via YT)",
      "Proprietary Video Lectures Upload 10 files (2 gb Total)",
      "Student Enquiry Notifications",
      "Download Reports",
      "2 Primary Accounts 5 Teacher Accounts",
      "Student Signup - Unlimited",
      "Institute Branding",
    ],
  },
];

const DemoPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState();
  const navigate = useNavigate(); 

  const handleClick = (planName) => {
    setSelectedPlan(planName);
    // Navigate to BookADemo and pass state
    navigate("/book-a-demo", { state: { planName } });
  };

  return (
    <>
      <PageTitle title="Test Series Plans | Test Series" />
      <DemoNavbar />
      <Container
        sx={{ mt: { xs: 8, md: 10 }, padding: "20px", minHeight: "100vh" }}
      >
        <Grid container rowSpacing={0} columnSpacing={2}>
          {plans.map((plan, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                variant="outlined"
                sx={{
                  mt: 5,
                  mb: 3,
                  minHeight: "100vh",
                  borderRadius: "25px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  wordSpacing: "2px",
                  letterSpacing: "1.5px",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    sx={{
                      mt: 3,
                      mb: 3,
                      fontWeight: "bold",
                      wordSpacing: "2px",
                      letterSpacing: "1.5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {plan.icon} {plan.title}
                  </Typography>

                  <hr />

                  <Typography
                    variant="body2"
                    // color="text.secondary"
                    gutterBottom
                    sx={{
                      mt: 3,
                      height: {
                        xs: "100px",
                        md: "90px",
                      },
                      wordSpacing: "2px",
                      letterSpacing: "1.5px",
                      color:"#757575"
                    }}
                  >
                    {plan.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 3 }}
                    onClick={() => handleClick(plan.title)} 
                  >
                    Request Demo
                  </Button>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      mt: 1,
                      fontWeight: "bold",
                      color: "#000",
                      wordSpacing: "2px",
                      letterSpacing: "1.5px",
                    }}
                  >
                    {plan.Allfeatures}
                  </Typography>
                  <List dense>
                    {plan.features.map((feature, i) => (
                      <ListItem key={i}>
                        <ListItemIcon>
                          <CheckIcon style={{ color: "orange" }} />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box sx={{ mt: 5 }}>
        <DemoFooter />
      </Box>
    </>
  );
};

export default DemoPlan;
