import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  Container,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "../../axiosInstance";

const EditVendorPlan = ({ userId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorPlan, setVendorPlan] = useState([]);
  const [name, setName] = useState("");
  const [examLimit, setExamLimit] = useState("");
  const [subjectiveExamLimit, setSubjectiveExamLimit] = useState("");
  const [PDFNotesLimit, setPDFNotesLimit] = useState("");
  const [courseVideoLimit, setCourseVideoLimit] = useState("");
  const [reportsLimit, setReportsLimit] = useState("");
  const [primaryAccountLimit, setPrimaryAccountLimit] = useState("");
  const [teacherAccountLimit, setTeacherAccountLimit] = useState("");
  const [planStartDate, setPlanStartDate] = useState("");
  const [planEndDate, setPlanEndDate] = useState(planStartDate);
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorPlanId, setVendorPlanId] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleStartDateChange = (date) => {
    setPlanStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setPlanEndDate(date);
  };

  // Fetch Vendor Plan by Id
  const fetchVendorPlan = async () => {
    try {
      const { data } = await axios.post(
        "/api/v1/plan/admin-get-assign-plan-to-user-id",
        { userId }
      );
      let plan = data?.userPlan;
      setVendorPlan(plan?.userPlan);
      setName(plan?.name);
      setExamLimit(plan?.examLimit);
      setSubjectiveExamLimit(plan?.subjectiveExamLimit);
      setPDFNotesLimit(plan?.PDFNotesLimit);
      setCourseVideoLimit(plan?.courseVideoLimit);
      setReportsLimit(plan?.reportsLimit);
      setPrimaryAccountLimit(plan?.primaryAccountLimit);
      setTeacherAccountLimit(plan?.teacherAccountLimit);
      setPlanStartDate(plan?.startDate);
      setPlanEndDate(plan?.endDate);
      setVendorEmail(plan?.email);
      setVendorName(plan?.userName);
      setVendorId(plan?.userId);
      setVendorPlanId(plan?.planId);
    } catch (error) {
      // message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchVendorPlan();
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const AssignPlanData = {
      userId: vendorId,
      planId: vendorPlanId,
      startDate: planStartDate,
      endDate: planEndDate,
      // name,
      examLimit,
      subjectiveExamLimit,
      PDFNotesLimit,
      courseVideoLimit,
      reportsLimit,
      primaryAccountLimit,
      teacherAccountLimit,
    };
    try {
      const response = await axios.put(
        "/api/v1/plan/admin-update-assign-plan-to-user",
        AssignPlanData
      );
      if (response?.data?.success === true) {
        message.success(response?.data?.message);
        setIsModalOpen(false);
        fetchVendorPlan();
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to assign plan");
    }
  };

  return (
    <div>
      <>
        {vendorPlan ? null : (
          <IconButton aria-label="edit" onClick={showModal}>
            <EditIcon />
          </IconButton>
        )}

        <Modal
          title={`Edit Plan`}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <h4>
            Vendor Name: - <b>{vendorName}</b>
          </h4>

          <h4>
            Vendor Email: - <b>{vendorEmail}</b>
          </h4>
          <Container
            component="form"
            className="form_style border-style"
            onSubmit={handleSubmit}
          >
            <Box>
              <TextField
                sx={{
                  mt: 2,
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="planName"
                label="Plan Name"
                name="planName"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan Name"
                value={name}
                // onChange={(e) => setName(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="examLimit"
                label="MCQ Exam"
                name="examLimit"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan MCQ Exam"
                value={examLimit}
                onChange={(e) => setExamLimit(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="subjectiveExam"
                label="Subjective Exam"
                name="subjectiveExam"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan Subjective Exam"
                value={subjectiveExamLimit}
                onChange={(e) => setSubjectiveExamLimit(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="PDFNotesLimit"
                label="PDF Notes"
                name="PDFNotesLimit"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan PDF Notes"
                value={PDFNotesLimit}
                onChange={(e) => setPDFNotesLimit(e.target.value)}
              />
              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="courseVideoLimit"
                label="Course Video"
                name="courseVideoLimit"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan Course Video"
                value={courseVideoLimit}
                onChange={(e) => setCourseVideoLimit(e.target.value)}
              />
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel shrink htmlFor="reports">
                  User Reports
                </InputLabel>
                <Select
                  label="User Reports"
                  value={reportsLimit}
                  onChange={(e) => setReportsLimit(e.target.value)}
                  displayEmpty
                  id="reports"
                  name="reports"
                  placeholder="User Reports Limit"
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Report Access
                  </MenuItem>
                  <MenuItem value="No Reports">No Reports</MenuItem>
                  <MenuItem value="View Reports">View Reports</MenuItem>
                  <MenuItem value="Download Reports">Download Reports</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{
                  mb: 3,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="primaryAccount"
                label="Primary Account"
                name="primaryAccount"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan Primary Account"
                value={primaryAccountLimit}
                onChange={(e) => setPrimaryAccountLimit(e.target.value)}
              />

              <TextField
                sx={{
                  mb: 2,
                  "& .MuiInputBase-root": {
                    color: "text.secondary",
                  },
                  fieldset: { borderColor: "rgb(231, 235, 240)" },
                }}
                fullWidth
                id="teacherAccountLimit"
                label="Teacher Account"
                name="teacherAccountLimit"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Plan Teacher Account"
                value={teacherAccountLimit}
                onChange={(e) => setTeacherAccountLimit(e.target.value)}
              />
              <h4>Vendor Plan Activate Start Date</h4>
              <Box
                sx={{ mb: 1 }}
                style={{ marginRight: "2%" }}
                className="Date"
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={planStartDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={planEndDate}
                  required
                />
              </Box>
              <h4>Vendor Plan Deactivate End Date</h4>
              <Box className="Date" sx={{ mb: 3 }}>
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={planEndDate}
                  onChange={handleEndDateChange}
                  minDate={planStartDate || new Date()}
                  required
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                color="primary"
              >
                Submit
              </Button>
            </Box>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default EditVendorPlan;
