import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Container,
  Card,
  Box,
  Grid,
  Typography,
  Button,
  CardActions,
  CardContent,
  TextField,
  Tooltip,
  Badge,
} from "@mui/material";
import axios from "../axiosInstance";
import Footer from "../Components/Footer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import { message } from "antd";

const Notes = () => {
  const [notes, setNotes] = useState([]);
  const [search, setSearch] = useState([]);
  const currentDate = moment().startOf("day");

  // Get All note Data
  const getAllNotes = async () => {
    try {
      const response = await axios.post("/api/v1/common/get-all-notes");
      setNotes(response?.data?.data);
      console.log(response.data.data, "pdf data");
      setSearch(response?.data?.data);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllNotes();
  }, []);

  const handleDownload = async (note) => {
    if (!note?.url || !note?.subject_name) {
      message.error("Invalid URL or subject name");
      return;
    }

    // Ensure the filename has a .pdf extension
    let fileName = note.subject_name || note.filename || "download";
    if (!fileName.toLowerCase().endsWith(".pdf")) {
      fileName += ".pdf";
    }

    // Show a confirmation dialog before proceeding with the download
    const confirmDownload = window.confirm(
      `Are you sure you want to download this PDF file: ${fileName}?`
    );

    if (!confirmDownload) {
      return;
    }

    try {
      const response = await fetch(note.url);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the element and URL object after the download
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      message.error("Failed to download PDF:", error);
    }
  };

  // Search All note
  const searchAllNotes = async (e) => {
    let key = e?.target?.value;
    if (key) {
      try {
        const response = await axios.get(
          `/api/v1/common/search-all-notes/${key}`
        );
        if (response.data.data) {
          setSearch(response?.data?.data);
          setNotes(response?.data?.data);
        }
      } catch (error) {
        message.error(error.response?.data?.message);
      }
    } else {
      getAllNotes();
    }
  };

  return (
    <>
      <Navbar />
      <PageTitle title="Notes | Test Series" />
      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="text"
          label="Search Subject Name and Type"
          name="text"
          placeholder="Search Subject Name and Type"
          onChange={searchAllNotes}
        />
      </Container>
      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
        <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              {notes && notes.length > 0 ? (
                notes.map((note, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Badge
                      badgeContent="Upcoming"
                      color="primary"
                      invisible={moment(note.startDate).isSameOrBefore(
                        currentDate
                      )}
                      sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Card
                        style={{
                          animation: "fadeIn 5s ease-in-out",
                          minHeight: "38vh",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Box display="flex" alignItems="center" mb={2}>
                            <PictureAsPdfIcon fontSize="large" color="error" />
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                letterSpacing: ".1rem",
                                marginLeft: "10px",
                                fontSize: "18px",
                                fontWeight: "bold",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {note.subject_name}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              marginTop: "12px",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>Notes Type:</strong> {note.subject_type}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              marginTop: "12px",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>File Size:</strong>{" "}
                            {note.fileSize
                              ? `${note.fileSize.toFixed(2)} MB`
                              : "N/A"}
                          </Typography>
                          {moment(note.startDate).isSameOrBefore(
                            currentDate
                          ) ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                marginTop: "12px",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <strong>Uploaded on:</strong>{" "}
                              {moment(note.startDate).format("MMMM Do YYYY")}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                marginTop: "12px",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <strong>Start Date:</strong>{" "}
                              {moment(note?.startDate).format("MMMM Do YYYY")}
                            </Typography>
                          )}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              marginTop: "12px",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>End Date:</strong>{" "}
                            {moment(note?.endDate).format("MMMM Do YYYY")}
                          </Typography>
                        </CardContent>

                        <CardActions sx={{ justifyContent: "center", mb: 2 }}>
                          {moment(note.startDate).isSameOrBefore(
                            currentDate
                          ) ? (
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={() => handleDownload(note)}
                              sx={{
                                letterSpacing: "1px",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Download Notes
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              fullWidth
                              disabled
                              sx={{
                                letterSpacing: "1px",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              Upcoming Notes
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Badge>
                  </Grid>
                ))
              ) : (
                <Box
                  style={{
                    color: "#878787",
                    margin: "30px 80px",
                    fontSize: 18,
                    letterSpacing: "1px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  No Available Notes
                </Box>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default Notes;
