import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "../../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import SubViewCategoryPermissions from "./SubViewCategoryPermissions";
import { useSelector } from "react-redux";
import SubAssignCategoryPermissionsList from "./SubAssignCategoryPermissionsList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SubCategoryPermissions() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [categoryPermissions, setCategoryPermissions] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorRole, setVendorRole] = useState("");
  const [routerPermission, setRouterPermission] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [addPermissionId, setAddPermissionId] = useState("");
  const [addPermission, setAddPermission] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      field: "Permission Info",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SubViewCategoryPermissions
            permissionName={value?.row?.permissions}
            institute={value?.row?.instituteId}
            userId={value?.row?.user_id?._id}
            permissionID={value?.row?._id}
          />
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 220,
      editable: true,
    },
    {
      field: "user_id",
      headerName: "Vendor Name",
      width: 250,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.name,
    },

    {
      field: "email",
      headerName: "Email Name",
      width: 280,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.email,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 260,
      // editable: true,
      renderCell: (row) => row?.row?.user_id?.role_name,
    },

    {
      field: "permissions",
      headerName: "Permissions",
      width: 240,
      renderCell: (row) => {
        // Render permissions as comma-separated string
        return row.row.permissions
          .map((permission) => permission.permission_name)
          .join(", ");
      },
    },

   
  ];

  // get-all-vendor
  const displayVendor = async () => {
    try {
      const { data } = await axios.get("/api/v1/common/admin-get-vendor");
      setVendors(data?.vendors);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    displayVendor();
  }, []);

  const AddRouterPermissionHandler = (e) => {
    const selectedValue = e.target.value; // Get the selected value
    setVendorId(selectedValue?._id);
    setVendorRole(selectedValue?.role_name);
    setVendorName(selectedValue?.name);
  };

  // Get Category Permission
  const getAllPermissionInRouter = async () => {
    try {
      const response = await axios.get(
        `/api/v1/common/admin-get-category-permission`
      );
      if (response?.data?.success === true) {
        setRouterPermission(response?.data?.allPermissions);
        setCategoryPermissions(response?.data?.allPermissions);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPermissionInRouter();
    return () => clearInterval(intervalId);
  }, []);

  const AddPermissionHandler = (e) => {
    const selectedValue = e.target.value;
    setAddPermissionId(selectedValue);
  };

  // Define a function to get the label for each MenuItem value in Add Permissions
  const getMenuItemLabel = (value) => {
    switch (value) {
      case 0:
        return "Create";
      case 1:
        return "Read";
      case 2:
        return "Update";
      case 3:
        return "Delete";
      default:
        return "";
    }
  };

  // Add Category Permissions
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addCategoryPermissions = {
      user_id: vendorId,
      instituteId: user?.instituteId,
      permissions: [
        {
          id: addPermissionId,
          value: addPermission,
        },
      ],
      permission_id: addPermissionId,
      assignCategoryPermissions: user?._id,
    };

    if (
      window.confirm(
        "Are you sure you want to Assign this Category Permission?"
      )
    ) {
      try {
        const { data } = await axios.post(
          "/api/v1/common/admin-create-category-permission",
          addCategoryPermissions
        );
        if (data.success === true) {
          message.success(data.message);
          // Clear form fields
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <PageTitle title="Admin Category Permissions | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          All Category Permissions
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Category Permissions List" {...TabPanel(0)} />
            <Tab label="Add Category Permissions" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Role List */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            All Category Permissions List
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 400, width: "auto" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                   // Style for header cells
                   "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={categoryPermissions}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>

          <SubAssignCategoryPermissionsList />
        </CustomTabPanel>

        {/* Add Roles */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Assign a new Category Permissions
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorInstituteId"
              label="Vendor Institute Id *"
              name="vendorInstituteId"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Institute Id"
              value={user?.instituteId}
            />
            {/* All User  */}
            <Box sx={{ minWidth: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Vendors Email
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Vendors Email"
                  onChange={AddRouterPermissionHandler}
                  required
                  sx={{ minWidth: "50%" }}
                >
                  {vendors && vendors.length > 0 ? (
                    vendors.map((vendor, index) => (
                      <MenuItem key={index} value={vendor}>
                        {vendor.email}
                      </MenuItem>
                    ))
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      Create a new Vendor
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorName"
              label="Vendor Name *"
              name="vendorName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Name"
              value={vendorName}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="vendorRole"
              label="Vendor Role *"
              name="vendorRole"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Vendor Role"
              value={vendorRole}
            />

            {/* Permission Dropdown */}
            <Box sx={{ width: "100%", mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Permission Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Permission Name"
                  onChange={AddPermissionHandler}
                  required
                >
                  {routerPermission && routerPermission.length > 0 ? (
                    routerPermission.map((routerItem, index) =>
                      routerItem.permissions.map(
                        (permission, permissionIndex) =>
                          // Filter out duplicates before rendering MenuItem
                          !routerPermission.some(
                            (otherRouterItem, otherIndex) =>
                              otherIndex < index &&
                              otherRouterItem.permissions.some(
                                (otherPermission) =>
                                  otherPermission.permission_name ===
                                  permission.permission_name
                              )
                          ) && (
                            <MenuItem
                              key={permissionIndex}
                              value={routerItem?.permission_id}
                            >
                              {permission.permission_name}
                            </MenuItem>
                          )
                      )
                    )
                  ) : (
                    <Box
                      style={{
                        color: "#878787",
                        margin: "10px",
                        fontSize: 18,
                      }}
                    >
                      No available Permission
                    </Box>
                  )}
                </Select>
              </FormControl>
            </Box>

            {/* Add Permission */}
            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Add Permission{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={addPermission}
                  label="Add Permission"
                  multiple // This allows selecting multiple items
                  onChange={(e) => setAddPermission(e.target.value)}
                  required
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip
                          sizes="medium"
                          key={value}
                          label={getMenuItemLabel(value)}
                        />
                      ))}
                    </div>
                  )}
                >
                  <MenuItem value={0}>Create</MenuItem>
                  <MenuItem value={1}>Read</MenuItem>
                  <MenuItem value={2}>Update</MenuItem>
                  <MenuItem value={3}>Delete</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Category
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
