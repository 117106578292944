import React, { useEffect, useState } from "react";
import axios from "../../axiosInstance";
import { message } from "antd";
import Button from "@mui/material/Button";
import jsPDF from "jspdf";
import { Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const QuestionPaperPDF = ({ id }) => {
  const [questions, setQuestions] = useState([]);
  const [examName, setExamName] = useState("");

  // Fetch exam data by ID
  const fetchExamById = async () => {
    try {
      const response = await axios.get(`/api/v1/exams/get-exam-by-id/${id}`);
      setQuestions(response.data.data.questions);
      setExamName(response.data.data.name);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to fetch question paper"
      );
    }
  };

  // Function to generate Question Paper PDF
  const generateQuestionPaperPDF = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });

    let yPos = 10;

    // Calculate center position for title
    const pageWidth = doc.internal.pageSize.width;
    const textWidth =
      (doc.getStringUnitWidth(examName) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const centerX = (pageWidth - textWidth) / 2;

    // Add exam title centered
    doc.setFontSize(16);
    doc.text(examName, centerX, yPos);
    yPos += 15; // Increase yPos for title spacing

    // Add questions and options
    questions.forEach((question, index) => {
      // Add question number and name
      const questionText = `${index + 1}. ${question.name}`;
      const options = question.options.map((option, idx) => {
        return `${String.fromCharCode(97 + idx)}. ${option}`;
      });

      const content = doc.splitTextToSize(
        `${questionText}\n${options.join("\n")}`,
        doc.internal.pageSize.width - 20
      );

      if (
        yPos + doc.getTextDimensions(content).h >
        doc.internal.pageSize.height - 10
      ) {
        doc.addPage();
        yPos = 10;
      }
      doc.setFont("normal");
      doc.setFontSize(12);
      doc.text(content, 10, yPos);
      yPos += doc.getTextDimensions(content).h + 10;
    });

    // Save Question Paper PDF
    doc.save("question_paper.pdf");
  };

  // Function to generate Answer Key PDF
  const generateAnswerKeyPDF = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });

    let yPos = 10;
    doc.setFontSize(16);
    doc.text(`Answer Key - ${examName}`, 10, yPos);
    yPos += 15;
    // Add questions and options with correct indication
    questions.forEach((question, index) => {
      // Add question number and name
      const questionText = `${index + 1}. ${question.name}`;
      const options = question.options.map((option, idx) => {
        return `${String.fromCharCode(97 + idx)}. ${option}`;
      });
      // Find the correct option index
      const correctOptionIndex = parseInt(question.correctOption);
      const content = doc.splitTextToSize(
        `${questionText}\n${options.join(
          "\n"
        )}\nCorrect Answer: ${String.fromCharCode(97 + correctOptionIndex)}. ${
          question.options[correctOptionIndex]
        }`,
        doc.internal.pageSize.width - 20
      );
      if (
        yPos + doc.getTextDimensions(content).h >
        doc.internal.pageSize.height - 10
      ) {
        doc.addPage();
        yPos = 10;
      }
      doc.setFontSize(12);
      doc.text(content, 10, yPos);
      yPos += doc.getTextDimensions(content).h + 10;
    });
    // Save Answer Key PDF
    doc.save("answer_key.pdf");
  };

  useEffect(() => {
    if (id) {
      fetchExamById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box className="question-paper">
      <Button
        variant="contained"
        color="primary"
        onClick={generateQuestionPaperPDF}
        startIcon={<DownloadIcon />}
      >
        Question Paper
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={generateAnswerKeyPDF}
        startIcon={<DownloadIcon />}
        style={{ marginLeft: "10px" }}
      >
        Answer Key
      </Button>
    </Box>
  );
};

export default QuestionPaperPDF;
