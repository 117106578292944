import React, { useState } from "react";
import { Modal, message } from "antd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Container, TextField, Button, Box } from "@mui/material";
import axios from "../../axiosInstance";
import moment from "moment";
import DatePicker from "react-datepicker";

const EditJobNotification = ({
  refreshData,
  latestPostId,
  latestPostName,
  latestNotificationDetails,
  urlJobNotification,
  latestLastDate,
  startDate,
  endDate,
  instituteId,
  vendorId,
}) => {
  const [post_Name, setPost_Name] = useState(latestPostName);
  const [last_Date, setLast_Date] = useState(latestLastDate);
  const [notification_Details, seNotification_Details] = useState(
    latestNotificationDetails
  );
  const [urlJob_Notification, setUrlJob_Notification] = useState(urlJobNotification);

  const [post_StartDate, setPost_StartDate] = useState(startDate);
  const [post_EndDate, setPost_EndDate] = useState(endDate);
  // const [refreshDataRole, setRefreshDataRole] = useState(refreshData)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleStartDateChange = (date) => {
    setPost_StartDate(date);
  };

  const handleEndDateChange = (date) => {
    setPost_EndDate(date);
  };

  const handleJobPostStartDateChange = (date) => {
    setLast_Date(date);
  };

//   const handleJobPostStartDateChange = (date) => {
//     setPost_StartDate(date);
//   };

  const jobPostStartDate = moment(post_StartDate).format("MM/DD/YYYY");
  const jobPostEndDate = moment(post_EndDate).format("MM/DD/YYYY");
  const jobPostDate = moment(last_Date).format("MM/DD/YYYY");

  // Edit Job Notification
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure you want to Edit this Job Notification Details?"
      )
    ) {
      const editJobNotification = {
        id: latestPostId,
        latestPostName: post_Name,
        latestLastDate: jobPostDate,
        latestNotificationDetails: notification_Details,
        urlJob_Notification:urlJob_Notification,
        startDate: jobPostStartDate,
        endDate: jobPostEndDate,
        instituteId: instituteId,
        vendorId: vendorId,
      };

      try {
        const { data } = await axios.put(
          "/api/v1/admin/admin-edit-job-notification",
          editJobNotification
        );
        if (data?.success === true) {
          message.success(data?.message);
          refreshData();
          setIsModalOpen(false);
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div>
      <>
        <IconButton aria-label="edit" onClick={showModal}>
          <EditIcon sx={{ color: "#1976d2" }} />
        </IconButton>
        <Modal
          title="Edit Job Notification"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={false}
         >
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="latestPostName"
              label="Latest Post Name"
              name="latestPostName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Latest Post Name"
              value={post_Name}
              onChange={(e) => setPost_Name(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  fullWidth
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Job Notification Last Date"
                  selected={last_Date}
                  value={last_Date}
                  onChange={handleJobPostStartDateChange}
                  required
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && last_Date) || new Date()}
                  maxDate={last_Date}
                />
              </Box>
            </Box>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="latestNotificationDetails"
              label="Latest Notification Details"
              name="latestNotificationDetails"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Latest Notification Details"
              value={notification_Details}
              onChange={(e) => seNotification_Details(e.target.value)}
            />

<TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="urlJobNotification"
              label="Url Job Notification"
              name="urlJobNotification"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Url Job Notification"
              value={urlJob_Notification}
              onChange={(e) => setUrlJob_Notification(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={post_StartDate}
                  value={post_StartDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={post_EndDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={post_EndDate}
                  onChange={handleEndDateChange}
                  value={post_EndDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && post_StartDate) || new Date()}
                  maxDate={post_EndDate}
                />
              </Box>
            </Box>

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Notification
            </Button>
          </Container>
        </Modal>
      </>
    </div>
  );
};

export default EditJobNotification;
