import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/userAction";
import { message } from "antd";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElReports, setAnchorElReports] = React.useState(null);
  const [anchorElTopRank, setAnchorElTopRank] = React.useState(null);
  
  const handleOpenReportsMenu = (event) => {
    setAnchorElReports(event.currentTarget);
  };
  
  const handleCloseReportsMenu = () => {
    setAnchorElReports(null);
  };
  
  const handleOpenTopRankMenu = (event) => {
    setAnchorElTopRank(event.currentTarget);
  };
  
  const handleCloseTopRankMenu = () => {
    setAnchorElTopRank(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to Log out?")) {
      dispatch(logout());
      localStorage.removeItem("token");
      message.success("Logout Successfully");
      navigate("/");
    }
  };

  return (
    <div>
      {/* <AppBar position="static" sx={{ backgroundColor: "#2c3e50"}}> */}
      <AppBar
        sx={{
          backgroundColor: "#FFF",
          color: "#000",
          fontWeight: "600",
          position: "absolute",
          p: 1.5,
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <Typography
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Menu Icon for Mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {/* Menu for Mobile */}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <ul style={{ padding: "2px" }}>
                    <li style={{ textDecoration: "none", listStyle: "none" }}>
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/exams"
                      >
                        Exams
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/subjective/exams"
                      >
                        Subjective Exams
                      </Link>
                    </li>

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/notes"
                      >
                        Notes
                      </Link>
                    </li>
                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/courses"
                      >
                        Courses
                      </Link>
                    </li>

                    {/* <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                        to="/reports"
                      >
                        Reports
                      </Link>
                    </li> */}

                    {/* <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                        to="/reports/top-rank"
                      >
                        TopRank
                      </Link>
                    </li> */}

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </MenuItem>
              </Menu>
            </Box>

            {/* Logo */}
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              TEST SERIES
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/"
              >
                Home
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/about"
              >
                About
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/exams"
              >
                Exams
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/subjective/exams"
              >
                Subjective Exams
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/courses"
              >
                Courses
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/notes"
              >
                Notes
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                }}
                to="/contact"
              >
                Contact
              </Link>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user?.name} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <>
                    <ul style={{ padding: "2px" }}>
                      <li style={{ textDecoration: "none", listStyle: "none" }}>
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to="/profile"
                        >
                          Profile
                        </Link>
                      </li>

                      <li
                        style={{
                          textDecoration: "none",
                          listStyle: "none",
                          marginTop: "8px",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            marginTop: "12px",
                          }}
                          to="/reports"
                        >
                          Reports
                        </Link>
                      </li>

                      <li
                        style={{
                          textDecoration: "none",
                          listStyle: "none",
                          marginTop: "8px",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            marginTop: "12px",
                          }}
                          to="/reports/top-rank"
                        >
                          TopRank
                        </Link>
                      </li>
                      <hr />
                      {isAuthenticated ? (
                        <li
                          style={{
                            textDecoration: "none",
                            listStyle: "none",
                            marginTop: "12px",
                          }}
                        >
                          {isAuthenticated && (
                            <li onClick={handleLogout}>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  marginTop: "12px",
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                to="#"
                              >
                                {isAuthenticated && "Logout"}
                              </Link>
                            </li>
                          )}
                        </li>
                      ) : (
                        <>
                          <li
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              marginTop: "12px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              textAlign="center"
                              to="/login"
                            >
                              Login
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
