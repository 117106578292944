import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import moment from "moment";
import PeopleIcon from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";

const AllInstituteDetails = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [response, setResponse] = useState(null);

  // get all user
  let userID  = user._id;

  const displayUsers = async () => {
    try {
      const response = await axios.get(`/api/v1/auth/admin-get-all-institute-user`);
      console.log(response, "all vendor users");
  
      if (response?.data?.success === true) {
        // Extract the user data from the response
        const users = response.data.user;
  
        // Filter and transform the user data
        const filteredData = users
          .filter((user) => user.instituteAccounts === "Primary Account" || user.role_name === "Institute Owner")
          .map((user) => {
            return {
              ...user,
              instituteId: user?.instituteId,
              role: user?.role_name,
              instituteAccounts: user?.instituteAccounts,
              name: user?.name,
              email: user?.email,
              phone: user?.phone,
              _id: user?._id,
              role: user?.role,
              createdAt: user?.createdAt,
            };
          });
  
        console.log(filteredData, "filteredData");
        setUsers(filteredData);
      } else {
        message.error(response?.data?.message);
        console.log(response, "error response");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  
  useEffect(() => {
    displayUsers();
  }, []);
  

  //delete User
  const deleteUser = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const { data } = await axios.delete("/api/v1/auth/admin-delete-user", {
          data: { id },
        });
        if (data?.success === true) {
          message.success("User Deleted successfully");
          displayUsers();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // Function to toggle user's active status
  const toggleUserActiveStatus = async (id, isActive) => {
    const action = isActive ? "activate" : "deactivate";
    if (window.confirm(`Are you sure you want to ${action} this user?`)) {
      try {
        const { data } = await axios.post(
          "/api/v1/auth/admin-active-delete-user",
          {
            id,
            isActive: isActive,
          }
        );
        if (data?.success === true) {
          message.success(data?.message);
          displayUsers();
        }
        setResponse(data);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 20,
      sortable: false,
    },
    {
      field: "Actions",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={`/admin/user/details/${value?.row?._id}`}>
            <IconButton aria-label="view">
              <PeopleIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Link>
           {/* <Link 
          to={`/admin/user/details/${value?.row?._id}`} 
          style={{ textDecoration: 'none', color: '#1976d2' }}
        >
          <VisibilityIcon sx={{ color: "#1976d2" }} />
        </Link> */}
        </Box>
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },
    {
      field:"instituteAccounts",
      headerName:"Institute Account",
      width: 180,
      editable: true,
    },
    {
      field:"role_name",
      headerName:"Role",
      width: 150,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      editable: true,
    },

    {
      field: "phone",
      headerName: "Phone",
      width: 180,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY h:mm A"),
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Personal Details | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          All Student Details
        </Typography>

        <Paper sx={{ bgcolor: "white" }}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row?._id}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  color: "black",
                },
                color: "black",
                [`& .${gridClasses?.row}`]: {
                  bgcolor: "white",
                },
                // Style for header cells
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "rgb(25, 118, 210)",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold",
                  // fontSize: "13px",
                },
                // Style for checkbox in header
                "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                  color: "#FFF",
                },
                "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
              }}
              // rows={users}
              rows={users?.map((row, index) => ({
                ...row,
                rowNumber: index + 1,
              }))}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
              // checkboxSelection
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default AllInstituteDetails;
