import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import moment from "moment";
import axios from "../axiosInstance";
import { message } from "antd";
import Footer from "../Components/Footer";
import CircularProgress from "@mui/material/CircularProgress";

const Reports = () => {
  const [reportsData, setReportsData] = useState([]);
  const [subjectiveReportsData, setSubjectiveReportsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      const response = await axios.post(
        `/api/v1/reports/get-all-reports-by-user`
      );
      setReportsData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllSubjectiveReports = async () => {
    try {
      const response = await axios.post(`/api/v1/reports/subjective/get-all-subjectiveReport-by-user`);
      setSubjectiveReportsData(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    getData();
    getAllSubjectiveReports();
  }, []);

  const mergedData = [...reportsData, ...subjectiveReportsData];

  const columns = [
    {
      field: "examName",
      headerName: "Exam Name",
      width: 260,
      editable: true,
      valueGetter: (params) => params.row.exam?.name || params.row.examName,
    },
    {
      field: "category",
      headerName: "Exam Category",
      width: 180,
      editable: true,
      valueGetter: (params) => params.row.exam?.category || params.row.category,
    },
    {
      field: "examSet",
      headerName: "Exam Type",
      width: 180,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 180,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 160,
      editable: true,
    },
    {
      field: "obtainedMarks",
      headerName: "Obtained Marks",
      width: 160,
      renderCell: (row) => {
        const obtainedMarks = row?.row?.result?.obtainedMarks || row?.row?.totalMarksObtained;
        return obtainedMarks ? obtainedMarks.toFixed(2) : '0.00';
      },
    },
    {
      field: "resultVerdict",
      headerName: "Result",
      width: 160,
      editable: true,
      renderCell: (row) => row?.row?.result?.verdict || row?.row?.verdict,
    },
    {
      field: "date",
      headerName: "Date",
      width: 160,
      editable: true,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <>
      <Navbar />
      <PageTitle title="Exam Reports | Test Series" />
      <Box sx={{pl:{xs:2, sm:2, md: 12, lg: 14 }, pr:{xs:2,sm:2, md: 12, lg: 14 }, pt: 3, minHeight: "100vh", mt:{ xs: 12, sm:12, md: 12, lg: 14 }}}>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Exam Reports
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                   // Style for header cells
                   "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={mergedData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                // checkboxSelection
              />
            </Box>
           </Paper>
        )}

        <Box sx={{ mt: 5 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Summary
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card sx={{ bgcolor: "#f5f5f5" }}>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    Total Exams Taken
                  </Typography>
                  <Typography variant="h4">
                    {mergedData.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ bgcolor: "#f5f5f5" }}>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    Average Score
                  </Typography>
                  <Typography variant="h4">
                    {(
                      mergedData.reduce(
                        (acc, report) =>
                          acc + (report?.result?.obtainedMarks || report?.totalMarksObtained || 0),
                        0
                      ) / mergedData.length 
                    ).toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ bgcolor: "#f5f5f5", mb:5 }}>
                <CardContent>
                  <Typography variant="h6" color="primary">
                    Success Rate
                  </Typography>
                  <Typography variant="h4">
                    {(
                      (mergedData.filter(
                        (report) => (report?.result?.verdict || report?.verdict) === "Pass"
                      ).length /
                        mergedData.length) *
                      100
                    ).toFixed(2)}
                    %
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Reports;
