import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  MenuItem,
} from "@mui/material";
import { message } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import axios from "../../axiosInstance";
import PageTitle from "../../Components/PageTitle/PageTitle";

import EditRole from "../Roles/EditRole";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const batches = [
  {
    name: "Batch 12th Class A",
    description: "This is Batch 1 description",
    startDate: "2024-09-15",
    duration: "6 months",
  },
  {
    name: "Batch 12th Class B",
    description: "This is Batch 1 description",
    startDate: "2024-09-15",
    duration: "6 months",
  },
  {
    name: "Batch 10th Class A",
    description: "This is Batch 2 description",
    startDate: "2024-10-01",
    duration: "4 months",
  },

  {
    name: "Batch 10th Class B",
    description: "This is Batch 3 description",
    startDate: "2024-09-15",
    duration: "6 months",
  },
  {
    name: "SSC",
    description: "This is Batch 4 description",
    startDate: "2024-10-01",
    duration: "4 months",
  },

  {
    name: "JEE",
    description: "This is Batch 5 description",
    startDate: "2024-09-15",
    duration: "6 months",
  },
  {
    name: "MCA",
    description: "This is Batch 6 description",
    startDate: "2024-09-15",
    duration: "6 months",
  },
];

export default function Roles() {
  const [value, setValue] = useState(0);
  const [batchName, setBatchName] = useState("");
  const [batchDuration, setBatchDuration] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [searchQuery, setSearchQuery] = useState("");
  const [batchClassMode, setBatchClassMode] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);
  };

  const handleEndTimeChange = (newEndTime) => {
    setEndTime(newEndTime);
  };

  const handleChangeClassMode = (event) => {
    setBatchClassMode(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const batchStartDate = moment(startDate).format("MM/DD/YYYY");
  const batchEndDate = moment(endDate).format("MM/DD/YYYY");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (e) => {
    setSearchQuery(e?.target?.value);
  };

  // Get Role Data
  const getAllRole = async () => {
    // try {
    //   const response = await axios.get(`/api/v1/admin/admin-get-role`);
    //   if (response?.data?.success === true) {
    //     setRoles(response?.data?.role);
    //   } else {
    //     message.error(response?.data?.message);
    //   }
    // } catch (error) {
    //   message.error(error?.response?.data?.message);
    // }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Role
  const deleteRole = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this Role?")) {
      try {
        const { data } = await axios.delete("/api/v1/admin/admin-delete-role", {
          data: { id },
        });
        if (data.success === true) {
          message.success(data?.message);
          getAllRole();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteRole(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditRole
            refreshData={getAllRole}
            roles={value?.row?._id}
            roleName={value?.row?.role_name}
            roleValue={value?.row?.value}
          />
        </Box>
      ),
    },
    {
      field: "role_name",
      headerName: "Role Name",
      width: 180,
      editable: true,
    },
    {
      field: "value",
      headerName: "Role Value",
      width: 180,
      editable: true,
    },

    {
      field: "createdAt",
      headerName: "Role Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  // Add Roles
  const handleSubmit = async (e) => {
    // e.preventDefault();
    // const addRole = {
    //   role_name: roleName,
    //   value: roleValue,
    //   // vendorId: user?._id, // todo
    // };
    // try {
    //   const { data } = await axios.post(
    //     "/api/v1/admin/admin-add-role",
    //     addRole
    //   );
    //   if (data.success === true) {
    //     message.success(data.message);
    //     // Clear form fields
    //     setRoleName("");
    //     setRoleValue("");
    //   } else {
    //     message.error(data?.message);
    //   }
    // } catch (error) {
    //   message.error(error?.response?.data?.message);
    // }
  };

  return (
    <>
      <PageTitle title="Admin Add Batches | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Students Batch List
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Batch List" {...TabPanel(0)} />
            <Tab label="Add Batch" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Role List */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            All Student Batch List
          </Typography>

          {/* Search Input */}
          <Box sx={{ pb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search Batch Name"
              placeholder="Search Batch Name"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Box>

          <Grid container spacing={2} sx={{ pb: 2, pt: 3 }}>
            {batches.map((batch, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  style={{
                    animation: "fadeIn 5s ease-in-out",
                    minHeight: "30vh",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      style={{
                        letterSpacing: ".1rem",
                        fontSize: "18px",
                        marginBottom: "12px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {batch.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        letterSpacing: ".1rem",
                        // fontSize: "18px",
                        marginBottom: "12px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong>Category</strong> : {batch.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        letterSpacing: ".1rem",
                        // fontSize: "18px",
                        marginBottom: "12px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong> Start Date </strong> : {batch.startDate}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{
                        letterSpacing: ".1rem",
                        // fontSize: "18px",
                        marginBottom: "12px",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <strong> Duration</strong> : {batch.duration}
                    </Typography>
                  </CardContent>
                  {/* <hr /> */}
                  <CardActions sx={{ mb: 2 }}>
                    <Button
                      variant="outlined"
                      fullWidth
                      component={Link}
                      to="/admin/student/batch"
                      onClick={() => alert(`Enroll in ${batch.name}`)}
                      sx={{
                        letterSpacing: "1px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      Enroll
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
        {/* Add student batch */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Create a new Batch
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Batch Name *"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Batch Name"
              value={batchName}
              onChange={(e) => setBatchName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Batch Duration *"
              name="duration"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Batch Duration"
              value={batchDuration}
              onChange={(e) => setBatchDuration(e.target.value)}
            />

            <TextField
              select
              label="Class Mode"
              placeholder="Class Mode"
              value={batchClassMode}
              onChange={handleChangeClassMode}
              fullWidth
              variant="outlined"
            >
              {/* Dropdown options */}
              <MenuItem disabled>Select Class Mode</MenuItem>
              <MenuItem value="online">Online</MenuItem>
              <MenuItem value="offline">Offline</MenuItem>
            </TextField>

            <Box
              style={{ display: "flex", marginTop: "30px" }}
              className="Date"
            >
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Batch Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Batch End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>
            <Box style={{ display: "flex" }} className="Date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* Start Time Picker */}
                <Box
                  sx={{
                    mb: 3,
                  }}
                  style={{ marginRight: "2%" }}
                >
                  <TimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                    )}
                  />
                </Box>
                {/* End Time Picker */}
                <Box>
                  <TimePicker
                    label="End Time"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Batch
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
