import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import {
  Container,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
} from "@mui/material";
import { message } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import PageTitle from "../../Components/PageTitle/PageTitle";

const SubjectiveTopRank = () => {
  const [loading, setLoading] = useState(true);
  const [topRankData, setTopRankData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/reports/subjective/admin-get-top-rank-subjectiveReport"
        );
        const data = response.data.data;
        const sortedData = Object.values(response.data.topRankData).sort(
          (a, b) => b.totalMarksObtained - a.totalMarksObtained
        );

        setTopRankData(sortedData);
      } catch (error) {
        message.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to export data to PDF
  const exportPDF = () => {
    const confirmDownload = window.confirm(
      "Do you want to download the Top Rank Reports as a PDF?"
    );

    if (confirmDownload) {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Student Name",
        "Student Email",
        "Exam Name",
        "Student Score",
      ];
      const rows = topRankData.map((item, index) => [
        index + 1,
        item.user?.name || "N/A",
        item.user?.email || "N/A",
        item.exam?.name || "N/A",
        `${item.totalMarksObtained || 0} / ${item.exam?.totalMarks || 0}`,
      ]);

      // Add title
      doc.text("Top Rank Reports", 14, 10);

      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });

      // Save the PDF
      doc.save("Top_Rank_Reports.pdf");
    }
  };

  return (
    <>
      <PageTitle title="Admin Subjective Exam TopRank | Test Series" />
      <Box sx={{ minHeight: "100vh" }}>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Top Rank Reports
        </Typography>

        {topRankData.length > 0 && (
          <Button
            sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            variant="contained"
            color="primary"
            onClick={exportPDF}
            startIcon={<DownloadIcon />}
          >
            Export to PDF
          </Button>
        )}

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : topRankData.length === 0 ? (
          <Typography variant="h6" sx={{ textAlign: "center", mt: 5 }}>
            No Reports Available at This Time
          </Typography>
        ) : (
          <Paper sx={{ bgcolor: "white" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
                >
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Top Rank
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Institute Id
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Student Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Student Email
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Exam Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      Student Score
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topRankData.map((item, index) => (
                    <TableRow
                      key={item._id}
                      sx={{
                        "&:nth-of-type(even)": { bgcolor: "#f5f5f5" },
                        "&:nth-of-type(odd)": { bgcolor: "#ffffff" },
                        ...(index === 0 ? { backgroundColor: "#dbe3e8" } : {}),
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.exam?.instituteId || "N/A"}</TableCell>
                      <TableCell>{item.user?.name || "N/A"}</TableCell>
                      <TableCell>{item.user?.email || "N/A"}</TableCell>
                      <TableCell>{item.exam?.name || "N/A"}</TableCell>
                      <TableCell>
                        {item.totalMarksObtained || 0} /{" "}
                        {item.exam?.totalMarks || 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default SubjectiveTopRank;
