import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import PeopleIcon from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import { message } from "antd";
import axios from "../../axiosInstance";

const Evaluation = () => {
  const [exams, setExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get All Exam Data
  const getAllSubjectiveExams = async () => {
    try {
      const response = await axios.get(
        `/api/v1/reports/subjective/admin-get-all-subjectiveReport`
      );
      if (response?.data?.success === true) {
        // Transform the data
        const transformedData = response.data.data.map(exam => {
          const report = exam.reportIds[0]; 
          return {
            ...exam,
            examName: report.examName,
            category: report.category,
            totalMarks: report.totalMarks,
            passingMarks: report.passingMarks,
            userId: report.userId,
            negativeMarking: report.negativeMarking,
            examId: report.examId,
            instituteId: report.instituteId,
          };
        });
        setExams(transformedData);
        setFilteredExams(transformedData);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllSubjectiveExams();
  }, []);

  // Filter exams based on search query and selected category
  useEffect(() => {
    let filtered = exams;

    if (searchQuery) {
      filtered = filtered.filter((exam) =>
        exam.examName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter((exam) => exam.category === selectedCategory);
    }

    setFilteredExams(filtered);
  }, [searchQuery, selectedCategory, exams]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const columns = [
    {
      field: "Users List",
      width: 120,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={`/admin/subjective/students-details/${value?.row?.examId}`}>
            <IconButton aria-label="view">
              <PeopleIcon sx={{ color: "#1976d2" }} />
            </IconButton>
          </Link>
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 130,
      editable: true,
    },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 270,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 270,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 180,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 180,
      editable: true,
    },
    {
      field: "negativeMarking",
      headerName: "Negative Marks",
      width: 180,
      editable: true,
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Subjective Exams Copy List | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Subjective Exams List
        </Typography>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          {/* Search Input */}
          <Box sx={{ pb: 2, mt: 5 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search Exams"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Box>
          <Box sx={{ pb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {Array.from(new Set(exams.map((exam) => exam.category))).map(
                  (category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.examId} // Ensure examId is unique for each row
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={filteredExams}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default Evaluation;
