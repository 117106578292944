import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditPermission from "./EditPermission";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Permissions = () => {
  const [value, setValue] = useState(0);
  const [permissions, setPermission] = useState([]);
  const [permission_name, setPermission_Name] = useState("");
  const [permissionDefault, setPermissionDefault] = useState("");
  const [description, setDescription] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Permission Data
  const getAllPermission = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-permission`);
      if (response?.data?.success === true) {
        setPermission(response?.data?.permission);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllPermission();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Permission
  const deletePermission = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this Permission?")) {
      try {
        const { data } = await axios.delete(
          "/api/v1/admin/admin-delete-permission",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getAllPermission();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deletePermission(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditPermission
            refreshData={getAllPermission}
            permissions={value?.row?._id}
            permissionName={value?.row?.permission_name}
            permissionDefault={value?.row?.is_default}
            permissionDescription={value?.row?.description}
          />
        </Box>
      ),
    },
    // {
    //   field: "_id",
    //   headerName: "Permission ID",
    //   width: 210,
    //   editable: true,
    // },
    {
      field: "permission_name",
      headerName: "Permission Name",
      width: 200,
      editable: true,
    },
    // {
    //   field: "is_default",
    //   headerName: "Permission Default",
    //   width: 180,
    //   editable: true,
    // },
    {
      field: "description",
      headerName: "Description",
      width: 700,
      editable: true,
    },

    {
      field: "createdAt",
      headerName: "Permission Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  // Add Permission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addRole = {
      permission_name,
      default: permissionDefault,
      description,
      // vendorId: user?._id, // todo
    };

    try {
      const { data } = await axios.post(
        "/api/v1/admin/admin-add-permission",
        addRole
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setPermission_Name("");
        setPermissionDefault("");
        setDescription("");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Permissions | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Permissions
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Permission List" {...TabPanel(0)} />
            <Tab label="Add Permission" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Permissions List */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Permission List
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 550, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                   // Style for header cells
                   "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={permissions}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Add Permissions */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Create a new Permission
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="permission_name"
              label="Permission Name *"
              name="permission_name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Permission Name"
              value={permission_name}
              onChange={(e) => setPermission_Name(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="description"
              label="Permission Description *"
              name="description"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Permission Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Box sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Default Permission{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={permissionDefault}
                  label="Default Permission"
                  required
                  onChange={(e) => setPermissionDefault(e.target.value)}
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Permission
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Permissions;
