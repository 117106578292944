import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { message, Table } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import EditSubjectiveQuestionModal from "./EditSubjectiveQuestionModal";
import QuestionPaperPDF from "./QuestionPaperPDF";

const EditSubjectiveQuestion = () => {
  let { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const [exam, setExam] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [editSubjectiveQuestionModal, setEditSubjectiveQuestionModal] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllExams = async () => {
    try {
      const response = await axios.get(`/api/v1/subjective/get-exam-subjective-by-id/${id}`);
      const examData = response.data.data;
      setExam(examData);
      setQuestions(examData?.subjectiveQuestions);
      setInstituteId(examData.instituteId);
      setVendorId(examData.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredQuestions = exam?.subjectiveQuestions?.filter((question) => {
      return (
        question.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        question.referenceAnswer.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setQuestions(filteredQuestions);
  }, [searchTerm, exam]);

  // Delete Exam Questions By id
  const deleteSubjectiveQuestion = async (questionId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this Subjective Question?"
      )
    ) {
      try {
        const { data } = await axios.post(
          `/api/v1/question/subjective/admin-delete-exam-to-subjectiveQuestions`,
          {
            questionId,
            subjectiveExamId: id,
          }
        );

        console.log(data, "data");
        if (data.success === true) {
          message.success(data.message);
          getAllExams();
        } else {
          message.error(data.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // Show Questions Columns
  const questionsColumns = [
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Box>
            <IconButton
              aria-label="delete"
              onClick={(e) => deleteSubjectiveQuestion(record._id)}
            >
              <DeleteIcon sx={{ color: "red" }} />
            </IconButton>
            <IconButton aria-label="edit">
              <EditIcon
                sx={{ color: "#1976d2" }}
                onClick={(e) => {
                  setSelectedQuestion(record);
                  setEditSubjectiveQuestionModal(true);
                }}
              />
            </IconButton>
          </Box>
        </>
      ),
    },
    {
      title: "Question",
      dataIndex: "name",
    },
    {
      title: "Question Number",
      dataIndex: "questionNumber",
    },
    {
      title: "Reference Answer",
      dataIndex: "referenceAnswer",
    },
  ];

  return (
    <div>
      <Box>
        <QuestionPaperPDF id={id} />
      </Box>
      <Container component="form" className="form_style border-style" maxWidth>
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="search"
          label="Search"
          name="search"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Search by Question Name or Reference Answer"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table
          id="questions-table"
          columns={questionsColumns}
          dataSource={questions}
          style={{
            width: "100%",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        />

        {editSubjectiveQuestionModal && (
          <EditSubjectiveQuestionModal
            setEditSubjectiveQuestionModal={setEditSubjectiveQuestionModal}
            editSubjectiveQuestionModal={editSubjectiveQuestionModal}
            examId={id}
            refreshData={getAllExams}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        )}
        
      </Container>
    </div>
  );
};

export default EditSubjectiveQuestion;
