import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import Mail from "@mui/icons-material/Mail";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import PageTitle from "../Components/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, forgotPassword } from "../redux/actions/userAction";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { error, loading, isAuthenticated, message } = useSelector(
    (state) => state.forgotPassword
  );
  const [email, setEmail] = useState("");

  // password forgot api call
  const handleSubmit = async (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("email", email);
    dispatch(forgotPassword(myForm));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      toast.success(message);
      setEmail("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, message, isAuthenticated]);
  return (
    <div>
      <PageTitle title="Forgot Password | Test Series" />
      <Box
        sx={{
          height: "81vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "primary.white",
        }}
      >
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
              <Mail />
            </Avatar>
            <h2>Forgot your password?</h2>
            <p>Don't fret! Just type in your email and we will send you a code to reset your password!</p>
            <TextField
              sx={{
                mb: 3,
                mt:2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              type="submit"
            >
              {loading ? "loading..." : "Send Email"}
            </Button>
            <Box sx={{ pt: 2 }} className="switchMember">
              {" "}
              Already a member ?{""} <Link to="/login"> Sign In </Link>{" "}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ForgotPassword;
