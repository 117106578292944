import React from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Container, Typography, Box, Paper, Divider, Grid, Avatar } from "@mui/material";
import Footer from "../Components/Footer";

const About = () => {
  return (
    <>
      <Navbar />
      <PageTitle title="About Us | Test Series" />
      <Container sx={{ mt:{ xs: 16, sm:16, md: 16, lg: 18 },mb:8, minHeight: "100vh",  }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ letterSpacing: "2px", fontFamily: 'Poppins, sans-serif',}}>
            About Us
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom sx={{ letterSpacing: "1.5px", fontFamily: 'Poppins, sans-serif',}}>
              Welcome to Test Series!
            </Typography>
            <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
              <span id="W_Name2">Test Series</span> is a professional{" "}
              <span id="W_Type1">educational</span> platform. Here we will only
              provide you with interesting content that you will enjoy very
              much. We are committed to providing you the best of{" "}
              <span id="W_Type2">educational</span>, with a focus on
              reliability and{" "}
              <span id="W_Spec">online exams, notes in PDF format, and recorded videos</span>. 
              We strive to turn our passion for{" "}
              <span id="W_Type3">education</span> into a thriving website. We
              hope you enjoy our <span id="W_Type4">educational</span> content
              as much as we enjoy creating it for you.
            </Typography>
            <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
              We will keep posting valuable and knowledgeable information on
              our website for all of you. Your love and support matter a lot.
            </Typography>
          </Box>
          <Divider sx={{ mb: 3 }} />
          {/* Services Section */}
          <Typography variant="h5" gutterBottom sx={{ letterSpacing: "1.8px", fontFamily: 'Poppins, sans-serif',}}>
            Our Services
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            At Test Series, we offer a variety of services to help you excel in your exams:
          </Typography>
          <Box sx={{ ml: 3 }}>
            <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
              <strong>Online Tests:</strong> Practice with our extensive range of online tests designed to simulate real exam conditions.
            </Typography>
            <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
              <strong>Notes in PDF Format:</strong> Access comprehensive study notes in PDF format, covering all the essential topics.
            </Typography>
            <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
              <strong>Recorded Videos:</strong> Learn at your own pace with our library of recorded video lectures from expert educators.
            </Typography>
          </Box>
          <Divider sx={{ mb: 3, mt: 3 }} />
          {/* Mission Section */}
          <Typography variant="h5" gutterBottom sx={{ letterSpacing: "1.8px", fontFamily: 'Poppins, sans-serif',}}>
            Our Mission
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            Our mission is to provide high-quality educational content and online test series to help students prepare for their exams efficiently. We aim to create a platform that makes learning engaging and accessible for everyone.
          </Typography>
          <Divider sx={{ mb: 3, mt: 3 }} />
          {/* Vision Section */}
          <Typography variant="h5" gutterBottom sx={{ letterSpacing: "1.8px", fontFamily: 'Poppins, sans-serif',}}>
            Our Vision
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            Our vision is to become the leading online educational platform, known for innovation and excellence in providing test series and learning resources. We aspire to empower students worldwide to achieve their academic goals.
          </Typography>
          <Divider sx={{ mb: 3, mt: 3 }} />
          {/* History Section */}
          <Typography variant="h5" gutterBottom sx={{ letterSpacing: "1.8px", fontFamily: 'Poppins, sans-serif',}}>
            Our History
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
          Test Series started as a small project aimed at helping a handful of students. Over the years, we have grown into a comprehensive platform offering a wide range of test series, notes, and recorded videos for various subjects and competitive exams.
          </Typography>
          <Divider sx={{ mb: 3, mt: 3 }} />
          {/* Team Section */}
          {/* <Typography variant="h5" gutterBottom sx={{ letterSpacing: "1.8px", fontFamily: 'Poppins, sans-serif',}}>
            Meet Our Team
          </Typography>
          <Grid container spacing={4} sx={{ mb: 3, letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            {[
              { name: "John Doe", role: "CEO", img: "https://via.placeholder.com/150" },
              { name: "Jane Smith", role: "CTO", img: "https://via.placeholder.com/150" },
              { name: "Bob Johnson", role: "Lead Developer", img: "https://via.placeholder.com/150" },
            ].map((member, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box sx={{ textAlign: "center" }}>
                  <Avatar alt={member.name} src={member.img} sx={{ width: 100, height: 100, mx: "auto" }} />
                  <Typography variant="h6" sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>{member.name}</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
                    {member.role}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid> */}
          {/* <Divider sx={{ mb: 3 }} /> */}
          {/* Contact Section */}
          <Typography variant="h5" gutterBottom sx={{ letterSpacing: "1.8px", fontFamily: 'Poppins, sans-serif',}}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            If you have any questions, suggestions, or feedback, feel free to reach out to us at:
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            E-mail: testseries@gatiktech.com
          </Typography>
          <Typography variant="body1" paragraph sx={{ letterSpacing: "1px", fontFamily: 'Poppins, sans-serif',}}>
            Contact Number: +91-7891555777
          </Typography>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default About;
