import React, { useState, useEffect } from "react";
import axios from "../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { Typography } from "@mui/material";
import { message } from "antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";
import PageTitle from "../Components/PageTitle/PageTitle";

function TopRank() {
  const [topRankData, setTopRankData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/reports/admin-get-top-rank-reports")
      .then((response) => {
        const data = response.data.data;
        const sortedData = data.sort(
          (a, b) => b.maxCorrectAnswersCount - a.maxCorrectAnswersCount
        );
        setTopRankData(sortedData);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }, []);

  // Function to export data to PDF with index column
  const exportPDF = () => {
    // Show confirmation dialog
    const confirmDownload = window.confirm(
      "Do you want to download the Top Rank Reports as a PDF?"
    );

    if (confirmDownload) {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Student Name",
        "Student Email",
        "Exam Name",
        "Student Score",
      ];

      const rows = topRankData.map((item, index) => [
        index + 1,
        item.user?.name || "N/A",
        item.user?.email || "N/A",
        item.exam?.name || "N/A",
        `${item.result?.obtainedMarks ? item.result.obtainedMarks.toFixed(2) : "0.00"} / ${item.exam?.totalMarks || 0}`,
      ]);
      

      // Add title
      doc.text("Top Rank Reports", 14, 10);

      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });

      // Save the PDF
      doc.save("Top_Rank_Reports.pdf");
    }
  };

  return (
    <>
      <PageTitle title="Admin Top Rank List | Test Series" />
      <Typography variant="h5" sx={{ color: "black", pb: 2 }}>
        All Exams Top Rank List
      </Typography>
      <Button
        sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
        variant="contained"
        color="primary"
        onClick={exportPDF}
        startIcon={<DownloadIcon />}
      >
        Export to PDF
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
          >
            <TableRow>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Top Rank
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Institute Id
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Student Name
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Student Email
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Exam Name
              </TableCell>
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Student Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topRankData.length > 0 ? (
              topRankData.map((item, index) => (
                <TableRow
                  key={item._id}
                  sx={{
                    "&:nth-of-type(even)": { bgcolor: "#f5f5f5" },
                    "&:nth-of-type(odd)": { bgcolor: "#ffffff" },
                    ...(index === 0 ? { backgroundColor: "#dbe3e8" } : {}),
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.user?.instituteId || "N/A"}</TableCell>
                  <TableCell>{item.user?.name || "N/A"}</TableCell>
                  <TableCell>{item.user?.email || "N/A"}</TableCell>
                  <TableCell>{item.exam?.name || "N/A"}</TableCell>
                  <TableCell>
                    {item.result?.obtainedMarks
                      ? item.result.obtainedMarks.toFixed(2)
                      : "0.00"}{" "}
                    / {item.exam?.totalMarks || 0}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TopRank;
