import "./App.css";
import "./stylesheets/theme.css";
import "./stylesheets/alignments.css";
import "./stylesheets/textelements.css";
import "./stylesheets/custom-components.css";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { message } from "antd";
import axios from "./axiosInstance";
import { reloadUser, allUsers } from "./redux/actions/userAction";
import store from "./redux/store";
import NotFound from "./pages/Error";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import WriteExam from "./exams/WriteExam";
import About from "./pages/About";
import Exam from "./pages/Exam";
import SubjectiveExam from "./pages/SubjectiveExam";
import SubjectiveWriteExam from "./exams/SubjectiveWriteExam";
import Notes from "./pages/Notes";
import Videos from "./pages/Videos";
import StartVideo from "./pages/StartVideo";
import Contact from "./pages/Contact";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Profile from "./user/Profile";
import Reports from "./user/Reports";
import TopRank from "./user/TopRank";
import Layout from "./admin/global/Layout";
// Admin Routes
import AdminDashboard from "./admin/Dashboard";
import AdminRoles from "./admin/Roles/Roles";
import AdminPermissions from "./admin/Permissions/Permissions";
// import AdminRouterPermissions from "./admin/RouterPermission/RouterPermissions.js";
import AdminProfile from "./admin/Profile";
import AdminExams from "./admin/Exams/Exams";
import AdminAddExamQuestion from "./admin/Exams/AddExamQuestion";
import AdminEditExamQuestion from "./admin/Exams/EditExamQuestion";
import AdminReports from "./admin/Reports";
import AdminEvaluation from "./admin/SubjectiveExam/Evaluation";
import AdminEvaluationStudentsList from "./admin/SubjectiveExam/EvaluationStudentsList";
import AdminEvaluationDetails from "./admin/SubjectiveExam/EvaluationDetails";
import AdminEditEvaluationDetails from "./admin/SubjectiveExam/EditEvaluationDetails";
import AdminSubjectiveReports from "./admin/SubjectiveExam/SubjectiveReports";
import AdminSubjectiveTopRank from "./admin/SubjectiveExam/SubjectiveTopRank";
import AdminTopRank from "./admin/TopRank";
import AdminContact from "./admin/Contact";
import AdminCreateVendor from "./admin/Vendor/CreateVendor";
import AdminEditorVendor from "./admin/Vendor/EditorVendor";
import AdminCategoryPermissions from "./admin/CategoryPermissions/CategoryPermissions";
import ConnectionStatus from "./Components/ConnectionStatus.js";
import AdminFiles from "./admin/Files/Files";
import AdminVideos from "./admin/Videos/Video";
import AdminInstituteInfo from "./admin/InstituteInfo/InstituteInfo";
import AdminJobNotification from "./admin/InstituteInfo/JobNotification";
import AdminSubjectiveExam from "./admin/SubjectiveExam/SubjectiveExam";
import AdminAddSubjectiveExamQuestion from "./admin/SubjectiveExam/AddSubjectiveExamQuestion";
import AdminEditSubjectiveExam from "./admin/SubjectiveExam/EditSubjectiveExam";
import AdminAllInstituteDetails from "./admin/StudentInformationSystem/AllInstituteDetails";
import AdminUserDetails from "./admin/StudentInformationSystem/UserDetails";
import AdminPlans from "./admin/VendorPlans/Plans";
import AdminAssignVendorPlanList from "./admin/VendorPlans/AssignVendorPlans/AssignVendorPlanList";
import AdminNewBatch from "./admin/Batch/NewBatch.js";
import AdminStudentBatch from "./admin/Batch/StudentBatch.js";
// Sub-Admin & Other Roles
import AdminSubEditorVendor from "./admin/SubAdmin/SubEditorVendor";
import AdminSubCategoryPermissions from "./admin/SubAdmin/SubCategoryPermissions/SubCategoryPermissions";
import AdminSubRoutesPermissions from "./admin/SubAdmin/SubRoutesPermissions/SubRoutesPermissions";

// Test Series Demo Pages
import DemoNavbar from "./Components/TestSeriesInformation/DemoNavbar";
import DemoHome from "./Components/TestSeriesInformation/DemoHome";
import DemoAbout from "./Components/TestSeriesInformation/DemoAbout";
import BookADemo from "./Components/TestSeriesInformation/BookADemo";
import DemoContact from "./Components/TestSeriesInformation/DemoContact";
import DemoPlan from "./Components/TestSeriesInformation/DemoPlan";
import DemoSupport from "./Components/TestSeriesInformation/DemoSupport";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  // Disable Right-click
  // useEffect(() => {
  //   document.addEventListener("contextmenu", (e) => e.preventDefault());
  // }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  // Check Server Status
  const checkServerStatus = async () => {
    try {
      const response = await axios.get("/api/status");
      if (response?.data?.data === true) {
        // message.success("Server is running");
      } else {
        message.error(
          "Oops! Something went wrong. Please try again later or contact support."
        );
      }
    } catch (error) {
      // message.error("Error: Unable to connect to the server");
      message.error(
        "Error: Oops! Something went wrong. Please try again later or contact support."
      );
    }
  };

  useEffect(() => {
    checkServerStatus();
  }, []);

  useEffect(() => {
    store.dispatch(reloadUser());
  }, []);

  useEffect(() => {
    store.dispatch(allUsers());
  }, []);

  const AdminDashboardHDC = Layout(AdminDashboard);

  const AdminRolesHDC = Layout(AdminRoles);
  const AdminPermissionsHDC = Layout(AdminPermissions);
  // const AdminRouterPermissionsHDC = Layout(AdminRouterPermissions);
  const AdminProfileHDC = Layout(AdminProfile);
  const AdminExamsHDC = Layout(AdminExams);
  const AdminAddExamQuestionHDC = Layout(AdminAddExamQuestion);
  const AdminEditExamQuestionHDC = Layout(AdminEditExamQuestion);
  const AdminReportsHDC = Layout(AdminReports);
  const AdminEvaluationHDC = Layout(AdminEvaluation);
  const AdminEvaluationStudentsListHDC = Layout(AdminEvaluationStudentsList);
  const AdminEvaluationDetailsHDC = Layout(AdminEvaluationDetails);
  const AdminEditEvaluationDetailsHDC = Layout(AdminEditEvaluationDetails);
  const AdminSubjectiveReportsHDC = Layout(AdminSubjectiveReports);
  const AdminSubjectiveTopRankHDC = Layout(AdminSubjectiveTopRank);
  const AdminTopRankHDC = Layout(AdminTopRank);
  const AdminContactHDC = Layout(AdminContact);
  const AdminCreateVendorHDC = Layout(AdminCreateVendor);
  const AdminEditorVendorHDC = Layout(AdminEditorVendor);
  const AdminCategoryPermissionsHDC = Layout(AdminCategoryPermissions);
  const AdminFilesHDC = Layout(AdminFiles);
  const AdminVideosHDC = Layout(AdminVideos);
  const AdminInstituteInfoHDC = Layout(AdminInstituteInfo);
  const AdminJobNotificationHDC = Layout(AdminJobNotification);
  const AdminSubjectiveExamHDC = Layout(AdminSubjectiveExam);
  const AdminAddSubjectiveExamQuestionHDC = Layout(
    AdminAddSubjectiveExamQuestion
  );
  const AdminAllInstituteDetailsHDC = Layout(AdminAllInstituteDetails);
  const AdminUserDetailsHDC = Layout(AdminUserDetails);
  const AdminEditSubjectiveExamHDC = Layout(AdminEditSubjectiveExam);
  const AdminPlansHDC = Layout(AdminPlans);
  const AdminAssignVendorPlanListHDC = Layout(AdminAssignVendorPlanList);
  const AdminNewBatchHDC = Layout(AdminNewBatch);
  const AdminStudentBatchHDC = Layout(AdminStudentBatch);
  // Sub-Admin & Other Roles
  const AdminSubEditorVendorHDC = Layout(AdminSubEditorVendor);
  const AdminSubCategoryPermissionsHDC = Layout(AdminSubCategoryPermissions);
  const AdminSubRoutesPermissionsHDC = Layout(AdminSubRoutesPermissions);

  return (
    <>
      <ConnectionStatus />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ProSidebarProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* <Route path="/" 
            // element={<DemoHome />} 
            element={isAuthenticated ? <Home /> : <DemoHome />}
            /> */}
            <Route path="/book-a-demo" element={<BookADemo />} />
            <Route path="/test-series/about" element={<DemoAbout />} />
            <Route path="/test-series/contact" element={<DemoContact />} />
            <Route path="/test-series/plan" element={<DemoPlan />} />
            <Route path="/test-series/support" element={<DemoSupport />} />
            {/* Common Routes  */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset/:token" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-service" element={<TermsOfService />} />
            {/* Authenticated Routes */}
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  user && user.role === 0 ? (
                    <Home />
                  ) : user && user?.role >= 1 ? (
                    // <Navigate to="/admin/dashboard" />
                    <AdminDashboardHDC />
                  ) : (
                    <DemoHome />
                  )
                ) : (
                  <DemoHome />
                )
              }
            />
            <Route
              path="/write-exam/:id"
              element={isAuthenticated ? <WriteExam /> : <Login />}
            />
            <Route
              path="/about"
              element={isAuthenticated ? <About /> : <Login />}
            />
            <Route
              path="/exams"
              element={isAuthenticated ? <Exam /> : <Login />}
            />
            <Route
              path="/subjective/exams"
              element={isAuthenticated ? <SubjectiveExam /> : <Login />}
            />
            <Route
              path="/subjective/write-exam/:id"
              // element={isAuthenticatedUserRedirect(<WriteExam />)}
              element={isAuthenticated ? <SubjectiveWriteExam /> : <Login />}
            />
            <Route
              path="/notes"
              element={isAuthenticated ? <Notes /> : <Login />}
            />
            <Route
              path="/courses"
              element={isAuthenticated ? <Videos /> : <Login />}
            />
            <Route
              path="/video/:id"
              element={isAuthenticated ? <StartVideo /> : <Login />}
            />
            <Route
              path="/contact"
              element={isAuthenticated ? <Contact /> : <Login />}
            />
            {/* users Routes */}
            <Route
              path="/profile"
              element={user?.role === 0 ? <Profile /> : <Login />}
            />
            <Route
              path="/reports"
              element={user?.role === 0 ? <Reports /> : <Login />}
            />
            <Route
              path="/reports/top-rank"
              element={user?.role === 0 ? <TopRank /> : <Login />}
            />
            {user?.role >= 1 ? (
              <>
                <Route
                  path="/admin/dashboard"
                  element={<AdminDashboardHDC />}
                />
                <Route path="/admin/roles" element={<AdminRolesHDC />} />
                <Route
                  path="/admin/permissions"
                  element={<AdminPermissionsHDC />}
                />
                <Route
                  path="/admin/category/permissions"
                  element={<AdminCategoryPermissionsHDC />}
                />
                <Route
                  path="/admin/create/vendor"
                  element={<AdminCreateVendorHDC />}
                />
                <Route
                  path="/admin/create/editor/vendor"
                  element={<AdminEditorVendorHDC />}
                />
                <Route path="/admin/profile" element={<AdminProfileHDC />} />
                <Route path="/admin/exams" element={<AdminExamsHDC />} />
                <Route
                  path="/admin/add/exam"
                  element={<AdminAddExamQuestionHDC />}
                />
                <Route
                  path="/admin/edit/exam/:id"
                  element={<AdminEditExamQuestionHDC />}
                />
                <Route
                  path="/admin/subjective/exams"
                  element={<AdminSubjectiveExamHDC />}
                />
                <Route
                  path="/admin/add/subjective/exam"
                  element={<AdminAddSubjectiveExamQuestionHDC />}
                />
                <Route
                  path="/admin/edit/subjective/exam/:id"
                  element={<AdminEditSubjectiveExamHDC />}
                />
                <Route path="/admin/files" element={<AdminFilesHDC />} />
                <Route path="/admin/courses" element={<AdminVideosHDC />} />
                <Route path="/admin/reports" element={<AdminReportsHDC />} />
                <Route
                  path="/admin/subjective/evaluation"
                  element={<AdminEvaluationHDC />}
                />
                <Route
                  path="/admin/subjective/students-details/:id"
                  element={<AdminEvaluationStudentsListHDC />}
                />
                <Route
                  path="/admin/subjective/exam-details/:id"
                  element={<AdminEvaluationDetailsHDC />}
                />
                <Route
                  path="/admin/subjective/edit-exam-details/:id"
                  element={<AdminEditEvaluationDetailsHDC />}
                />
                <Route
                  path="/admin/subjective/top/rank"
                  element={<AdminSubjectiveTopRankHDC />}
                />
                {/* <Route path="/admin/subjective/top/rank" element={<AdminSubjectiveTopRankHDC />} /> */}
                <Route
                  path="/admin/subjective/reports"
                  element={<AdminSubjectiveReportsHDC />}
                />
                <Route path="/admin/top/rank" element={<AdminTopRankHDC />} />
                <Route path="/admin/contact" element={<AdminContactHDC />} />
                {/* Sub-Admin & Other Roles */}
                <Route
                  path="/admin/create/editor"
                  element={<AdminSubEditorVendorHDC />}
                />
                <Route
                  path="/admin/permission/category"
                  element={<AdminSubCategoryPermissionsHDC />}
                />
                <Route
                  path="/admin/permission/routes"
                  element={<AdminSubRoutesPermissionsHDC />}
                />
                <Route
                  path="/admin/institute/info"
                  element={<AdminInstituteInfoHDC />}
                />
                <Route
                  path="/admin/job/notification"
                  element={<AdminJobNotificationHDC />}
                />
                <Route
                  path="/admin/all/institute/details"
                  element={<AdminAllInstituteDetailsHDC />}
                />
                <Route
                  path="/admin/user/details/:id"
                  element={<AdminUserDetailsHDC />}
                />
                <Route path="/admin/vendor/plans" element={<AdminPlansHDC />} />
                <Route
                  path="/admin/assign/vendor/plans"
                  element={<AdminAssignVendorPlanListHDC />}
                />
                <Route path="/admin/batch" element={<AdminNewBatchHDC />} />
                <Route
                  path="/admin/student/batch"
                  element={
                    isAuthenticated ? <AdminStudentBatchHDC /> : <Login />
                  }
                />
              </>
            ) : null}

            {/* Admin Routes */}
            {/* Page not found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ProSidebarProvider>
    </>
  );
}

export default App;
