import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import { Container, Box, TextField, Button } from "@mui/material";
import axios from "../../axiosInstance";
import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const EditInstituteInfo = ({
  refreshData,
  instituteId,
  instituteInfo,
  vendorId,
  instituteName,
  instituteDescription,
  instituteCourses,
}) => {
  const { user } = useSelector((state) => state.user);

  const [institute_Name, setInstitute_Name] = useState(instituteName);
  const [description, setDescription] = useState(instituteDescription);
  //   const [institute_Courses, setInstitute_Courses] = useState(instituteCourses);

  const [courses, setCourses] = useState(instituteCourses);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //   const handleSubmit = (e) => {
  //     console.log(e);
  //   }

  // Edit Job Notification
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure you want to Edit this  Edit Institute Details?"
      )
    ) {
      const editInstituteDetails = {
        id: instituteInfo,
        instituteName: institute_Name,
        description,
        courses,
        instituteId: instituteId,
        vendorId: vendorId,
      };

      try {
        const { data } = await axios.put(
          "/api/v1/vendor/admin-edit-about-institute",
          editInstituteDetails
        );
        if (data?.success === true) {
          message.success(data?.message);
          refreshData();
          setIsModalOpen(false);
        } else {
          message.error(data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // On Change Correct Option Array
  const handleChangeCorrectOption = (onChangeValue, i) => {
    const inputData = [...courses];
    inputData[i] = onChangeValue.target.value;
    setCourses(inputData);
  };

  return (
    <>
      <IconButton aria-label="edit" onClick={showModal}>
        <EditIcon sx={{ color: "#1976d2" }} />
      </IconButton>
      <Modal
        title="Edit Institute Details"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Container
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
          maxWidth
        >
          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="institute_Name"
            label="Institute Name"
            name="institute_Name"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Institute Name"
            value={institute_Name}
            onChange={(e) => setInstitute_Name(e.target.value)}
          />

          <TextField
            sx={{
              mb: 3,
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              fieldset: { borderColor: "rgb(231, 235, 240)" },
            }}
            fullWidth
            id="instituteDescription"
            label="Institute Description"
            name="instituteDescription"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            multiline
            placeholder="Institute Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          {courses &&
            courses?.map((data, i) => (
              <>
                <Box sx={{ mb: 2 }} style={{ fontWeight: "600" }}>
                  {/* {i + 1}. Course Name */}
                </Box>

                <TextField
                  sx={{
                    mb: 1,
                    "& .MuiInputBase-root": {
                      color: "text.secondary",
                    },
                    fieldset: { borderColor: "rgb(231, 235, 240)" },
                  }}
                  fullWidth
                  id="coursesName"
                  label="Courses Name"
                  name="coursesName"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Correct Option"
                  value={data}
                  onChange={(e) => handleChangeCorrectOption(e, i)}
                />
              </>
            ))}

          <Button sx={{ mt: 3 }} variant="contained" type="submit">
            Add Notification
          </Button>
        </Container>
      </Modal>
    </>
  );
};

export default EditInstituteInfo;
