import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditFile from './EditFile';
import EditVideo from "./EditVideo";

const GetVideo = () => {
  const [notes, setNotes] = useState([]);

  // Get Video Data
  const getAllVideo = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-video`);
      if (response?.data?.success === true) {
        setNotes(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllVideo();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Video 
  const deleteVideo = async (e, cloudinaryId, id) => {
    if (window.confirm("Are you sure you want to delete this Video?")) {
      try {
        const { data } = await axios.delete(
          "/api/v1/admin/admin-delete-video",
          {
            data: { cloudinaryId, id },
          }
        );
        if (data?.success === true) {
          message.success(data.message);
          getAllVideo();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [

    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
           <IconButton
            aria-label="delete"
            onClick={(e) => deleteVideo(e, value?.row?.cloudinaryId, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditVideo
          refreshData={getAllVideo}
            videoId={value?.row?._id}
            title={value?.row?.title}
            description={value?.row?.description}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            instituteId={value?.row?.instituteId}
          />
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 210,
      editable: true,
    },
    {
      field: "title",
      headerName: "Title",
      width: 210,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 210,
      editable: true,
    },
    {
      field: "url",
      headerName: "Video Url",
      width: 240,
      editable: true,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 170,
      editable: true,
    },
  ];
  return (
    <>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              // Style for header cells
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={notes}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </>
  );
};

export default GetVideo;
