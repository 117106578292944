import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRole  from "../Roles/EditRole";

function CustomTabPanel(props) {
  
  
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Roles() {
  const [value, setValue] = useState(0);
  const [roleName, setRoleName] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const [roles, setRoles] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get Role Data
  const getAllRole = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/admin-get-role`);
      if (response?.data?.success === true) {
        setRoles(response?.data?.role);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllRole();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Role
  const deleteRole = async (e, id) => {
    if (window.confirm("Are you sure you want to delete this Role?")) {
      try {
        const { data } = await axios.delete("/api/v1/admin/admin-delete-role", {
          data: { id },
        });
        if (data.success === true) {
          message.success(data?.message);
          getAllRole();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [

    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
           <IconButton
            aria-label="delete"
            onClick={(e) => deleteRole(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditRole refreshData={getAllRole} roles={value?.row?._id } roleName = { value?.row?.role_name} roleValue={value?.row?.value}/>
        </Box>
      ),
    },
    {
      field: "role_name",
      headerName: "Role Name",
      width: 180,
      editable: true,
    },
    {
      field: "value",
      headerName: "Role Value",
      width: 180,
      editable: true,
    },

    {
      field: "createdAt",
      headerName: "Role Date",
      width: 180,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },

  ];

  // Add Roles
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addRole = {
      role_name: roleName,
      value:roleValue,
      // vendorId: user?._id, // todo
    };

    try {
      const { data } = await axios.post("/api/v1/admin/admin-add-role", addRole);
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setRoleName("")
        setRoleValue("")
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Roles | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
         All Students List 
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Students List" {...TabPanel(0)} />
            <Tab label="Add Student" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/* Role List */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
          Students Batch List
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                   // Style for header cells
                   "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={roles}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/* Add Roles */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Add a new Student 
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
           >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="role_name"
              label="Full Name *"
              name="role_name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Full Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />

           
            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Student
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}