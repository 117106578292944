/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import {
  Box,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  TextField,
  Badge,
} from "@mui/material";
import axios from "../axiosInstance";
import moment from "moment";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const currentDate = moment().startOf("day");
  const navigate = useNavigate();

  // Get All video Data
  const getAllVideos = async () => {
    try {
      const response = await axios.get("/api/v1/admin/get-all-video");
      setVideos(response?.data?.data);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  };

  // Search All video
  const searchAllVideo = async (e) => {
    let key = e?.target?.value;
    if (key) {
      try {
        const response = await axios.get(`/api/v1/admin/search-video/${key}`);
        if (response.data.data) {
          setVideos(response?.data?.data);
        }
      } catch (error) {
        message.error(error.response?.data?.message);
      }
    } else {
      getAllVideos();
    }
  };

  useEffect(() => {
    getAllVideos();
  }, []);

  const getYouTubeEmbedUrl = (url) => {
    const videoIdMatch = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return videoIdMatch
      ? `https://www.youtube.com/embed/${videoIdMatch[1]}`
      : null;
  };

  const isYouTubeUrl = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  const handlePlay = () => {
    setIsPlaying(false);
  };

  return (
    <div>
      <Navbar />
      <PageTitle title="Courses | Test Series" />
      <Container sx={{ pt: 3, mt: { xs: 12, sm: 12, md: 12, lg: 14 } }}>
        <TextField
          sx={{
            mb: 3,
            "& .MuiInputBase-root": {
              color: "text.secondary",
            },
            fieldset: { borderColor: "rgb(231, 235, 240)" },
          }}
          fullWidth
          id="text"
          label="Search Title and Description"
          name="text"
          placeholder="Search Title and Description"
          onChange={searchAllVideo}
        />
      </Container>
      <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
        <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              {videos && videos.length > 0 ? (
                videos.map((video, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Badge
                      badgeContent="Upcoming"
                      color="primary"
                      invisible={moment(video.startDate).isSameOrBefore(
                        currentDate
                      )}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // height: "480px",
                          minHeight:"38vh",
                          animation: "fadeIn 5s ease-in-out",
                        }}
                      >
                        <CardMedia
                          component="div"
                          title="Video"
                          sx={{ height: 240 }}
                        >
                          {isYouTubeUrl(video.url) ? (
                            <Box onClick={handlePlay}>
                              <img
                                src={`https://img.youtube.com/vi/${
                                  video.url.split("v=")[1] ||
                                  video.url.split("/").pop()
                                }/0.jpg`}
                                alt="YouTube Thumbnail"
                                style={{
                                  height: "240px",
                                }}
                              />
                            </Box>
                          ) : (
                            <video
                              height="210"
                              controls={false}
                              style={{ width: "100%" }}
                            >
                              <source src={video.url} controls={false} />
                            </video>
                          )}
                        </CardMedia>
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            className="courses_title"
                            style={{
                              letterSpacing: ".1rem",
                              fontSize: "18px",
                              marginBottom: "12px",
                              fontWeight: "bold",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {video.title}
                          </Typography>
                          <Typography
                            className="courses_desc"
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              mt: 2,
                              display: "flex",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            {video.description}
                          </Typography>
                          {moment(video.startDate).isSameOrBefore(
                            currentDate
                          ) ? (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                mt: 2,
                                display: "flex",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <strong>Uploaded on:</strong>{" "}
                              {moment(video.startDate).format("MMMM Do YYYY")}
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                mt: 2,
                                display: "flex",
                                fontSize: "15px",
                                letterSpacing: ".1rem",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <strong>Start Date:</strong>{" "}
                              {moment(video?.startDate).format("MMMM Do YYYY")}
                            </Typography>
                          )}
                           <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              mt: 2,
                              display: "flex",
                              fontSize: "15px",
                              letterSpacing: ".1rem",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            <strong>End Date:</strong>{" "}
                            {moment(video?.endDate).format("MMMM Do YYYY")}
                          </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "center", mb:2 }}>
                          {moment(video.startDate).isSameOrBefore(
                            currentDate
                          ) ? (
                            <Button
                              variant="outlined"
                              fullWidth
                              onClick={() => navigate(`/video/${video._id}`)}
                            >
                              Start Watching
                            </Button>
                          ) : (
                            <Button variant="outlined" fullWidth disabled>
                              Upcoming Courses
                            </Button>
                          )}
                        </CardActions>
                      </Card>
                    </Badge>
                  </Grid>
                ))
              ) : (
                <Box
                  style={{
                    color: "#878787",
                    margin: "30px 80px",
                    fontSize: 18,
                  }}
                >
                  No Available Courses
                </Box>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default Videos;
