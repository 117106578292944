import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import { composeWithDevTools } from '@redux-devtools/extension'
import { userReducer, profileReducer, forgotPasswordReducer, contactReducer, adminUsersReducer } from "../redux/reducers/userReducers";
import { examReducer } from "../redux/reducers/examReducers";

const reducer = combineReducers({
    user:userReducer,
    profile: profileReducer,
    forgotPassword:forgotPasswordReducer,
    users:adminUsersReducer,
    contact: contactReducer,
    exam:examReducer,
});

let initialState = {}

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))


export default store;