import React from "react";
import { Button } from "@mui/material";

const DownloadCSV = () => {
  const downloadCSV = () => {
    // Path to the CSV file in the public directory
    const csvFilePath = "/downloaded_sample.csv";

    fetch(csvFilePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "downloaded_sample.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  return (
    <Button variant="contained" onClick={downloadCSV} color="primary">
      Download Sample CSV
    </Button>
  );
};

export default DownloadCSV;
