import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import EasyIcon from "@mui/icons-material/TouchApp"; // Easy-to-use icon
import OnboardingIcon from "@mui/icons-material/GroupAdd"; // Effortless onboarding icon
import CustomizationIcon from "@mui/icons-material/Brush"; // Customized for you icon
import IntegrationIcon from "@mui/icons-material/IntegrationInstructions"; // Seamless integration icon
import WorksEverywhereIcon from "@mui/icons-material/Devices"; // Works everywhere icon
import TechStackIcon from "@mui/icons-material/CloudQueue"; // Top-tier tech stack icon
import SecurityIcon from "@mui/icons-material/Security"; // Highly secure icon
import ExpertsIcon from "@mui/icons-material/Star"; // Built by experts icon
import QuizIcon from "@mui/icons-material/Quiz";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AssessmentIcon from "@mui/icons-material/Assessment";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card
    sx={{
      minHeight: 150,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CardContent>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Icon sx={{ fontSize: 40, marginBottom: 1, color: "primary.main" }} />
        <Typography variant="h6" align="center" sx={{ wordSpacing: "2px", letterSpacing:" 1px"}}>
          {title}
        </Typography>
        <Typography variant="body2" align="center" sx={{ wordSpacing: "2px", letterSpacing:" 1px", color:"#757575"}}>
          {description}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

const DemoFeature = () => {
  return (
    <Container sx={{  padding:  {xs:"0.2rem", md:"2rem" }, mt: 3 , mb:{xs:"10px", md:"50px" } }}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}> 
          <FeatureCard
            icon={QuizIcon}
            title="MCQ Exams"
            description="Efficient multiple-choice testing"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={TextSnippetIcon}
            title="Subjective Exams"
            description="In-depth analysis with subjective questions"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={PictureAsPdfIcon}
            title="PDF Notes"
            description="Access comprehensive study materials"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={PlayCircleFilledIcon}
            title="Video Courses"
            description="Learn at your own pace with video content"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={AssessmentIcon}
            title="Online Reports"
            description="Instant analysis and feedback"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={WorksEverywhereIcon}
            title="Cross-Platform"
            description="Accessible on iOS, Android, web"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={TechStackIcon}
            title="Modern Tech Stack"
            description="Built with top-tier technology"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={SecurityIcon}
            title="Secure & Reliable"
            description="Ensuring data privacy and protection"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureCard
            icon={ExpertsIcon}
            title="Expertly Crafted"
            description="Created by experienced professionals"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DemoFeature;
