import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import axios from "../../axiosInstance";
import { message } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditRole  from "./EditRole";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditJobNotification from "./EditJobNotification";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function JobNotification() {
  const [value, setValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const [latestPostName, setLatestPostName] = useState("");
  const [latestLastDate, setLatestLastDate] = useState(null);
  const [latestNotificationDetails, setLatestNotificationDetails] =
    useState("");
  const [urlJobNotification, setUrlJobNotification] = useState("")
  const [jobPost, setJobPost] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleJobPostStartDateChange = (date) => {
    setLatestLastDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const jobPostStartDate = moment(startDate).format("MM/DD/YYYY");
  const jobPostEndDate = moment(endDate).format("MM/DD/YYYY");
  const jobPostDate = moment(latestLastDate).format("MM/DD/YYYY");

  // Get Job Post Data
  const getAllJobPost = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/admin-get-job-notification`
      );
      if (response?.data?.success === true) {
        setJobPost(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllJobPost();
    return () => clearInterval(intervalId);
  }, []);

  // Delete Job Post Notification Details
  const deleteJobPost = async (e, id) => {
    if (
      window.confirm("Are you sure you want to delete this Job Notification?")
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/admin/admin-delete-job-notification",
          {
            data: { id },
          }
        );
        if (data?.success === true) {
          message.success(data?.message);
          getAllJobPost();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteJobPost(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditJobNotification
            refreshData={getAllJobPost}
            latestPostId={value?.row?._id}
            latestPostName={value?.row?.latestPostName}
            latestNotificationDetails={value?.row?.latestNotificationDetails}
            urlJobNotification={value?.row?.urlJobNotification}
            latestLastDate={value?.row?.latestLastDate}
            startDate={value?.row?.startDate}
            endDate={value?.row?.endDate}
            instituteId={value?.row?.instituteId}
            vendorId={value?.row?.vendorId}
          />
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "institute",
      width: 180,
      editable: true,
    },
    {
      field: "latestPostName",
      headerName: "Post Name",
      width: 200,
      editable: true,
    },
    {
      field: "latestLastDate",
      headerName: "Post Last Date",
      width: 180,
      editable: true,
    },
    {
      field: "latestNotificationDetails",
      headerName: "Notification Details",
      width: 200,
      editable: true,
    },

    {
      field: "urlJobNotification",
      headerName: "Url",
      width: 240,
      editable: true,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      editable: true,
    },

    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      editable: true,
    },

  ];

  // Add Job Notification Details
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addJopPost = {
      latestPostName,
      latestLastDate: jobPostDate,
      latestNotificationDetails,
      urlJobNotification,
      vendorId: user?._id,
      instituteId: user?.instituteId,
      startDate: jobPostStartDate,
      endDate: jobPostEndDate,
    };

    try {
      const { data } = await axios.post(
        "/api/v1/admin/create-job-notification",
        addJopPost
      );
      if (data.success === true) {
        message.success(data.message);
        // Clear form fields
        setLatestPostName("");
        setLatestNotificationDetails("");
        setLatestLastDate(null);
        setStartDate(null);
        setEndDate(null);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle title="Admin Job Notification | Test Series" />
      <Box>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Job Notification
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Notification List" {...TabPanel(0)} />
            <Tab label="Add Notification" {...TabPanel(1)} />
          </Tabs>
        </Box>

        {/*  Notification Details */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Notification Details
          </Typography>
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                  // Style for header cells
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={jobPost}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
                // checkboxSelection
              />
            </Box>
          </Paper>
        </CustomTabPanel>

        {/*  Create a new Job Notification */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 2, pt: 3 }}>
            Create a new Job Notification
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="latestPostName"
              label="Latest Post Name"
              name="latestPostName"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Latest Post Name"
              value={latestPostName}
              onChange={(e) => setLatestPostName(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  fullWidth
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Job Notification Last Date"
                  selected={latestLastDate}
                  value={latestLastDate}
                  onChange={handleJobPostStartDateChange}
                  minDate={(new Date() && latestLastDate) || new Date()}
                  maxDate={latestLastDate}
                  required
                />
              </Box>
            </Box>

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="latestNotificationDetails"
              label="Latest Notification Details"
              name="latestNotificationDetails"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Latest Notification Details"
              value={latestNotificationDetails}
              onChange={(e) => setLatestNotificationDetails(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="urlJobNotification"
              label="Url Job Notification"
              name="urlJobNotification"
              InputLabelProps={{
                shrink: true,
              }}
              required
              placeholder="Url Job Notification"
              value={urlJobNotification}
              onChange={(e) => setUrlJobNotification(e.target.value)}
            />

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  required
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                />
              </Box>
            </Box>

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Add Notification
            </Button>
          </Container>
        </CustomTabPanel>
      </Box>
    </>
  );
}
