import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: "https://testseries.gatiktech.com",  
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

export default instance;