import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import axios from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { message, Table } from "antd";
import PageTitle from "../../Components/PageTitle/PageTitle";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import EditQuestionModal from "./EditQuestionModal";
import AddIcon from "@mui/icons-material/Add";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import QuestionPaperPDF from "./QuestionPaperPDF";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
// import DownloadCSV from "./DownloadCSV";
// import AddQuestions from "./AddQuestions";
import AddSubjectiveQuestion from "./AddSubjectiveQuestion";
import EditSubjectiveQuestion from './EditSubjectiveQuestion';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditSubjectiveExam() {
  // Get Data in exam by Id
  let { id } = useParams();
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [passingMarks, setPassingMarks] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(startDate);
  const [questions, setQuestions] = useState([]);
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [negativeMarking, setNegativeMarking] = useState("");
  const { user } = useSelector((state) => state.user);
  const [type, setType] = useState("Subjective");
  const [examSet, setExamSet] = useState("");
  const [instituteId, setInstituteId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [exam, setExam] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const examStartDate = moment(startDate).format("MM/DD/YYYY");
  const examEndDate = moment(endDate).format("MM/DD/YYYY");

  // Get all Exams
  const getAllExams = async () => {
    try {
      const response = await axios.get(`/api/v1/subjective/get-exam-subjective-by-id/${id}`);
      const examData = response?.data?.data;
      setExam(examData);
      setSearchResults(examData.questions); 
      setName(examData.name);
      setCategory(examData.category);
      setDuration(examData.duration);
      setTotalMarks(examData.totalMarks);
      setPassingMarks(examData.passingMarks);
      setNegativeMarking(examData.negativeMarking);
      setType(examData.type);
    //   setQuestionType(examData.questionType);
      setExamSet(examData.examSet);
      setStartDate(examData.startDate);
      setEndDate(examData.endDate);
      setQuestions(examData.questions);
      setInstituteId(examData.instituteId);
      setVendorId(examData.vendorId);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Edit Exam by Id
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const addExam = {
        name,
        category,
        duration,
        totalMarks,
        passingMarks,
        negativeMarking,
        examSet,
        type,
        startDate: examStartDate,
        endDate: examEndDate,
        id: id,
        instituteId: instituteId,
        vendorId: vendorId,
      };
      const { data } = await axios.put(
        "/api/v1/subjective/admin-update-exam-subjective",
        addExam
      );
      if (data.success === true) {
        // message.error(data.message);
        message.success(data.message);
        // localStorage save exam ID
        localStorage.setItem("examData", JSON.stringify(data));
      } else {
        // message.success(data.message);
        message.error(data.message);

      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  // Delete Exam Questions By id
  const deleteQuestion = async (questionId) => {
    if (window.confirm("Are you sure you want to delete this Question?")) {
      try {
        const { data } = await axios.post(
          `/api/v1/question/admin-delete-exam-to-questions`,
          {
            questionId,
            examId: id,
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getAllExams();
        } else {
          message.error(data.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div>
      <PageTitle title="Admin Edit Subjective Exam | Test Series" />
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Edit Subjective Exam And Question
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Exam" {...TabPanel(0)} />
            <Tab label="Question" {...TabPanel(1)} />
            <Tab label="Add Question" {...TabPanel(2)} />
          </Tabs>
        </Box>

        {/* Add Exams */}
        <CustomTabPanel value={value} index={0}>
          <Typography variant="h6" sx={{ color: "black", pb: 3 }}>
            Edit Subjective Exam
          </Typography>
          <Container
            onSubmit={handleSubmit}
            component="form"
            className="form_style border-style"
            maxWidth
          >
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="name"
              label="Exam Name"
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Exam Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="category"
              label="Category"
              name="category"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="duration"
              label="Duration"
              name="duration"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="totalMarks"
              label="Total Marks"
              name="totalMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Total Marks"
              value={totalMarks}
              onChange={(e) => setTotalMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="passingMarks"
              label="Passing Marks"
              name="passingMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Passing Marks"
              value={passingMarks}
              onChange={(e) => setPassingMarks(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="negativeMarks"
              label="Negative Marks"
              name="negativeMarks"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Negative Marks"
              value={negativeMarking}
              onChange={(e) => setNegativeMarking(e.target.value)}
            />

            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  Exam Set{" "}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={examSet}
                  label="Exam Set"
                  onChange={(e) => setExamSet(e.target.value)}
                >
                  <MenuItem value="Practice Exam">Practice Exam </MenuItem>
                  <MenuItem value="Live Exam">Live Exam</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box style={{ display: "flex" }} className="Date">
              <Box
                sx={{
                  mb: 3,
                }}
                style={{ marginRight: "2%" }}
              >
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="Start Date"
                  selected={startDate}
                  value={startDate}
                  onChange={handleStartDateChange}
                  minDate={new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
              <Box>
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  isClearable
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  value={endDate}
                  // eslint-disable-next-line no-mixed-operators
                  minDate={(new Date() && startDate) || new Date()}
                  maxDate={endDate}
                  required
                />
              </Box>
            </Box>

            <Button sx={{ mt: 3 }} variant="contained" type="submit">
              Update Exam
            </Button>
          </Container>
        </CustomTabPanel>

        {/* Add Questions */}
        <CustomTabPanel value={value} index={1} maxWidth>
          <Typography variant="h6" sx={{ color: "black", pb: 3 }}>
            Edit Question
          </Typography>
          <EditSubjectiveQuestion vendor_Id={vendorId} institute_Id={instituteId} />
        </CustomTabPanel>
       
        <CustomTabPanel value={value} index={2}>
          <Typography variant="h6" sx={{ color: "black", pb: 2 }}>
            Add Question
          </Typography>
          <AddSubjectiveQuestion vendor_Id={vendorId} institute_Id={instituteId} />
        </CustomTabPanel>
      </Box>
    </div>
  );
}

