import React, { useState, useEffect } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { Box, Typography, Paper, TextField, Button } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { message } from "antd";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import jsPDF autotable plugin
import axios from "../../axiosInstance";

const SubjectiveReports = () => {
  const [reportsData, setReportsData] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [filters, setFilters] = useState({
    examId: "",
    userId: "",
  });

  // Get all reports by user
  const getReports = async (filters) => {
    try {
      const response = await axios.post(
        `/api/v1/reports/subjective/admin-get-all-Reports-subjectiveReport`,
        filters
      );
      setReportsData(response?.data?.data);
      setFilteredReports(response?.data?.data); 
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getReports(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = reportsData.filter((report) => {
      return (
        report.examName.toLowerCase().includes(query) ||
        report.userEmail.toLowerCase().includes(query)
      );
    });

    setFilteredReports(filtered);
  };

  // Function to export data to PDF with index column
  const exportPDF = () => {
    // Show confirmation dialog
    const confirmDownload = window.confirm("Do you want to download the Student Report as a PDF?");
  
    if (confirmDownload) {
      const doc = new jsPDF();
      const columns = [
        "#",
        "Exam Name",
        "User Email",
        "Total Marks",
        "Passing Marks",
        "Obtained Marks",
        "Verdict",
        "Report Date",
      ];
  
      const rows = filteredReports.map((report, index) => [
        index + 1,
        report.examName,
        report.userEmail,
        report.totalMarks,
        report.passingMarks,
        report.totalMarksObtained,
        report.verdict,
        moment(report.createdAt).format("MM/DD/YYYY"),
      ]);
  
      // Add title
      doc.text("Student All Report", 16, 16);
  
      // Generate the PDF with autotable
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 20,
      });
  
      // Save the PDF
      doc.save("Student_Report.pdf");
    }
  };
  

  // Delete report by Id
  const deleteReport = async (e, id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this user Subjective Report?"
      )
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/reports/subjective/admin-delete-subjectiveReport",
          {
            data: { id },
          }
        );
        if (data.success === true) {
          message.success(data.message);
          getReports(); 
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteReport(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "examName",
      headerName: "Exam Name",
      width: 160,
      editable: true,
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 120,
      editable: true,
    },
    {
      field: "category",
      headerName: "Exam Category",
      width: 120,
      editable: true,
    },
    {
      field: "examSet",
      headerName: "Exam Type",
      width: 120,
      editable: true,
    },
    {
      field: "userEmail",
      headerName: "User Email",
      width: 120,
      editable: true,
    },
    {
      field: "totalMarks",
      headerName: "Total Marks",
      width: 120,
      editable: true,
    },
    {
      field: "passingMarks",
      headerName: "Passing Marks",
      width: 120,
      editable: true,
    },
    {
      field: "totalMarksObtained",
      headerName: "Obtained Marks",
      width: 120,
      renderCell: (row) => row?.row?.totalMarksObtained,
    },
    {
      field: "verdict",
      headerName: "Verdict",
      width: 120,
    },
    {
      field: "date",
      headerName: "Report Date",
      width: 120,
      renderCell: (params) =>
        moment(params?.row?.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <div>
      <PageTitle title="Admin Subjective Exam Reports | Test Series" />
      <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
        Subjective Reports
      </Typography>

      <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Search by Exam Name or User Email"
          placeholder="Search by Exam Name or User Email"
          value={searchQuery}
          onChange={handleSearch}
        />
      </Box>

      <Button
        sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
        variant="contained"
        color="primary"
        onClick={exportPDF}
        startIcon={<DownloadIcon />} 
      >
        Export to PDF
      </Button>

      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={filteredReports}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default SubjectiveReports;
