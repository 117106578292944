import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle/PageTitle";
import VendorAbout from "../Components/VenderAbout";
import StudentTestimonial from "../Components/StudentTestimonial";
import Footer from "../Components/Footer";
import JobNotification from "../Components/JobNotification";
import axios from "../axiosInstance";
import { message } from "antd";

const BannerContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "600px",
});

const BannerImage = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#000",
});

const Home = () => {
  const [image_Url, setImage_Url] = useState([]);

  const settings = {
    dots: false,
    infinite: image_Url.length > 1, // infinite only if there are multiple images
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Get all reports by user
  const getBannerImage = async () => {
    try {
      const response = await axios.get(`/api/v1/vendor/get-banner-images`);
      setImage_Url(response?.data?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getBannerImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <PageTitle title="Home | Test Series" />

      <BannerContainer sx={{ mt: { xs: 8, sm: 8, md: 8, lg: 8 } }}>
        <Slider {...settings}>
          {image_Url?.map((url, index) => (
            <BannerImage
              key={index}
              sx={{
                height: {
                  xs: "300px",
                  sm: "400px",
                  md: "400px",
                  lg: "550px",
                },
              }}
            >
              <img
                src={url?.url}
                alt={`Slide ${index + 1}`}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </BannerImage>
          ))}
        </Slider>
      </BannerContainer>

      <VendorAbout />

      <JobNotification />

      {/* <StudentTestimonial /> */}

      <Footer />
    </div>
  );
};

export default Home;
