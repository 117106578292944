import React, { useState, useEffect, useRef } from "react";
import axios from "../../axiosInstance";
import { Box, Grid, Typography, TextField, Paper, Button } from "@mui/material";
import { message } from "antd";
import { useParams } from "react-router-dom";

const EditEvaluationDetails = () => {
  const { id } = useParams();
  const [report, setReport] = useState([]);
  const [marks, setMarks] = useState({});
  const [marksObtained, setMarksObtained] = useState(0);
  const [verdict, setVerdict] = useState("");
  const containerRef = useRef(null);

  // Fetch the report data based on the report ID
  const getAllSubjectiveExams = async () => {
    try {
      const response = await axios.post(
        "/api/v1/reports/subjective/admin-get-exam-by-subjectiveReport",
        { reportId: id }
      );
      if (response?.data?.success === true) {
        const exams = response?.data?.data;
        setReport(exams);
        const storedMarks = exams[0].answersData.reduce(
          (acc, answer, index) => {
            acc[index] = answer.marks || 0;
            return acc;
          },
          {}
        );
        setMarks(storedMarks);
        setMarksObtained(exams[0].totalMarksObtained || 0);
        setVerdict(exams[0].verdict || "");
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllSubjectiveExams();
  }, [id]);

  useEffect(() => {
    if (report.length > 0) {
      const totalMarks = Object.values(marks).reduce(
        (sum, mark) => sum + parseFloat(mark || 0),
        0
      );
      const negativeMarks = report[0]?.answersData.reduce(
        (sum, answer, index) => {
          const awardedMarks = parseFloat(marks[index] || 0);
          return (
            sum +
            (awardedMarks < answer.questionNumber
              ? awardedMarks * report[0].negativeMarking
              : 0)
          );
        },
        0
      );
      const calculatedMarksObtained = (totalMarks - negativeMarks).toFixed(2);
      setMarksObtained(calculatedMarksObtained);
      setVerdict(
        calculatedMarksObtained >= report[0].passingMarks ? "Pass" : "Fail"
      );
    }
  }, [marks, report]);

  const handleMarksChange = (index, value) => {
    const questionNumber = report[0].answersData[index].questionNumber;
    let updatedMarks = {
      ...marks,
      [index]: value,
    };

    // Clamp marks within 0 to questionNumber
    if (value > questionNumber) {
      updatedMarks = {
        ...updatedMarks,
        [index]: questionNumber,
      };
      message.warning(`Marks cannot exceed ${questionNumber}`);
    }

    setMarks(updatedMarks);
  };

  // call the update api
  const handleSave = async () => {
    const updatedData = {
      ...report[0],
      answersData: report[0].answersData.map((answer, index) => ({
        ...answer,
        marks: parseFloat(marks[index] || 0),
      })),
      totalMarksObtained: marksObtained,
      verdict: verdict,
      reportId: id,
    };
    try {
      const response = await axios.post(
        "/api/v1/reports/subjective/admin-update-subjectiveReport",
        updatedData
      );
      message.success("Report updated successfully!");
      setReport([response.data.data]);
    } catch (error) {
      message.error("Failed to update report");
    }
  };

  if (report.length === 0) {
    return null;
  }

  const exam = report[0];

  return (
    <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
      <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
        Students Copy with their Answers
      </Typography>
      <Box
        ref={containerRef}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box>
          <Typography variant="h6">Name: {exam.userName}</Typography>
          <Typography variant="h6" mb={2}>
            Exam: {exam.examName}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">Total Marks: {exam.totalMarks}</Typography>
          <Typography variant="h6">
            Passing Marks: {exam.passingMarks}
          </Typography>
          <Typography variant="h6" mb={2}>
            Negative Marking: {exam.negativeMarking}
          </Typography>
        </Box>
      </Box>

      {exam.answersData.map((answer, index) => (
        <Box key={index} mb={2}>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ marginBottom: 2 }}
            >
              Q{index + 1}: {answer.question}
            </Typography>
            <Grid item display="flex" alignItems="center">
              <TextField
                value={answer.questionNumber}
                variant="outlined"
                size="small"
                sx={{ width: "70px", marginLeft: 1, mb: 2 }}
              />
            </Grid>
          </Grid>
          <TextField
            label="Student Answer"
            multiline
            fullWidth
            defaultValue={answer.answer}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Reference Answer"
            multiline
            fullWidth
            defaultValue={answer.referenceAnswer}
            variant="outlined"
            sx={{ marginBottom: 2, mt: 2 }}
          />
          {answer.answer && ( // Only render "Marks Awarded" if answer.answer exists
            <Grid container justifyContent="flex-end" alignItems="end">
              <Typography>Marks Awarded</Typography>
              <TextField
                value={marks[index] || ""}
                onChange={(e) => handleMarksChange(index, e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: "70px", marginLeft: 1, mt: 3 }}
              />
            </Grid>
          )}
        </Box>
      ))}

      <Grid container justifyContent="flex-end" alignItems="end" mb={2}>
        <Typography variant="h6">Marks Obtained</Typography>
        <TextField
          value={marksObtained}
          onChange={(e) => setMarksObtained(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ width: "70px", marginLeft: 1 }}
        />
      </Grid>

      <Grid container justifyContent="flex-end" alignItems="end" mb={2}>
        <Typography variant="h6">Verdict</Typography>
        <TextField
          value={verdict}
          onChange={(e) => setVerdict(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ width: "70px", marginLeft: 1 }}
        />
      </Grid>

      <Grid container justifyContent="flex-end" sx={{ mt: 5 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Update Exam Details
        </Button>
      </Grid>
    </Paper>
  );
};

export default EditEvaluationDetails;
