import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import AdbIcon from "@mui/icons-material/Adb";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import DemoImage from "../../assets/demo image.png";
import { Paper } from "@mui/material";

export default function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "#FFF",
          color: "#000",
          fontWeight: "600",
          position: "absolute",
          p: 1.5,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                wordSpacing: "2px",
                letterSpacing: " 1px",
              }}
            >
              TEST SERIES
            </Typography>

            {/* Menu Icon for Mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <ul style={{ padding: "2px" }}>
                    <li style={{ textDecoration: "none", listStyle: "none" }}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          wordSpacing: "2px",
                          letterSpacing: " 1px",
                        }}
                        to="/"
                      >
                        Home
                      </Link>
                    </li>

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                        wordSpacing: "2px",
                        letterSpacing: " 1px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/test-series/about"
                      >
                        About
                      </Link>
                    </li>

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                        wordSpacing: "2px",
                        letterSpacing: " 1px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/test-series/plan"
                      >
                        Plans
                      </Link>
                    </li>

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                        wordSpacing: "2px",
                        letterSpacing: " 1px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/test-series/contact"
                      >
                        Contact
                      </Link>
                    </li>

                    {/* <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                        wordSpacing: "2px",
                        letterSpacing: " 1px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/test-series/support"
                      >
                        Support
                      </Link>
                    </li> */}

                    {/* <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                        wordSpacing: "2px",
                        letterSpacing: " 1px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/book-a-demo"
                      >
                        Book a Demo
                      </Link>
                    </li> */}

                    <li
                      style={{
                        textDecoration: "none",
                        listStyle: "none",
                        marginTop: "12px",
                        wordSpacing: "2px",
                        letterSpacing: " 1px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/login"
                      >
                        Login
                      </Link>
                    </li>
                  </ul>
                </MenuItem>
              </Menu>
            </Box>

            {/* Logo */}
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 600,
                color: "inherit",
                textDecoration: "none",
                wordSpacing: "2px",
                letterSpacing: " 1px",
              }}
            >
              TEST SERIES
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/"
              >
                Home
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/test-series/about"
              >
                About
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/test-series/plan"
              >
                Plans
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/test-series/contact"
              >
                Contact
              </Link>

              {/* <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/test-series/support"
              >
                Support
              </Link> */}
{/*               
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/book-a-demo"
              >
                Book a Demo
              </Link> */}
              
              <Link
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  padding: "12px",
                  wordSpacing: "2px",
                  letterSpacing: " 1px",
                }}
                to="/login"
              >
                Login
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
