import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PageTitle from "../Components/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, resetPassword } from "../redux/actions/userAction";
import { useNavigate, useParams } from "react-router-dom";
import Password from "@mui/icons-material/Password";
import { message } from "antd";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("password", password);
    myForm.set("confirmPassword", confirmPassword);
    dispatch(resetPassword(token, myForm));
  };

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      message.success("Password Updated Successfully");
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, success]);
  return (
    <div>
      <PageTitle title="Reset Password | Test Series" />
      <Box
        sx={{
          height: "81vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "primary.white",
        }}
      >
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
              <Password />
            </Avatar>

            <h2>Reset your password</h2>

            <TextField
              sx={{
                mb: 3,
                mt:2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="ConfirmPassword"
              name="password"
              label="Confirm Password"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              type="submit"
            >
              {loading ? "loading..." : "Reset Password"}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ResetPassword;
