import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { message } from "antd";
import axios from "../axiosInstance";
import { Container } from "@mui/material";

const JobNotification = () => {
  const [Jobs, setJobs] = useState([]);

  // Get All Job Post Data
  const getAllJobPost = async () => {
    try {
      const response = await axios.get(`/api/v1/admin/get-job-notification`);
      if (response?.data?.success === true) {
        setJobs(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllJobPost();
  }, []);

  const columns = [
    {
      field: "latestPostName",
      headerName: "Post Name",
      width: 250,
      editable: true,
    },
    {
      field: "latestLastDate",
      headerName: "Last Date",
      width: 180,
      editable: true,
    },
    {
      field: "latestNotificationDetails",
      headerName: "Notification Details",
      width: 340,
      editable: true,
    },
    {
      field: "urlJobNotification",
      headerName: "Notification Url",
      width: 350,
      editable: true,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },
  ];
  

  return (
    <>
    {Jobs && Jobs?.length > 0 ? (
    <Container sx={{mb:5}}>
        <Typography
          style={{
            fontWeight: "600px",
            fontSize: "22px",
          }}
          sx={{ color: "black", pb: 3, mt: 3 }}
        >
          {/* <b> Latest Rajasthan Govt Jobs – Rajasthan Recruitment List:</b> */}
          <b>Latest Job Notification</b>
        </Typography>
        <Box
          style={{
            width: "auto",
            overflowY: "hidden",
            overflowX: "scroll",
          }}
        >
          <Paper sx={{ bgcolor: "white" }}>
            <Box sx={{ height: 500, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row?._id}
                sx={{
                  "& .MuiTablePagination-displayedRows": {
                    color: "black",
                  },
                  color: "black",
                  [`& .${gridClasses?.row}`]: {
                    bgcolor: "white",
                  },
                   // Style for header cells
                   "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "rgb(25, 118, 210)",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white",
                    fontWeight: "bold",
                    // fontSize: "13px",
                  },
                  // Style for checkbox in header
                  "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                    color: "#FFF",
                  },
                  "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                }}
                rows={Jobs}
                columns={columns}
                pageSize={3}
                rowsPerPageOptions={[3]}
              />
            </Box>
          </Paper>
        </Box>
      </Container>
    ): (
      <>
      {null}
      </>
    )}

    </>
  );
};

export default JobNotification;
