import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const DemoFooter = () => {
  const [isVisible, setIsVisible] = useState(false);

  const currentYear = new Date().getFullYear();
  // Show button when page is scrolled up to a certain distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  // Scroll the page to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Box
        className="footer-main-box"
        sx={{
          backgroundColor: "#252C39",
          width: "100%",
          height: "100%",
          padding: "20px 0",
         
        }}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                className="footer-main-inside-box"
                sx={{ m: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}
              >

                  {/* Added links section */}
                  <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs:"column", md: "row" },
                    justifyContent: "Left",
                    mb: 2,
                    color: "#fff",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  {/* Link 1 */}
                  <Link
                    to="/"
                    style={{ color: "#FFF", textDecoration: "none", margin: '8px' }}
                  >
                    Home
                  </Link>
                  {/* Link 2 */}
                  <Link
                    to="/test-series/about"
                    style={{ color: "#FFF", textDecoration: "none", margin: '8px' }}
                  >
                    About
                  </Link>
                  {/* Link 3 */}
                  <Link
                    to="/test-series/plan"
                    style={{ color: "#FFF", textDecoration: "none", margin: '8px' }}
                  >
                    Plans
                  </Link>
                  {/* Link 4 */}
                  <Link
                    to="/test-series/contact"
                    style={{ color: "#FFF", textDecoration: "none", margin: '8px' }}
                  >
                    Contact
                  </Link>
                </Box>
                {/* End of added links section */}

                <Divider sx={{ backgroundColor: "#fff", height: "2px" }} />

                <Box
                  className="footer-under-section"
                  sx={{
                    mt: 3,
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "1.75",
                  }}
                >
                  <p>
                    {/* Gatik Ai Technology is registered in Cardiff under Company
                    No. 09568675 | VAT Reg No. 217562215 */}
                    GatikAI Technologies is one of the growing IT Solutions
                    company based in India. We provides expert solutions in the
                    area of App & Web development.
                  </p>
                  <p>
                    &copy; {currentYear} Copyright by{" "}
                    <Link
                      to="https://gatiktech.com"
                      style={{ color: "#FFF", textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      GatikAI Technologies
                    </Link>
                    . All rights reserved.
                  </p>
                  <p>
                    Registered Office: Arg group, north avenue, 306A, third
                    floor, road no 9, Sikar Rd, Jaipur, Rajasthan 302013
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <Box className="up-down-button">
          {isVisible && (
            <button onClick={scrollToTop} className="">
              <KeyboardArrowUpIcon sx={{ fontSize: 30 }} />
            </button>
          )}
        </Box> */}
      </Box>
    </div>
  );
};

export default DemoFooter;
