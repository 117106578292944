import React, { useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../Components/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../redux/actions/userAction";
import { message } from "antd";
import axios  from '../axiosInstance';


const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Check Server Status 
  const checkServerStatus = async () => {
    try {
      const response = await axios.get("/api/status");
      if (response?.data?.data === true) {
        // message.success("Server is running");
      } else {
        message.error("Oops! Something went wrong. Please try again later or contact support.");
      }
    } catch (error) {
      message.error("Error: Oops! Something went wrong. Please try again later or contact support.");

    }
  };

// login api call
const handleSubmit = async (e) => {
  e.preventDefault();
  checkServerStatus();
  // Dispatch login action
  await dispatch(login(email, password));
};

useEffect(() => {
  if (error) {
    message.error(error);
    dispatch(clearErrors());
  }

  if (isAuthenticated) {
    message.success("Login Successfully");
    navigate("/");
  }
}, [error, isAuthenticated, dispatch, navigate]);


  return (
    <>
      <PageTitle title="Login | Test Series" />
      <Box
        sx={{
          height: "81vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "primary.white",
        }}
      >
        <Box
          onSubmit={handleSubmit}
          component="form"
          className="form_style border-style"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main", mb: 3 }}>
              <LockOpenIcon />
            </Avatar>
            <h2>Login in </h2>
            <p>Please fill your login details to access your institution account.</p>
            <TextField
              sx={{
                mb: 3,
                mt:2,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              type='email'
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  color: "text.secondary",
                },
                fieldset: { borderColor: "rgb(231, 235, 240)" },
              }}
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="ForgotPassword">
              {" "}
              <Link to="/password/forgot"> Forgot Password ? </Link>{" "}
            </p>
            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              type="submit"
            >
              {loading ? "loading..." : "Log In"}
            </Button>
            <Box sx={{ pt: 2 }} className="switchMember">
              {" "}
              Create an Account ?{""} <Link to="/register"> Sign Up </Link>{" "}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;
