import React, { useState, useEffect } from "react";
import axios from "../../axiosInstance";
import { message } from "antd";
import { Box, Paper } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditInstituteInfo from './EditInstituteInfo';

const InstituteInfoList = () => {
  const [instituteInfo, setInstituteInfo] = useState("");

  // Get institute details data
  const getAllInstituteInfo = async () => {
    try {
      const response = await axios.get(
        `/api/v1/vendor/admin-get-about-institute`
      );
      if (response?.data?.success === true) {
        setInstituteInfo(response?.data?.data);
      } else {
        message.error(response?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {}, 1000);
    getAllInstituteInfo();
    return () => clearInterval(intervalId);
  }, []);

  // Delete institute details data
  const deleteBannerImage = async (e, id) => {
    if (
      window.confirm("Are you sure you want to delete this institute details?")
    ) {
      try {
        const { data } = await axios.delete(
          "/api/v1/vendor/admin-delete-about-institute",
          {
            data: { id },
          }
        );
        if (data?.success === true) {
          message.success(data?.message);
          getAllInstituteInfo();
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const columns = [
    {
      field: "Actions",
      width: 100,
      renderCell: (value) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            aria-label="delete"
            onClick={(e) => deleteBannerImage(e, value?.row?._id)}
          >
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
          <EditInstituteInfo  refreshData={getAllInstituteInfo} 
          instituteInfo={value?.row?._id} 
          instituteId={value?.row?.instituteId} 
          instituteName={value?.row?.instituteName} 
          instituteDescription={value?.row?.description} 
          instituteCourses={value?.row?.courses} 
          vendorId={value?.row?.vendorId}
          />
        </Box>
      ),
    },
    {
      field: "instituteId",
      headerName: "Institute Id",
      width: 180,
      editable: true,
    },
    {
      field: "instituteName",
      headerName: "Institute Name",
      width: 260,
      editable: true,
    },
    {
      field: "description",
      headerName: "Institute Description",
      width: 390,
      editable: true,
    },
    {
      field: "courses",
      headerName: "Courses",
      width: 280,
      editable: true,
    },
    
  ];

  return (
    <>
      <Paper sx={{ bgcolor: "white" }}>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row?._id}
            sx={{
              "& .MuiTablePagination-displayedRows": {
                color: "black",
              },
              color: "black",
              [`& .${gridClasses?.row}`]: {
                bgcolor: "white",
              },
               // Style for header cells
               "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgb(25, 118, 210)",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold",
                // fontSize: "13px",
              },
              // Style for checkbox in header
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#FFF",
              },
              "& .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                color: "white",
              },
            }}
            rows={instituteInfo}
            columns={columns}
            pageSize={3}
            rowsPerPageOptions={[3]}
            // checkboxSelection
          />
        </Box>
      </Paper>
    </>
  );
};

export default InstituteInfoList;
