import React, { useState, useEffect } from "react";
import axios from "../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import Navbar from "../Components/Navbar";
import { Typography } from "@mui/material";
import PageTitle from "../Components/PageTitle/PageTitle";
import { message } from "antd";
import Footer from "../Components/Footer";

function TopRank() {
  const [mergedExamData, setMergedExamData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [objectiveRes, subjectiveRes] = await Promise.all([
          axios.get("/api/v1/reports/get-top-rank"),
          axios.get("/api/v1/reports/subjective/get-top-rank-subjectiveReport"),
        ]);

        const objectiveData = objectiveRes.data?.maxCorrectAnswersPerExam
          ? Object.keys(objectiveRes.data.maxCorrectAnswersPerExam).map(
              (examId) => {
                const exam = objectiveRes.data.maxCorrectAnswersPerExam[examId];
                return {
                  ...exam,
                  examType: "Objective",
                  obtainedMarks: exam.result?.obtainedMarks,
                  totalMarks: exam.exam?.totalMarks,
                };
              }
            )
          : [];

        const subjectiveData = subjectiveRes.data?.topRankData
          ? Object.keys(subjectiveRes.data.topRankData).map((examId) => {
              const exam = subjectiveRes.data.topRankData[examId];
              return {
                ...exam,
                examType: "Subjective",
                obtainedMarks: exam.totalMarksObtained,
                totalMarks: exam.exam?.totalMarks,
              };
            })
          : [];

        // Merge and sort data
        const combinedData = [...objectiveData, ...subjectiveData];
        const sortedData = combinedData.sort(
          (a, b) => b.obtainedMarks - a.obtainedMarks
        );

        setMergedExamData(sortedData);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <PageTitle title="Top Rank List | Test Series" />
      <Box
        sx={{
          mb: 4,
          pt: 3,
          pl: { xs: 2, sm: 2, md: 12, lg: 14 },
          pr: { xs: 2, sm: 2, md: 12, lg: 14 },
          mt: { xs: 12, sm: 12, md: 12, lg: 14 },
          minHeight: "100vh",
        }}
      >
        <Typography variant="h5" sx={{ color: "black", pb: 3 }}>
          Your Performance Rankings
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "rgb(25, 118, 210)", color: "white" }}
            >
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Top Rank
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Student Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Student Email
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Exam Name
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Exam Type
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Student Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergedExamData.length > 0 ? (
                mergedExamData.map((exam, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{exam.user?.name || "N/A"}</TableCell>
                    <TableCell>{exam.user?.email || "N/A"}</TableCell>
                    <TableCell>{exam.exam?.name || "N/A"}</TableCell>
                    <TableCell>{exam.examType}</TableCell>
                    <TableCell>
                      {exam.obtainedMarks
                        ? exam.obtainedMarks.toFixed(2)
                        : "0.00"}{" "}
                      / {exam.totalMarks || 0}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </>
  );
}

export default TopRank;
